import { Injectable } from '@angular/core';
import { AppConfig } from '../appConfig';

@Injectable({providedIn: 'root'})
export class HelpService {

    private readonly _helpUrl: string;

    private helpKeyMap: Record<string, Record<string, string>> = {
        en: {
            'Account.Organization': '/organization',
            'Attendee.ImportCSV': '/import-csv',
            'Attendee.SendInvitationEmail': '/how-it-works',
            'Attendee.GenerateBadges': '/generate-badges',
            'Attendee.DownloadRQCodes': '/download-codes',
            'Event.Buy': '/buying-additional-number-of-attendees',
            'Event.DataExport': '/data-export',
            'Messages.ProjectionScreen': '/projection-screen',
            'Page.Access_Code': '/connection-to-the-event',
            'Page.Attendees': '/attendees',
            'Page.EventSettings': '/settings',
            'Page.GDPR': '/gdpr',
            'Page.Messages': '/messages',
            'Page.ResetPassword': '/forgotten-password',
            'Page.SignUp': '/signing-up',
            'Page.WelcomePage': '/create-welcome-page',
            'Page.UserProfile': '/user-profile'
        },
        cs: {
            'Account.Organization': '/sprava-organizace',
            'Attendee.ImportCSV': '/importovat-csv',
            'Attendee.SendInvitationEmail': '/jak-to-funguje',
            'Attendee.GenerateBadges': '/vytisknout-badge',
            'Attendee.DownloadRQCodes': '/stahnout-kody',
            'Event.Buy': '/navyseni-poctu-ucastniku',
            'Event.DataExport': '/export-dat',
            'Messages.ProjectionScreen': '/promitaci-okno',
            'Page.Access_Code': '/pripojeni-k-eventu',
            'Page.Attendees': '/ucastnici',
            'Page.EventSettings': '/nastaveni-pro-ucastniky',
            'Page.GDPR': '/gdpr-2',
            'Page.Messages': '/zpravy',
            'Page.ResetPassword': '/zapomenute-heslo',
            'Page.SignUp': '/vytvoreni-uctu',
            'Page.WelcomePage': '/vytvoreni-uvodni-stranky',
            'Page.UserProfile': '/profil-ucastnika'
        }
    };

    constructor() {
        this._helpUrl = AppConfig.helpUrl;
    }

    getHelpUrl(key: string, culture: string) {
        let keyUrl = this.helpKeyMap[culture][key];
        return `${this._helpUrl}${keyUrl}`;
    }

}
