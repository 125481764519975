import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'input-radio',
    styleUrls: ['./inputRadio.component.scss'],
    template: `
        <div class="switchSection" [ngClass]="{on:value}">
            <label class="switch" [ngClass]="{disabled: isDisabled, notInitialized:!initialized}"><input type="radio"
                                                                                                         [value]="value"
                                                                                                         [formControl]="control"
                                                                                                         (change)="onStateChanged($event)">
                <svg width="20px" height="20px" viewBox="0 0 20 20">
                    <circle cx="10" cy="10" r="9"></circle>
                    <path
                        d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                        class="inner"></path>
                    <path
                        d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                        class="outer"></path>
                </svg>
                <span class="switchText"><ng-content></ng-content></span></label>
        </div>
    `
})
export class InputRadioComponent implements OnInit, AfterViewInit {
    @Input() label?: string | SafeHtml;
    @Input() fieldName?: string;
    @Input() control!: FormControl;
    initialized = false;

    @HostBinding('class') hostClasses?: string;

    private _value = false;
    private _valueInitialized = false;
    private _classNames = 'switch';
    private _disabled = false;

    get value(): any {
        return this._value;
    }

    @Input() set value(v: any) {
        if (v !== this._value || !this._valueInitialized) {
            this._value = v;
            this._valueInitialized = true;
        }
    }

    @Input() set classNames(value: string) {
        this._classNames = value;
        this.updateHostClasses();
    }

    get isDisabled(): boolean {
        return this._disabled;
    }

    @Input() set isDisabled(val: boolean) {
        if (this._disabled !== val) {
            this._disabled = val;
            //  Angular reactive forms do not currently support disabling individual radio buttons in a group
            //  through the formcontrol / formgroup API as per this open issue:
            // https://github.com/angular/angular/issues/11763

            // if (this.control) {
            //     if (val) {
            //         this.control.disable();
            //     } else {
            //         this.control.enable();
            //     }
            // }
        }
    }

    constructor(private _changeDetectorRef: ChangeDetectorRef) {
    }

    onStateChanged(_: any) {
        this._changeDetectorRef.detectChanges();
        this.control?.markAsDirty();
    }

    ngOnInit(): void {
        this.updateHostClasses();
    }

    ngAfterViewInit() {
        this.initialized = true;
        this._changeDetectorRef.detectChanges();
    }

    private updateHostClasses() {
        this.hostClasses = 'radio ' + (this._classNames || '');
    }

}
