import { Injectable } from '@angular/core';
import { Logger } from '@myia/ngx-core';
import { BackendService } from '@myia/ngx-http'
import { FormatDateService } from '@myia/ngx-localization';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PlaceEvent } from '../entities/placeEvent';
import { IPlaceEvent } from '../entities/placeEvent.interface';
import { PlaceEventType } from '../entities/placeEventType';

@Injectable({ providedIn: 'root' })
export class EventListService {

    constructor(private _backendService: BackendService, private _formatDateService: FormatDateService, private _logger: Logger) {
    }

    getEventType(event: IPlaceEvent): PlaceEventType {
        const now = this._formatDateService.now();
        const isRunning = this._formatDateService.isDateBetween(now, event.from, event.to);
        if (isRunning) {
            return PlaceEventType.Running;
        }
        const isUpcoming = this._formatDateService.isDateAfter(event.from, now);
        return isUpcoming ? PlaceEventType.Upcoming : PlaceEventType.Completed;

    }


    getEvents(): Observable<ReadonlyArray<IPlaceEvent>> {
        // return mockup data
        // return new ScalarObservable(this.getMockupPlaceEvents()).delay(2000); // wait 2s to return result
        return this._backendService.get<Array<IPlaceEvent>>('/api/events')
            .pipe(
                map(data => {
                    const events = data.map((ev: IPlaceEvent) => {
                        return PlaceEvent(ev);
                    });
                    return events;
                }),
                catchError(err => {
                    this._logger.error('Could not load events.');
                    return throwError(err);
                })
            );

    }

    // private getMockupPlaceEvents() {
    //     return List([
    //         PlaceEvent({id: 'ev1', title: 'Event One'}),
    //         PlaceEvent({id: 'ev2', title: 'Event Two'}),
    //         PlaceEvent({id: 'ev3', title: 'Event Three'})
    //     ]);
    // }

}

