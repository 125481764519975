import { Pipe, PipeTransform } from '@angular/core';
import { HelpPosition } from './helpPosition';

@Pipe({
    name: 'helpPosition'
})
export class HelpPositionPipe implements PipeTransform {
    static getClass(position: string | HelpPosition) {
        if (typeof position === 'string') {
            return position;
        }
        return HelpPosition[position];
    }

    transform(value: string | HelpPosition): string {
        if (typeof value === 'string') {
            return value;
        }
        return HelpPositionPipe.getClass(value);
    }
}
