import { Component, Input, Output, EventEmitter, OnInit, HostBinding } from '@angular/core';
import { CountryCodes } from '../helpers/countryCodes';
import { find, propEq } from 'ramda';
import { Logger } from '@myia/ngx-core';


@Component({
    selector: 'input-language-field',
    template: `<input-dropdown [label]="label" [items]="items" [(value)]="item" (valueChange)="onValueChanged($event)" [disabled]="disabled" [isModified]="isModified"></input-dropdown>
               `
})
export class InputLanguageFieldComponent implements OnInit {
    @Input() label?: string;
    @Input() value?: string;
    @Output() valueChange: any = new EventEmitter<string>();
    @Input() disabled = false;
    @Input() isModified = false;

    @HostBinding('class') hostClasses = 'languageField';

    public items: Array<any>;
    public item: any;

    constructor(private _logger: Logger) {
        this.items = CountryCodes.map((cCode) => {
            return {label: cCode.name, value: cCode.code};
        });
    }

    ngOnInit() {
        this.updateItem();
    }

    updateItem() {
        this.item = find(propEq('value', this.value))(this.items);
    }

    onValueChanged(newValue: any) {
        this.value = newValue.value;
        this.valueChange.emit(newValue.value);
        this.updateItem();
        this._logger.log('Language changed to:' + this.value);
    }
}
