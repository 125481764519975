import { Injectable } from '@angular/core';
import { BackendService } from '@myia/ngx-http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GoogleMapsAPIService } from './googleMapsAPIService';
import { CachedDataServiceBase } from './cachedDataServiceBase';
import { CultureService } from '@myia/ngx-localization';
import { Logger } from '@myia/ngx-core';

@Injectable({ providedIn: 'root' })
export class TimeZonesService extends CachedDataServiceBase<string> {

    constructor(private _cultureService: CultureService, private _googleMapsAPIService: GoogleMapsAPIService, private _logger: Logger, private _backendService: BackendService) {
        super();
        this._cultureService.onChange.subscribe(() => {
            this.initializeCache();
        });
    }

    public getTimeZoneTitle(timeZoneId: string): Observable<string> {
        return this.getCachedItem(timeZoneId, this._getTimeZoneTitle.bind(this));
    }

    public getTimeZoneByPlace(placeId: string, place: string, addressComponents?: Array<string>): Observable<any> {
        return this._googleMapsAPIService.timeZoneByPlace(placeId, place, this._cultureService.currentCulture, addressComponents)
            .pipe(
                catchError(() => {
                    return throwError(() => new Error('Could not get time zone by place(Google API).'));
                })
            );
    }

    private _getTimeZoneTitle(zoneId: string) {
       return this._backendService.get(`/api/services/TimeZoneTitle?timeZoneId=${encodeURIComponent(zoneId)}&language=${this._cultureService.currentCulture}`, {responseType: 'text'}).pipe(
                catchError(err => {
                    this._logger.error(`Could not load time zone for id '${zoneId}'.`);
                    return throwError(err);
                })
            );
    }
}
