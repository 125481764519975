import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getNewComponentId } from '@myia/ngx-core';
import { ToastManager } from '@myia/ngx-toast';
import { LocalizationService } from '@myia/ngx-localization';

// toast keys
const TOAST_MULTIPLE_FILES_IS_NOT_SUPPORTED = 'TOAST_MULTIPLE_FILES_IS_NOT_SUPPORTED';

@Component({
    selector: 'input-file',
    styleUrls: ['./inputFile.component.scss'],
    template: `
                <input #inputEl type="file" name="{{inputId}}" id="{{inputId}}" [attr.multiple]="multiple" [accept]="accept" class="inputFile" [ngClass]="{hasFocus: hasFocus}" (change)="onChange($event)" (blur)="onBlur()" (focus)="onFocus()" [disabled]="disabled" tabindex="-1"/>
                <label [attr.for]="inputId" class="inputFileLabel" [ngClass]="{disabled: disabled}"><ng-content></ng-content></label>
              `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFileComponent implements OnInit, OnDestroy {
    @Input() multiple = false;
    @Input() disabled = false;
    @Input() formGroupRef?: FormGroup;
    @Input() fieldName?: string;
    @Input() accept?: string;
    @Input() tabIndex?: string;

    @HostBinding('class.inputFileBlock') hostClass = true;

    @ViewChild('inputEl', {static: true}) _input?: ElementRef;

    @Output() fileAdded: EventEmitter<any>;

    public inputId: string = 'input' + getNewComponentId();
    public hasFocus = false;

    private _control?: FormControl;
    private _controlName?: string;

    constructor(private _changeDetectorRef: ChangeDetectorRef, private _toastManager: ToastManager, private _localizationService: LocalizationService) {
        this.fileAdded = new EventEmitter();
    }

    ngOnInit() {
        this._control = new FormControl('', Validators.compose([]));
        if (this.formGroupRef) {
            this._controlName = this.fieldName || getNewComponentId();
            this.formGroupRef.addControl(this._controlName, this._control);
        }
    }

    ngOnDestroy() {
        if (this.formGroupRef && this._controlName) {
            this.formGroupRef.removeControl(this._controlName);
        }
    }

    onChange($event: any) {
        let files = $event.srcElement.files;
        if (files && files.length) {
            if (files.length > 1 && !this.multiple) {
                this._toastManager.warning(this._localizationService.translate('PhotoPicker.Multiple_Photos_Not_Supported'), { toastKey: TOAST_MULTIPLE_FILES_IS_NOT_SUPPORTED });
            }
            const inputFiles = this.multiple ? files : files[0];
            this._control?.setValue(inputFiles);
            this._control?.markAsTouched();
            this._changeDetectorRef.markForCheck();
            this.fileAdded.emit({file: inputFiles});
            if (this._input) {
                this._input.nativeElement.value = '';
            }
        }
    }

    onBlur() {
        this.hasFocus = false;
    }

    onFocus() {
        this.hasFocus = true;
    }
}
