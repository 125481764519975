export enum HelpPosition {
    left,
    top,
    right,
    bottom,
    leftTop,
    rightTop,
    leftBottom,
    rightBottom,
    center,
    inline
}
