import { readOnlyEntity } from '@myia/ngx-core';
import { IPriceWithCurrency } from './priceWithCurrency.interface';

export function PriceWithCurrency(props: Partial<IPriceWithCurrency>): IPriceWithCurrency {
    return readOnlyEntity({
        currency: null,
        price: null,
        ...props
    } as IPriceWithCurrency);
}
