import { createState, updateState } from '@myia/ngx-redux';
import { ICountry } from '../services/countryService';
import { TABLE_DATA_SET_COUNTRIES } from './countryActions';

export const countryReducerKey = 'country';

export interface ICountryState {
    countries: ReadonlyArray<ICountry> | null;
}
const initialState = createState<ICountryState>(
    {
        countries: null
    });

function CountryReducer(state = initialState, action: any) {
    switch (action.type) {
        case TABLE_DATA_SET_COUNTRIES:
            return updateState(state, (newState: ICountryState) => {
                newState.countries = action.countries;
            });
        default:
            return state;
    }
}

export default {
    [countryReducerKey]: CountryReducer
};
