import {
    AfterViewChecked, AfterViewInit, ChangeDetectorRef, Directive, ElementRef, EventEmitter, inject, Input, OnDestroy,
    OnInit, Output
} from '@angular/core';
import { EmitterService, getCssNumber, getCssValue, getParentScroll, LAYOUT_RESIZED } from '@myia/ngx-core';
import { takeUntil } from 'rxjs/operators';
import { OnDestroyDirective } from '@myia/ngx-core-ui';

@Directive({
    selector: '[sticky]',
    hostDirectives: [
        OnDestroyDirective
    ],
})
export class StickyDirective implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

    @Input() stickyZIndex: number = 10;
    @Input() stickyWidth: string = 'auto';
    @Input() stickyOffsetTop: number = 0;
    @Input() stickyOffsetBottom: number = 0;
    @Input() stickyToBottom = false;
    @Input() stickyOffsetRight: number = 0;
    @Input() stickyToContainer = false;
    @Input() stickyContainer?: HTMLElement;
    @Input() stickyStart: number = 0;
    @Input() stickyClass: string = 'sticky';
    @Input() stickyEndClass: string = 'sticky-end';
    @Input() stickyMediaQuery: string = '';

    @Output() stickyActivated = new EventEmitter();
    @Output() stickyDeactivated = new EventEmitter();

    private onScrollBind: EventListener = this.onScroll.bind(this);
    private onResizeBind: EventListener = this.onResize.bind(this);

    private isStuck: boolean = false;
    private _destroy$ = inject(OnDestroyDirective).destroy$;

    private readonly elem: any;
    private originalCss: any;

    private container: any;
    private elemHeight?: number;
    private containerHeight?: number;
    private containerTop?: number;
    private elementTopOffset?: number;
    private _isSticky = false;

    private static getBoundingClientRectValue(element: any, property: string): number {
        let result = 0;
        if (element && element.getBoundingClientRect) {
            let rect = element.getBoundingClientRect();
            result = (typeof rect[property] !== 'undefined') ? rect[property] : 0;
        }
        return result;
    }

    @Input() set isSticky(sticky: boolean) {
        this._isSticky = sticky;
        this.defineDimensions();
        this.sticker();
    }

    constructor(private element: ElementRef, private _changeDetectorRef: ChangeDetectorRef) {
        this.elem = element.nativeElement;
    }

    ngOnInit(): void {
        window.addEventListener('resize', this.onResizeBind);
        EmitterService.getEvent(LAYOUT_RESIZED).pipe(
            takeUntil(this._destroy$)
        ).subscribe(this.onResizeBind);
    }

    ngAfterViewInit(): void {
        if (!this.checkContainer()) {
            this.defineDimensions();
            this.sticker();
        }
        //if (this._isSticky) {
            // refresh after while when it should be stuck from beginning
            setTimeout(() => {
                this.refresh();
            }, 200);
        //}
    }

    ngAfterViewChecked(): void {
        this.checkContainer();
    }

    ngOnDestroy(): void {
        if (this.container) {
            this.container.removeEventListener('scroll', this.onScrollBind);
        }
        window.removeEventListener('resize', this.onResizeBind);
    }

    checkContainer(checkAgain?: boolean) {
        if (!this.container || checkAgain || this._isSticky) {
            // get scroll container as parent element that scrolls
            const container = getParentScroll(this.elem);
            if (this.container !== container) {
                if (this.container) {
                    this.container.removeEventListener('scroll', this.onScrollBind);
                }
                this.container = container;
                if (this.container) {
                    if (this._isSticky === undefined) {
                        this.container.addEventListener('scroll', this.onScrollBind);
                    }
                    this.defineDimensions();
                    this.sticker();
                }
                else {
                    // check container again after 1s
                    setTimeout(() => {
                        this.checkContainer();
                    }, 1000);
                }
            }
        }
        return !!this.container;
    }

    onScroll(): void {
        this.defineDimensions();
        this.sticker();
    }

    onResize(): void {
        this.refresh();
    }

    refresh(): void {
        this.resetElement();
        this.unstuckElement();
        this.checkContainer(true);
        this.defineDimensions();
        this.sticker();
    }

    defineDimensions(): void {
        if (this.container) {
            this.containerHeight = getCssNumber(this.container, 'height');
            this.elemHeight = getCssNumber(this.elem, 'height');
            const containerTop = StickyDirective.getBoundingClientRectValue(this.container, 'top') + this.stickyOffsetTop;
            if (!this.isStuck || this.containerTop !== containerTop) {
                this.containerTop = containerTop;
                this.elementTopOffset = StickyDirective.getBoundingClientRectValue(this.elem, 'top') - this.containerTop + this.container.scrollTop;
            }
        }
    }

    resetElement(): void {
        this.elem.classList.remove(this.stickyClass);
        Object.assign(this.elem.style, this.originalCss);
    }

    stuckElement(): void {
        if (this.stickyContainer) {
            this.stickyContainer.style.height = `${this.stickyContainer.offsetHeight}px`;
        }

        this.isStuck = true;

        this.originalCss = {
            zIndex: getCssValue(this.elem, 'zIndex'),
            position: getCssValue(this.elem, 'position'),
            top: getCssValue(this.elem, 'top'),
            right: getCssValue(this.elem, 'right'),
            left: getCssValue(this.elem, 'left'),
            width: this.elem.style.width
        };

        this.elem.classList.remove(this.stickyEndClass);
        this.elem.classList.add(this.stickyClass);

        const marginLeft = getCssNumber(this.elem, 'margin-left');
        let containerBounds;
        if (this.stickyToContainer) {
            containerBounds = this.container.getBoundingClientRect();
        }
        const elementLeft = this.stickyToContainer ? containerBounds.left : StickyDirective.getBoundingClientRectValue(this.elem, 'left') - marginLeft;
        this.elem.style.zIndex = this.stickyZIndex;
        this.elem.style.position = 'fixed';
        if (this.stickyToBottom) {
            this.elem.style.top = 'auto';
            this.elem.style.bottom = this.stickyOffsetBottom + 'px';
        }
        else {
            this.elem.style.bottom = 'auto';
            this.elem.style.top = this.containerTop + 'px';
        }
        this.elem.style.left = elementLeft + 'px';
        this.elem.style.right = 'auto';
        if (this.stickyToContainer) {
            this.elem.style.width = (this.container.clientWidth - 2*marginLeft) + 'px';
        }
        else {
            this.elem.style.width = this.stickyWidth;
        }

        this.stickyActivated.next(this.elem);
    }

    unstuckElement(): void {
        this.isStuck = false;
        if (this.stickyContainer) {
            this.stickyContainer.style.height = `auto`;
        }
        this.stickyDeactivated.next(this.elem);
    }

    matchMediaQuery(): any {
        if (!this.stickyMediaQuery) return true;
        return (
            window.matchMedia('(' + this.stickyMediaQuery + ')').matches ||
            window.matchMedia(this.stickyMediaQuery).matches
        );
    }

    sticker(): void {
        if (this.container) {
            // check media query
            if (this.isStuck && !this.matchMediaQuery()) {
                this.resetElement();
                return;
            }

            // detecting when a container's height changes
            let currentContainerHeight: number = getCssNumber(this.container, 'height');
            if (currentContainerHeight !== this.containerHeight) {
                this.defineDimensions();
            }

            let position: number = this.scrollbarYPos();

            const forceStuck = this._isSticky !== undefined;
            // unstick
            if ((forceStuck && !this._isSticky) || (!forceStuck && this.elementTopOffset != null && this.elementTopOffset > position)) {
                if (this.isStuck) {
                    this.resetElement();
                    this.unstuckElement();
                }
            }
            // stick
            else if (!this.isStuck) {
                this.stuckElement();
            }
        }
    }

    private scrollbarYPos(): number {
        return this.container ? this.container.scrollTop : 0;
    }
}
