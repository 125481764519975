import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { cubicInOut, Logger } from '@myia/ngx-core';

@Directive({
    selector: '[autoScrollContent]'
})
export class AutoScrollContentDirective implements AfterViewInit {
    @Input() scrollSpeed: number = 50; // 50px per second
    @Input() scrollSpeedInverseTime: number = 1000; // 1000s
    @Input() scrollAgainDelay: number = 1000; // wait 1s before scroll again

    private _autoScrollContent = false;
    private _initialized = false;
    private _scrollAnimated = false;

    get autoScrollContent(): boolean {
        return this._autoScrollContent;
    }

    @Input() set autoScrollContent(value: boolean) {
        this._autoScrollContent = value;
        this.updateAutoScroll();
    }

    constructor(private _element: ElementRef, private _logger: Logger) {
    }

    ngAfterViewInit(): any {
        this._initialized = true;
        this.updateAutoScroll();
    }

    private updateAutoScroll() {
        if (this._autoScrollContent !== this._scrollAnimated) {
            this._scrollAnimated = this._autoScrollContent;
            if (this._autoScrollContent) {
                // reset scroll pos to top
                this._element.nativeElement.scrollTop = 0;
                this._logger.log('Scroll animation started.');
                setTimeout(() => { // wait 500ms for proper dom element dimensions
                    this.startScrolling();
                }, this.scrollAgainDelay);
            }
            else {
                this._logger.log('Scroll animation stopped.');
            }
        }
    }

    private scrollTo(elementToScroll: any, from: number, to: number, scrollSpeed: number, callback?: Function) {
        const duration = 1000 * elementToScroll.scrollHeight / scrollSpeed;
        const scrollHeight = elementToScroll.scrollHeight - elementToScroll.offsetHeight;
        let offsetFrom = scrollHeight*from,
            change = scrollHeight*to - offsetFrom,
            currentTime = 0,
            increment = 20;
        const that = this;
        const animateScroll = function () {
            if (!that._scrollAnimated) {
                return;
            }
            // increment the time
            currentTime += increment;
            // move the document.body
            elementToScroll.scrollTop = cubicInOut(currentTime, offsetFrom, change, duration);
            // do the animation unless its over
            if (currentTime < duration) {
                window.requestAnimationFrame(animateScroll);
            } else {
                that._logger.log('Scroll animation completed.');
                if (callback && typeof(callback) === 'function') {
                    // the animation is done so lets callback
                    callback();
                }
            }
        };
        animateScroll();
    }

    private startScrolling() {
        if (this._element.nativeElement.scrollHeight <= this._element.nativeElement.offsetHeight) {
            this._logger.log('Scrolling canceled - content is not larger than element height.');
            return;
        }
        this.scrollTo(this._element.nativeElement, 0, 1, this.scrollSpeed, () => {
            setTimeout(() => {
                this.scrollTo(this._element.nativeElement, 1, 0, this._element.nativeElement.scrollHeight * 1000 / this.scrollSpeedInverseTime, () => {
                    setTimeout(() => {
                        this.startScrolling();
                    }, this.scrollAgainDelay);
                });
            }, this.scrollAgainDelay);
    });

    }
}
