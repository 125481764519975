import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { openNewWindow } from '@myia/ngx-core';
import { CultureService } from '@myia/ngx-localization';
import { HelpService } from '../services/helpService';
import { HelpPosition } from './helpPosition';
import { HelpPositionPipe } from './helpPositionPipe';
import { Placement } from '@floating-ui/dom';

@Component({
    selector: 'help-icon',
    styleUrls: ['./helpIcon.component.scss'],
    template: `
        <div class="wrapper" [tooltip]="helpTooltip" tooltipTrigger="hover" tooltipContainer="body"
             [tooltipPlacement]="tooltipPlacement" [tooltipClass]="key ? 'helpKeyTooltip' : 'helpImgTooltip'" [tooltipOffset]="[0,5]" [tooltipEnable]="popUpPlacement!=='hidden'"
             (click)="openHelp($event)">
            <svg-icon name="question" [ngStyle]="{color: color}"></svg-icon>
            <ng-content></ng-content>
            <div class="helpImg" *ngIf="imageUrl"
                 [ngStyle]="{'background-image': imageUrl ? 'url(\\'' + imageUrl + '\\')' : null}"></div>
            <ng-template #helpTooltip>
                <img class="helpImgFull" src="{{imageUrl}}" *ngIf="imageUrl">
                <div *ngIf="key">{{'Help.Click_To_Open_Help'|translate}}</div>
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpIconComponent {
    @Input() imageUrl?: string;
    @Input() popUpPlacement: Placement | 'auto' | 'hidden' = 'auto';
    @Input() key?: string;
    @Input() position: string | HelpPosition = HelpPosition.inline;
    @Input() color?: string;
    @Input() className?: string;

    @HostBinding('class')
    get helpClass() {
        return `helpIcon ${HelpPositionPipe.getClass(this.position)}${this.className ? ' ' + this.className : ''}`;
    }

    get tooltipPlacement(): Placement | 'auto' {
        return this.popUpPlacement as Placement | 'auto';
    }

    constructor(private _element: ElementRef, private _helpService: HelpService, private _cultureService: CultureService) {
    }

    openHelp(event: any) {
        if (this.key) {
            event.stopPropagation();
            event.preventDefault();
            const url = this._helpService.getHelpUrl(this.key, this._cultureService.currentCulture);
            openNewWindow(url);
        }
    }
}
