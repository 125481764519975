import {
    ChangeDetectionStrategy, Component, ComponentRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild,
    ViewContainerRef
} from '@angular/core';
import { IWYSIWYGEditorComponent } from './wysiwygEditor.interface';
import { WYSIWYGEditor4Component } from './wysiwygEditor4';
import { WYSIWYGEditor5Component } from './wysiwygEditor5';
import { WysiwygEditorVersions } from './wysiwygEditorVersions';
import { Observable, of } from 'rxjs';
import { WYSIWYGEditor6Component } from './wysiwygEditor6';

@Component({
    selector: 'wysiwyg-editor',
    template: `<ng-template #editorHost></ng-template>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WYSIWYGEditorComponent implements OnInit, OnChanges, IWYSIWYGEditorComponent {
    @Input() previewEvent?: string; // EmitterService event key to be raised when external component want to show content preview
    @Input() toolbarAlwaysVisible = false;
    @Input() contentStylesFixedForEditor?: string;
    @Input() viewPortTopOffset: number = 0; // e.g. height of top bar that overflows content
    @Input() trackImages = false;
    @Input() bottomToolbarEl?: HTMLElement;
    @Input() editorVersion?: string;
    @Input() htmlContent?: string;
    @Input() contentCss?: string | null;
    @Input() editorScript?: string;
    @Input() showToolbars = false;
    @Input() readonly = false;
    @Input() imageUploader?: (imageBlob: Blob) => Observable<string>;
    @Input() fixedSize = false;

    @Output() contentChanged: EventEmitter<any>;
    @Output() cssChanged: EventEmitter<any>;
    @Output() contentLoaded: EventEmitter<void>;
    @Output() editorClicked: EventEmitter<void>;
    @Output() editorLeft: EventEmitter<void>;

    @ViewChild('editorHost', {read: ViewContainerRef, static: true}) _contentViewContainerRef?: ViewContainerRef;

    private _editorRef?: ComponentRef<IWYSIWYGEditorComponent>;

    constructor() {
        this.contentLoaded = new EventEmitter<void>();
        this.contentChanged = new EventEmitter<void>();
        this.cssChanged = new EventEmitter<void>();
        this.editorClicked = new EventEmitter<void>();
        this.editorLeft = new EventEmitter<void>();
    }

    ngOnInit() {
        this.addEditorComponent();
    }

    ngOnChanges() {
        this._setInputProperties();
    }

    resetContent(): void {
        this._editorRef?.instance.resetContent();
    }

    uploadImages(): Observable<void> {
        return this._editorRef?.instance.uploadImages() ?? of(void 0);
    }

    private addEditorComponent() {
        let editorComponent: any = WYSIWYGEditor4Component; // default editor
        switch (this.editorVersion) {
            case WysiwygEditorVersions[WysiwygEditorVersions.tinymce5]:
                editorComponent = WYSIWYGEditor5Component;
                break;
            case WysiwygEditorVersions[WysiwygEditorVersions.tinymce6]:
                editorComponent = WYSIWYGEditor6Component;
                break;
        }
        this._contentViewContainerRef?.clear();
        this._editorRef = this._contentViewContainerRef?.createComponent(editorComponent) as ComponentRef<IWYSIWYGEditorComponent>;
        this._setInputProperties();

        this._editorRef.instance.contentChanged.subscribe((e: any) => {
            this.contentChanged.emit(e);
        });
        if (this._editorRef.instance.cssChanged) {
            this._editorRef.instance.cssChanged.subscribe((e: any) => {
                this.cssChanged.emit(e);
            });
        }
        this._editorRef.instance.contentLoaded.subscribe(() => {
            this.contentLoaded.emit();
        });
        this._editorRef.instance.editorClicked.subscribe(() => {
            this.editorClicked.emit();
        });
        this._editorRef.instance.editorLeft.subscribe(() => {
            this.editorLeft.emit();
        });
    }

    private _setInputProperties() {
        if (this._editorRef) {
            this._editorRef.instance.htmlContent = this.htmlContent;
            this._editorRef.instance.contentCss = this.contentCss;
            this._editorRef.instance.editorScript = this.editorScript;
            this._editorRef.instance.showToolbars = this.showToolbars;
            this._editorRef.instance.readonly = this.readonly;

            this._editorRef.instance.previewEvent = this.previewEvent;
            this._editorRef.instance.toolbarAlwaysVisible = this.toolbarAlwaysVisible;
            this._editorRef.instance.contentStylesFixedForEditor = this.contentStylesFixedForEditor;
            this._editorRef.instance.viewPortTopOffset = this.viewPortTopOffset;
            this._editorRef.instance.trackImages = this.trackImages;
            this._editorRef.instance.bottomToolbarEl = this.bottomToolbarEl;
            this._editorRef.instance.editorVersion = this.editorVersion;
            this._editorRef.instance.imageUploader = this.imageUploader;
            this._editorRef.instance.fixedSize = this.fixedSize;
        }
    }
}
