import { ICountry } from '../services/countryService';

export const TABLE_DATA_SET_COUNTRIES = 'TABLE_DATA_SET_COUNTRIES';

export function setCountriesAction(countries: ReadonlyArray<ICountry>) {
    return {
        type: TABLE_DATA_SET_COUNTRIES,
        countries
    };
}
