import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({name: 'safe'})
export class SafeUrlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(url: string | undefined) {
        return url ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : url;
    }
}
