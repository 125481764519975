import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input,
    NgZone, OnDestroy, OnInit, Output
} from '@angular/core';
import {
    addListener, EmitterService, getFileDataUrl, getImageMimeType, getNewComponentId, getParentScroll, IFRAME_CLICKED,
    Logger, removeAllListeners
} from '@myia/ngx-core';
import { CultureService, LocalizationService } from '@myia/ngx-localization';
import { ToastManager } from '@myia/ngx-toast';
import { Observable, Observer, Subscription } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { IEditorExtension } from '../entities/editorExtension.interface';
import { croppingService } from '../services/croppingService';
import { IWYSIWYGEditorComponent } from './wysiwygEditor.interface';

const fixedStyles =
    // fix visible table borders in readonly mode
    'body[contenteditable=false] .mce-item-table, body[contenteditable=false] .mce-item-table td, body[contenteditable=false] .mce-item-table th, body[contenteditable=false] .mce-item-table caption {' +
    'border: none' +
    '}';

@Component({
    selector: 'wysiwyg-editor-v5',
    styleUrls: ['./wysiwygEditor5.component.scss'],
    template: `
        <div class="wysiwyg-editor-block" [ngClass]="{initializing: isInitializing, readOnly: readonly}">
            <div class="hidden">
                <textarea>{{htmlContent}}</textarea>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WYSIWYGEditor5Component implements AfterViewInit, OnInit, OnDestroy, IWYSIWYGEditorComponent {

    @Input() trackImages = false;
    @Input() previewEvent?: string; // EmitterService event key to be raised when external component want to show content preview
    @Input() toolbarAlwaysVisible = false;
    @Input() contentStylesFixedForEditor?: string;
    @Input() viewPortTopOffset: number = 0; // e.g. height of top bar that overflows content
    @Input() bottomToolbarEl?: HTMLElement;
    @Input() editorVersion?: string;
    @Input() imageUploader?: (imageBlob: Blob) => Observable<string>;
    @Input() fixedSize = false;

    @Output() contentChanged: EventEmitter<string>;
    @Output() cssChanged: EventEmitter<string>;
    @Output() contentLoaded: EventEmitter<void>;
    @Output() editorClicked: EventEmitter<void>;
    @Output() editorLeft: EventEmitter<void>;

    isInitializing = false;
    isEditorInBackground = false;

    onScroll = new EventEmitter<void>();

    private _editorExtensions?: Array<IEditorExtension>;
    private _elementRef: ElementRef;
    private readonly _elementID: string;
    private _htmlContent?: string;
    private _htmlContentCss?: string;
    private _htmlEditedContent?: string;
    private _showToolbars = false;
    private _isReadOnly = false;
    private _editorScript?: string;
    private _currentEditor?: any;
    private _isToolbarVisible = false;
    private _myiaPluginsAdded = false;
    private _isToolbarFixed = false;
    private _previewSubscription: any;
    private _isSettingHtmlContent = false;
    private _croppingServiceSubscription?: Subscription;
    private _parentScroll: any;
    private _tinyMce: any;
    private _extensionEditorStyles?: string;

    constructor(@Inject(ElementRef) _elementRef: ElementRef, private _localizationService: LocalizationService, private _zone: NgZone, private _changeDetectorRef: ChangeDetectorRef, private _cultureService: CultureService, private _toastManager: ToastManager, private _logger: Logger) {
        this._elementRef = _elementRef;
        this.isInitializing = true;

        const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
        const editorId = randLetter + Date.now();
        this._elementID = 'tinymce' + editorId;

        // events
        this.contentLoaded = new EventEmitter<void>();
        this.contentChanged = new EventEmitter<string>();
        this.cssChanged = new EventEmitter<string>();
        this.editorClicked = new EventEmitter<void>();
        this.editorLeft = new EventEmitter<void>();

        this._cultureService.onChange.subscribe(() => {
            this.destroyEditor();
            this.initializeEditor();
        });
    }

    initializeEditor() {
        if (!this._tinyMce) {
            console.log('TinyMce was not loaded yet !!!');
            return;
        }
        const importStyles = this._htmlContentCss && this._htmlContentCss.indexOf('http') !== 0;
        this._extensionEditorStyles = this.fixedSize ? 'html, body {height: 100%; overflow-y: hidden;}' : 'html {height: auto !important; min-height: 0 !important}'; // set auto height to body to override style for page displayed in mobile app (allows autosize plugin to work correctly)
        if (this._editorExtensions) {
            this._editorExtensions.forEach((ext) => {
                this._extensionEditorStyles += ext.getEditorStyles(this.localizeExtensionsResources.bind(this));
            });
        }
        this._tinyMce.baseURL = '/tinymce5';

        const that = this;
        // Attach tinyMCE to textarea clone
        let creationParams = {
            mode: 'exact',
            skin: 'oxide-dark',
            content_css: importStyles ? null : this._htmlContentCss,
            content_style: this.getAllStyles(),
            language: this._cultureService.currentCulture,
            plugins: [
                'advlist autolink lists link charmap print anchor',
                'searchreplace visualblocks fullscreen',
                'insertdatetime media',
                'table paste hr',
                'code',
                'noneditable',
                'myiaImageTools',
                'myiaPreview',
                'myiaCssStyles',
                'image',
                'autoresize',
                //'myiaStickyToolBar'
            ],
            menubar: 'edit insert view format table',
            menu: {
                // file: {title: 'File', items: 'newdocument'},
                edit: {
                    title: 'Edit',
                    items: 'undo redo | cut copy paste pastetext | cropImage | selectall | searchreplace'
                },
                insert: {title: 'Insert', items: 'link image | hr | charmap anchor insertdatetime'},
                view: {title: 'View', items: 'visualblocks visualaid | code myiaCssStyles | myiaPreview fullscreen'},
                format: {
                    title: 'Format',
                    items: 'bold italic underline strikethrough superscript subscript | formats | removeformat'
                },
                table: {title: 'Table', items: 'inserttable tableprops deletetable | cell row column'},
            },
            toolbar: 'undo redo | styleselect | sizeselect fontsizeselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | cropImage',
            toolbar_drawer: 'sliding',
            contextmenu: 'link image cropImage inserttable | cell row column deletetable',
            statusbar: false,
            verify_html: false, // do not remove empty tags
            readonly: this._isReadOnly,
            elements: this._elementID,
            forced_root_block: false,
            target_list: false, // disable target selection in 'insert link' dialog
            default_link_target: '_system',
            link_title: false, // disable link title field in 'insert link' dialog
            relative_urls: false,
            convert_urls: false,
            remove_script_host: false,
            anchor_top: false,
            anchor_bottom: false,
            autoresize_overflow_padding: 0,
            autoresize_bottom_margin: 0,
            min_height: 200,
            paste_as_text: true,
            /* disable title field in the Image dialog*/
            image_title: false,
            /* disable description field in the Image dialog*/
            image_description: false,
            /* hide upload tab in image dialog */
            image_uploadtab: false,
            /* enable automatic uploads of images represented by blob or data URIs*/
            automatic_uploads: false,
            images_upload_handler: function (blobInfo: any, success: Function, failure: Function) {
                that.imageUploader!(blobInfo.blob()).subscribe({
                    next: (url) => {
                        success(url);
                    },
                    error: (err) => {
                        console.error('Image upload error:' + err);
                        that._toastManager.error(that._localizationService.translate('TinyMce5.Image_Upload_Error'));
                        // return base64 image as url to be able to try to upload it again later
                        const reader = new FileReader();
                        reader.onload = (event: any) => {
                            success(event.target.result);
                        };
                        reader.readAsDataURL(blobInfo.blob());
                    }
                });
            },
            file_picker_types: 'image',
            /* and here's our custom image picker*/
            file_picker_callback: function (cb: any, value: any, meta: any) {
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');

                /*
                  Note: In modern browsers input[type="file"] is functional without
                  even adding it to the DOM, but that might not be the case in some older
                  or quirky browsers like IE, so you might want to add it to the DOM
                  just in case, and visually hide it. And do not forget do remove it
                  once you do not need it anymore.
                */

                input.onchange = function () {
                    var file = (this as any).files[0];

                    const reader = new FileReader();
                    reader.onload = function () {
                        /*
                          Note: Now we need to register the blob in TinyMCEs image blob
                          registry. In the next release this part hopefully won't be
                          necessary, as we are looking to handle it internally.
                        */
                        var id = 'blobid' + (new Date()).getTime();
                        var blobCache = that._tinyMce.activeEditor.editorUpload.blobCache;
                        var base64 = (reader.result as any).split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        /* call the callback and populate the Title field with the file name */
                        cb(blobInfo.blobUri(), {title: file.name});
                    };
                    reader.readAsDataURL(file);
                };

                input.click();
            },
            setup: this.tinyMCESetup.bind(this)
        };
        const result = this._tinyMce.init(creationParams);
        result.then(() => {
            this._zone.run(() => {
                that.isInitializing = false;
                that._changeDetectorRef.markForCheck();
                setTimeout(() => {
                    if (this._currentEditor && this._currentEditor.editorContainer) {
                        // expand second toolbar
                        const toolBarOverflowPart = this._currentEditor.editorContainer.querySelector('.tox-toolbar__overflow--closed') as HTMLElement;
                        if (toolBarOverflowPart) {
                            toolBarOverflowPart.style.height = 'auto';
                            toolBarOverflowPart.classList.remove('tox-toolbar__overflow--closed');
                        }

                        // hide more button
                        const toolBarGroups = this._currentEditor.editorContainer.querySelectorAll('.tox-toolbar__primary .tox-toolbar__group');
                        if (toolBarGroups.length > 0) {
                            toolBarGroups[toolBarGroups.length - 1].style.display = 'none';
                        }
                    }
                    that.contentLoaded.emit();
                });
            });
        });
        return result;
    }

    destroyEditor() {
        if (this._currentEditor) {
            try {
                removeAllListeners(this.getScrollContainer(), ['scroll', 'resize']);
                const editorElements = this._tinyMce.get(this._elementID);
                if (editorElements) {
                    // destroy cloned elements
                    editorElements.remove();
                }
                if (this._croppingServiceSubscription) {
                    this._croppingServiceSubscription.unsubscribe();
                }
                if (this._editorExtensions) {
                    this._editorExtensions.forEach((ext) => {
                        if (ext.destroy) {
                            ext.destroy();
                        }
                    });
                }

            } catch (e) {
                this._logger.error('destroyEditor failed.');
            }
        }
        removeAllListeners(this.getScrollContainer(), ['scroll', 'resize']);
    }

    addMyiaPlugins() {
        if (!this._myiaPluginsAdded) {
            const $ = this._tinyMce.dom.DomQuery;
            this._myiaPluginsAdded = true;

            const imgToolbarItems = ['image cropImage'];

            const that = this;

            const selectTextareaLine = (tarea: any, lineNum: number, column: number, len: number) => {
                lineNum--; // array starts at 0
                const lines = tarea.value.split('\n');

                // calculate start/end
                let startPos = 0;
                let endPos = tarea.value.length;
                for (let x = 0; x < lines.length; x++) {
                    if (x === lineNum) {
                        break;
                    }
                    startPos += (lines[x].length + 1);

                }

                startPos += len ? column : 0;
                endPos = startPos + (len || lines[lineNum].length);

                tarea.setSelectionRange(startPos, endPos);

                // we need to scroll to this row but scrolls are in pixels,
                // so we need to know a row's height, in pixels
                const lineHeight = tarea.scrollHeight / lines.length;

                tarea.scrollTop = lineHeight * lineNum - tarea.clientHeight / 2;
            };

            // css styles code plugin
            this._tinyMce.PluginManager.add('myiaCssStyles', function (editor: any) {
                const setContent = (editor: any, css: string, resultCallback: Function) => {
                    (async () => {
                        // @ts-ignore
                        const prettyCss = await import('prettycss');
                        const parsedCss = prettyCss.parse(css);
                        if (parsedCss.errors && parsedCss.errors.length) {
                            const editorElement = document.querySelector('.tox-dialog__content-js textarea');
                            const errLine = parsedCss.errors[0].token.line;
                            const errColumn = parsedCss.errors[0].token.charNum - 1;
                            const errLen = parsedCss.errors[0].token.content ? parsedCss.errors[0].token.content.length : 0;
                            selectTextareaLine(editorElement, errLine, errColumn, errLen);
                            console.log('Css errors detected:' + JSON.stringify(parsedCss.errors));
                            that._toastManager.error(that._localizationService.translate('WelcomePage.CSS_Code_Validation_Failed'));
                            resultCallback(false);
                        } else {
                            editor.focus();
                            const stylesEl = Array.from(editor.contentDocument.head.children).find((e: any) => e.tagName === 'STYLE') as any;
                            that._htmlContentCss = css;
                            const allStyles = that.getAllStyles.call(that);
                            editor.settings.content_style = allStyles;
                            stylesEl.innerHTML = allStyles;
                            that.cssChanged.emit(css);
                            editor.selection.setCursorLocation();
                            editor.nodeChanged();
                            resultCallback(true);
                        }
                    })();
                };
                const getContent = (editor: any, success: Function) => {
                    (async () => {
                        // @ts-ignore
                        const prettyCss = await import('prettycss');
                        success(prettyCss.parse(that._htmlContentCss).toString());
                    })();
                };
                const Content = {
                    setContent: setContent,
                    getContent: getContent
                };

                const open = (editor: any) => {
                    Content.getContent(editor, (editorContent: string) => {
                        editor.windowManager.open({
                            title: that._localizationService.translate('TinyMce5.Css_Styles_Code'),
                            size: 'large',
                            body: {
                                type: 'panel',
                                items: [{
                                    type: 'textarea',
                                    name: 'code'
                                }]
                            },
                            buttons: [
                                {
                                    type: 'cancel',
                                    name: 'cancel',
                                    text: 'Cancel'
                                },
                                {
                                    type: 'submit',
                                    name: 'save',
                                    text: 'Save',
                                    primary: true
                                }
                            ],
                            initialData: {code: editorContent},
                            onSubmit: function (api: any) {
                                Content.setContent(editor, api.getData().code, (close: boolean) => {
                                    if (close) {
                                        api.close();
                                    }
                                });

                            }
                        });
                    });
                };
                var Dialog = {open: open};
                editor.addCommand('myiaCssStyles', () => {
                    Dialog.open(editor);
                });

                editor.ui.registry.addMenuItem('myiaCssStyles', {
                    icon: 'css-code',
                    text: that._localizationService.translate('TinyMce5.Css_Styles_Code'),
                    onAction: (_: any) => editor.execCommand('myiaCssStyles'),
                });
                import('!!raw-loader!../../../img/tinymce5/css-code.svg').then(({default: cssCodeIcon}) => {
                    editor.ui.registry.addIcon('css-code', cssCodeIcon.default);
                });

            });

            // image crop plugin
            this._tinyMce.PluginManager.add('myiaImageTools', function (editor: any) {
                const cropImage = () => {
                    let selectedEl = editor.selection.getNode();
                    editor.selection.collapse();
                    editor.iframeElement.blur();
                    that.isEditorInBackground = true;
                    that.setToolbarsFixedMode(false);
                    that._croppingServiceSubscription =
                        getImageMimeType(selectedEl.getAttribute('src')).pipe(
                            mergeMap((mimeType: string | undefined) => {
                                return croppingService.cropImage(selectedEl, editor.iframeElement, true, null,
                                    {
                                        minContainerWidth: selectedEl.offsetWidth,
                                        minContainerHeight: selectedEl.offsetHeight,
                                        background: true,
                                        mimeType: mimeType
                                    });
                            }),
                            finalize(() => {
                                // show original image element hidden by cropper service
                                that._currentEditor?.dom.setStyle(selectedEl, 'visibility', 'visible');
                                that.finishCommand(selectedEl);
                            })
                        )
                            .subscribe((croppedImgData) => {
                                if (croppedImgData) {
                                    that.updateImageSource.call(that, selectedEl, croppedImgData.data);
                                }
                            });
                };

                editor.addCommand('myiaCropImage', cropImage);

                const isTargetNode = function (element?: any) {
                    if (!element) {
                        element = editor.selection ? editor.selection.getNode() : null;
                    }
                    return element && element.tagName === 'IMG';
                };
                let cropButtons: Array<any> = [];
                editor.ui.registry.addButton('cropImage', {
                    title: that.localizeExtensionsResources('Editor.CropImage'),
                    icon: 'crop',
                    onAction: (_: any) => editor.execCommand('myiaCropImage'),
                    onPostRender: function () {
                        cropButtons.push(this);
                    }
                });
                editor.ui.registry.addContextToolbar('cropImage',
                    {
                        predicate: function (node: any) {
                            return isTargetNode(node);
                        },
                        items: imgToolbarItems.join(' '),
                        scope: 'node',
                        position: 'node'
                    }
                );

                let cropMenuItems: Array<any> = [];
                editor.ui.registry.addMenuItem('cropImage', {
                    text: that.localizeExtensionsResources('Editor.CropImage'),
                    icon: 'crop',
                    onAction: (_: any) => editor.execCommand('myiaCropImage'),
                    disabled: !isTargetNode(),
                    context: 'edit',
                    onPostRender: function () {
                        cropMenuItems.push(this);
                        this.disabled(!isTargetNode());
                    }
                });

                editor.on('blur', function (e: any) {
                    if (!that.isEditorInBackground) {
                        editor.save();
                        that.editorLeft.emit();
                    }
                });
                editor.on('NodeChange', function (e: any) {
                    const isCommandEnabled = isTargetNode(e.element);
                    cropButtons.forEach((cropButton) => {
                        cropButton.disabled(!isCommandEnabled);
                    });
                    cropMenuItems.forEach((cropMenuItem) => {
                        cropMenuItem.disabled(!isCommandEnabled);
                    });
                });
            });

            this._tinyMce.PluginManager.add('myiaPreview', function (editor: any) {
                const settings = editor.settings, sandbox = !that._tinyMce.Env.ie;

                editor.addCommand('myiaPreview', function (ui: any, v: any) {
                    const closeCallback = v ? v.onClose : null;
                    let win: any;
                    editor.on('CloseWindow', function (e: any) {
                        if (closeCallback && e.win === win) {
                            closeCallback();
                        }
                    });
                    const dialogOptions = {
                        title: v && v.title ? v.title : 'Preview',
                        width: parseInt(editor.getParam('plugin_preview_width', '650'), 10),
                        height: parseInt(editor.getParam('plugin_preview_height', '500'), 10),
                        url: 'data:text/html;charset=utf-8,' + encodeURIComponent(`<script type="text/javascript">window.addEventListener('message', function(event) {
                                if (event.data && event.data.wpContentFrame) {
                                    var iFrameHtml = event.data.wpContentFrame;
                                    document.body.setAttribute('style', 'padding:0;margin:0');
                                    document.body.innerHTML=  iFrameHtml;
                                    var dialogiFrame = document.getElementsByTagName('iframe')[0];
                                    if (!event.data.sandbox) {
                                        var doc = dialogiFrame.contentWindow.document;
                                        doc.open();
                                        doc.write(previewHtml);
                                        doc.close();
                                    } else {
                                        dialogiFrame.src = 'data:text/html;charset=utf-8,' + encodeURIComponent(event.data.previewHtml);
                                    }

                                }
                              });
                              window.parent.postMessage({iFrameLoaded: true}, '*');</script>`),
                        // buttons: [{
                        //     type: 'cancel',
                        //     name: 'cancel',
                        //     text: 'Close',
                        //     onclick: function () {
                        //         win.close();
                        //     }
                        // }],
                        onIFrameLoaded: function (event: any) {
                            if (event.data && event.data.iFrameLoaded) {
                                let previewHtml, headHtml = '';

                                headHtml += '<base href="' + editor.documentBaseURI.getURI() + '">';

                                that._tinyMce.each(editor.contentCSS, function (url: string) {
                                    headHtml += '<link type="text/css" rel="stylesheet" href="' + editor.documentBaseURI.toAbsolute(url) + '">';
                                });

                                // changes start
                                if (typeof editor.settings.content_style !== 'undefined') {
                                    headHtml += '<style id="pageStyles">' + editor.settings.content_style + '</style>';
                                }

                                // changes end

                                let bodyId = settings.body_id || 'tinymce';
                                if (bodyId.indexOf('=') !== -1) {
                                    bodyId = editor.getParam('body_id', '', 'hash');
                                    bodyId = bodyId[editor.id] || bodyId;
                                }

                                let bodyClass = settings.body_class || '';
                                if (bodyClass.indexOf('=') !== -1) {
                                    bodyClass = editor.getParam('body_class', '', 'hash');
                                    bodyClass = bodyClass[editor.id] || '';
                                }

                                let preventClicksOnLinksScript = (
                                    '<script>' +
                                    'document.addEventListener && document.addEventListener("click", function(e) {' +
                                    'for (var elm = e.target; elm; elm = elm.parentNode) {' +
                                    'if (elm.nodeName === "A") {' +
                                    'e.preventDefault();' +
                                    '}' +
                                    '}' +
                                    '}, false);' +
                                    '</script> '
                                );

                                const dirAttr = editor.settings.directionality ? ' dir="' + editor.settings.directionality + '"' : '';

                                previewHtml = (
                                    '<!DOCTYPE html>' +
                                    '<html>' +
                                    '<head>' +
                                    headHtml +
                                    '</head>' +
                                    '<body id="' + bodyId + '" class="mce-content-body ' + bodyClass + '"' + dirAttr + '>' +
                                    editor.getContent() +
                                    preventClicksOnLinksScript +
                                    '</body>' +
                                    '</html>'
                                );
                                const iFrameWindow = event.source;
                                iFrameWindow.postMessage({
                                    wpContentFrame: `<iframe src="javascript:''" frameborder="0" ${(sandbox ? 'sandbox="allow-scripts"' : '')} style="width:100%;height:100%"></iframe>`,
                                    previewHtml,
                                    sandbox
                                }, '*');
                            }
                        }
                    };

                    window.addEventListener('message', dialogOptions.onIFrameLoaded);

                    win = editor.windowManager.openUrl(dialogOptions);
                });

                editor.ui.registry.addButton('myiaPreview', {
                    title: 'Preview',
                    onAction: (_: any) => editor.execCommand('myiaPreview'),
                });

                editor.ui.registry.addMenuItem('myiaPreview', {
                    text: 'Preview',
                    onAction: (_: any) => editor.execCommand('myiaPreview'),
                    context: 'view'
                });
            });
        }
    }

    getAllStyles() {
        const importStyles = this._htmlContentCss && this._htmlContentCss.indexOf('http') !== 0;
        return (this.contentStylesFixedForEditor || '') + this._extensionEditorStyles + fixedStyles + (importStyles ? this._htmlContentCss : '');
    }

    initializeLoadedEditor() {
        this.addMyiaPlugins();
        this.initializeEditor();
    }

    addToolbars() {
        if (this._editorExtensions) {
            try {
                this._editorExtensions.forEach((ext) => {
                    ext.register(this._currentEditor, this.localizeExtensionsResources.bind(this));
                });
            } catch (err) {
                this._logger.error(err as string);
                this._toastManager.error('Editor extension registration failed.');
            }
        }
    }

    localizeExtensionsResources(resourceKey: string) {
        return this._localizationService.translate(resourceKey);
    }

    ngAfterViewInit() {
        // Clone base textarea
        const baseTextArea = this._elementRef.nativeElement.querySelector('.wysiwyg-editor-block textarea');
        const clonedTextArea = baseTextArea.cloneNode(true);
        clonedTextArea.id = this._elementID;

        const editorBlock = this._elementRef.nativeElement.querySelector('.wysiwyg-editor-block');
        editorBlock.appendChild(clonedTextArea);

        const that = this;
        (async () => {
            // @ts-ignore
            const {default: tinyMce} = await import('tinymce5lib');
            // @ts-ignore
            await import('tinymce5libIcons');
            that._tinyMce = tinyMce;
            (window as any)['tinymce5'] = tinyMce; // used by code injected by webpack wrapper plugin
            that.initializeLoadedEditor();
        })();
    }

    ngOnInit() {
        if (this.previewEvent) {
            this._previewSubscription = EmitterService.getEvent(this.previewEvent).subscribe((options: any) => {
                this.preview(options);
            });
        }
    }

    ngOnDestroy() {
        this.destroyEditor();
        // remove elements
        this._elementRef.nativeElement.querySelector('.wysiwyg-editor-block').remove();
        if (this._previewSubscription) {
            this._previewSubscription.unsubscribe();
            this._previewSubscription = null;
        }
    }

    triggerContentChanged(content?: string) {
        this._htmlEditedContent = content || this._tinyMce.get(this._elementID).getContent();
        this.contentChanged.emit(this._htmlEditedContent);
    }

    generateDialogId() {
        return getNewComponentId();
    }

    tinyMCESetup(ed: any) {
        this._currentEditor = ed;
        if (this._isReadOnly) {
            this._currentEditor?.setMode('readonly');
        }
        // set container for correct position calculation flow controls (menu/dropdowns/...)
        ed.editorManager.Env.container = this._elementRef.nativeElement;
        // set reference to this component for extensions
        ed.component = this;

        ed.on('keyup', this.tinyMCEOnKeyup.bind(this));
        ed.on('click', this.tinyMCEOnClick.bind(this));
        ed.on('init', this.tinyMCEOnInit.bind(this));
        ed.on('SaveContent', this.tinyMCEOnSaveContent.bind(this));

        ed.on('change', this.tinyMCEOnChange.bind(this));

        ed.on('keydown', this.tinyMCEOnKeyDown.bind(this));
        this.addToolbars();
    }

    tinyMCEOnInit() {
        // initially hide toolbar
        this.tinyMCESetToolbarVisibility(this._showToolbars);
        // set initial content
        this.setEditorContent(this._htmlEditedContent);
        if (!this._isReadOnly && this.toolbarAlwaysVisible) {
            addListener(this.getScrollContainer(), ['scroll', 'resize'], this.onEditorScroll.bind(this));
        }
        if (this._editorExtensions) {
            this._editorExtensions.forEach((ext) => {
                ext.initializeContent();
            });
        }

        // native editor 'Click event' is not fired when tinymce is in readonly mode
        const docBody = this._currentEditor?.getDoc().body;
        docBody?.addEventListener('click', () => {
            if (this._isReadOnly) {
                this.tinyMCEOnClick();
            }
        });
    }

    uploadImages() {
        return new Observable((observer: Observer<void>) => {
            this._currentEditor?.uploadImages((uploadedImages: Array<{ element: any, status: boolean }>) => {
                if (uploadedImages) {
                    if (uploadedImages.length) {
                        this.triggerContentChanged();
                    }
                    observer.next();
                } else {
                    observer.error('Image upload failed!');
                }
                observer.complete();
            });
        });
    }

    getScrollContainer() {
        if (!this._parentScroll) {
            this._parentScroll = getParentScroll(this._elementRef.nativeElement);
        }
        return this._parentScroll;
    }

    onEditorScroll(e: any) {
        this.repositionToolbar(e);
        // hide opened context menu on tooltip
        const menus = document.querySelectorAll<HTMLElement>('.tox-menu, .tox-pop');
        menus.forEach(menu => {
            menu.style.display = 'none';
        });

        this.onScroll.emit();
    }

    repositionToolbar(e: any) {
        if (this.isEditorInBackground) {
            return;
        }
        try {
            const editorEl = this.getEditorEl();
            if (editorEl) {
                const shouldBeFixed = editorEl.getBoundingClientRect().top < this.getScrollContainer().getBoundingClientRect().top + this.viewPortTopOffset;
                if (shouldBeFixed !== this._isToolbarFixed || (shouldBeFixed && e.type === 'resize')) {
                    this.setToolbarsFixedMode(shouldBeFixed, editorEl);
                }
            }
        } catch (err) {
            this._logger.warn('repositionToolbar failed: ' + err);
        }
    }

    getEditorEl(): HTMLElement | undefined {
        return this._currentEditor?.container;
    }

    getFixedToolBarsHeight(editorEl: HTMLElement): number {
        if (this._isToolbarFixed) {
            const toolbarEls = editorEl.querySelectorAll<HTMLElement>('.tox-editor-header');
            let toolbarsHeight = 0;
            toolbarEls.forEach(toolBarEl => {
                toolbarsHeight += toolBarEl.clientHeight;
            });
            return toolbarsHeight;
        }
        return 0;
    }

    setToolbarsFixedMode(isFixed: boolean, editorEl?: HTMLElement) {
        if (!editorEl) {
            editorEl = this.getEditorEl();
        }
        const toolbarEls = editorEl?.querySelectorAll<HTMLElement>('.tox-editor-header');
        this._isToolbarFixed = isFixed;
        const wrapEl = editorEl?.querySelector('.tox-sidebar-wrap') as HTMLElement;
        if (isFixed) {
            let toolbarsHeight = 0;
            toolbarEls?.forEach((toolBarEl: HTMLElement) => {
                toolbarsHeight += toolBarEl.clientHeight;
            });
            toolbarEls?.forEach((toolBarEl: HTMLElement) => {
                toolBarEl.classList.add('isFixed');
                toolBarEl.style.width = `${editorEl?.clientWidth}px`;
            });
            wrapEl.style.marginTop = `${toolbarsHeight}px`;
        } else {
            toolbarEls?.forEach((toolBarEl: HTMLElement) => {
                toolBarEl.classList.remove('isFixed');
                toolBarEl.style.width = 'auto';
            });
            wrapEl.style.marginTop = `0`;
        }
    }

    tinyMCEOnClick() {
        this._zone.run(() => {
            this.editorClicked.emit();
            // notify all other components about click to editor iframe (e.g. required for popup closing)
            EmitterService.getEvent(IFRAME_CLICKED).emit();
        });
    }

    tinyMCEOnKeyup(e: any) {
        if (this._editorExtensions) {
            this._editorExtensions.forEach((ext) => {
                if (ext.onKeyUp) {
                    ext.onKeyUp(e, this._currentEditor);
                }
            });
        }
        this.triggerContentChanged();
    }

    tinyMCEOnSaveContent(e: any) {
        if (this._editorExtensions) {
            this._editorExtensions.forEach((ext) => {
                if (ext.onSaveContent) {
                    ext.onSaveContent(e);
                }
            });
        }
        this.triggerContentChanged(e.content);
    }

    tinyMCEOnChange() {
        if (!this._isSettingHtmlContent) {
            this.triggerContentChanged();
        }
    }

    tinyMCEOnKeyDown(e: any) {
        if (this._editorExtensions) {
            this._editorExtensions.forEach((ext) => {
                if (ext.onKeyDown) {
                    ext.onKeyDown(e, this._currentEditor);
                }
            });
        }
    }

    tinyMCESetToolbarVisibility(visible: boolean) {
        if (this._currentEditor && this._isToolbarVisible !== visible) {
            const editorEl = this.getEditorEl();
            if (editorEl) {
                const toolBars = editorEl.querySelectorAll<HTMLElement>('.tox-editor-header');
                toolBars.forEach(toolBar => {
                    toolBar.style.display = visible ? 'block' : 'none';
                });
                this._currentEditor.execCommand('mceAutoResize');
            }
        }
    }

    resetContent() {
        this._isSettingHtmlContent = true;
        this._htmlEditedContent = this._htmlContent;
        this._changeDetectorRef.markForCheck();
        this.setEditorContent(this._htmlContent);
        this._isSettingHtmlContent = false;
    }

    get htmlContent() {
        return this._htmlContent;
    }

    @Input() set htmlContent(content: string | undefined) {
        if (this._htmlContent !== content) {
            this._htmlContent = content;
            this.resetContent();
        }
    }

    get contentCss() {
        return this._htmlContentCss;
    }

    @Input() set contentCss(contentCss: string | undefined) {
        if (this._htmlContentCss !== contentCss) {
            this._htmlContentCss = contentCss;
            if (this._currentEditor) {
                // recreate editor
                this.destroyEditor();
                this.initializeEditor();
            }
        }
    }

    get editorScript() {
        return this._editorScript;
    }

    @Input() set editorScript(editorScript: string | undefined) {
        if (this._editorScript !== editorScript) {
            this._editorScript = editorScript;
            (async () => {
                // @ts-ignore
                const {default: jquery} = await import('jquery');
                this._editorExtensions = this.getWysiwygEditorScriptFromString(editorScript, jquery);
                if (this._currentEditor) {
                    // recreate editor
                    this.destroyEditor();
                    this.initializeEditor();
                }
            })();
        }
    }

    @Input() set showToolbars(showToolbars: boolean) {
        this._showToolbars = showToolbars;
        this.tinyMCESetToolbarVisibility(this._showToolbars);
    }

    @Input() set readonly(readonly: boolean) {
        if (this._isReadOnly !== readonly) {
            this._isReadOnly = readonly;
            // revert _htmlEditedContent
            this._htmlEditedContent = this._htmlContent;
            if (this._currentEditor) {
                // recreate editor
                this.destroyEditor();
                this.initializeEditor();
            }
        }
    }

    get readonly() {
        return this._isReadOnly;
    }

    updateImageSource(selectedEl: string, inputFile: File | Blob) {
        // locally convert do dataUrl to display in page
        getFileDataUrl(inputFile).subscribe((imgUrl) => {
            this._currentEditor?.dom.setAttrib(selectedEl, 'src', imgUrl);
        });
    }

    private preview(options: any) {
        if (this._currentEditor) {
            this._currentEditor.execCommand('myiaPreview', false, options);
        }
    }

    private setEditorContent(content: string | undefined) {
        if (this._currentEditor) {
            this._currentEditor.setContent(content || '');
            try {
                this._currentEditor.execCommand('mceAutoResize');
            } catch (err) {
                this._logger.warn('mceAutoResize failed: ' + err);
            }
        }
    }

    private finishCommand(selectedEl: any) {
        this.setToolbarsFixedMode(this._isToolbarFixed);
        this._currentEditor?.iframeElement?.focus();
        // select with delay to avoid selection interference with other editor components
        setTimeout(() => {
            if (this._currentEditor?.selection) {
                this._currentEditor.selection.select(selectedEl);
            }
        }, 200);
        this.isEditorInBackground = false;
    }

    private getWysiwygEditorScriptFromString(script: string | undefined, jQuery: any): any {
        if (script) {
            try {
                const parsedFunction = new Function(`return ${script}`)();
                // pass jquery param to the function
                return parsedFunction(jQuery);
            } catch (e) {
                this._logger.warn('Editor script parse error:' + e);
            }
        }
        return [];
    }

}
