import { Observable, Subscription, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICompleterItem } from '../entities/completerItem.interface';
import { CompleterService } from '../components/completerService';

export abstract class GoogleMapsCompleterServiceBase extends CompleterService {

    private _searchObservable?: Subscription;

    search(term: string) {
        this.cancel();
        this._searchObservable = this.searchData(term)
            .pipe(
                map((data: any) => {
                    // Convert the result to CompleterItem[]
                    let matches = this.transformSearchResultData(data);
                    this.next(matches);
                })
            ).subscribe();
    }

    cancel() {
        this._searchObservable?.unsubscribe();
    }

    // this method should override inherited class
    protected searchData(term: string): Observable<Array<any>> {
        return throwError(() => new Error('The \'searchData\' method is not implemented.'));
    }

    protected transformSearchResultData(data: any): ICompleterItem[] {
        throw new Error('The \'transformSearchResultData\' method is not implemented.');
    }

}
