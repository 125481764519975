export const SET_EVENT_LIST_DISPLAY = 'SET_EVENT_LIST_DISPLAY';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export function setEventListDisplay(asList: boolean) {
    return {
        type: SET_EVENT_LIST_DISPLAY,
        asList
    };
}


export function setPageTitle(title: string) {
    return {
        type: SET_PAGE_TITLE,
        title
    };
}


