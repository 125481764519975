import { readOnlyEntity } from '@myia/ngx-core';
import { IUser } from './user.interface';

export function User(props: Partial<IUser>): IUser {
    return readOnlyEntity({
        email: '',
        fullName: undefined,
        ...props
    } as IUser);
}
