import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { SideBarComponent } from './sideBar';

@Component({
    selector: 'side-bar-item',
    styleUrls: ['./sideBarItem.component.scss'],
    template: `
        <a [routerLink]="linkUrl" routerLinkActive="router-link-active" [ngClass]="{collapsed: sideBar.isCollapsed, admin: adminOnly}"><svg-icon name="{{icon}}"></svg-icon><span class="menuText">{{itemTitle|trans}}</span></a>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarItemComponent {
    @Input() itemTitle?: string;
    @Input() icon?: string;
    @Input() linkUrl?: Array<string>;
    @Input() adminOnly = false;

    constructor(public sideBar: SideBarComponent, _changeDetectorRef: ChangeDetectorRef) {
        sideBar.collapseButtonClicked.subscribe(() => {
            setTimeout(() => {
                _changeDetectorRef.markForCheck();
            });
        });
    }
}
