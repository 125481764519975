export interface IAppConfig {
    title: string;
    host: string;
    port: number;
    ENV: string;
    reduxStorageKey: string;
    messagePhotoMaxWidth: number;
    messagePhotoMaxHeight: number;
    messagePhotoAspectRatio: number;
    messagePhotoMinWidth: number;
    cultures: string;
    defaultCulture: string;
    showDevTools: boolean;
    logLevel: string;
    baseUrl: string;
    helpUrl: string;
    GoPayUrl: string;
    GDPRUrl: string;
    BASE_API_URL: string;
    SIGNALR_URL: string;
    BASE_AUTH_API_URL: string;
    AUTH_APP_ID: string;
    PWA: boolean;
    isProd: boolean;
}

export const AppConfig = {
    // @ts-ignore
    isProd: !!window?.process?.env?.ISPROD ?? false,
    ...process.env['MYIA_CONFIG'] as any
} as IAppConfig;

