import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { timeInterval, finalize, share, switchMap } from 'rxjs/operators';

/**
 * Timer service
 */
@Injectable({ providedIn: 'root' })
export class TimerService {

    private _timerGroups: any = {};

    getTimer(timerKey: string, timerInterval: number): BehaviorSubject<void> {
        if (!this._timerGroups[timerKey]) {
            const tmr = timer(0, timerInterval)
                .pipe(
                    timeInterval(),
                    // tap(() => {
                    //     console.log(`Timer ${timerKey} tick!`);
                    // }),
                    finalize(() => {
                        // all subscriptions un-subscribed
                        this.stopTimer(timerKey);
                    }),
                    share()
                );

            this._timerGroups[timerKey] = {
                interval: timerInterval,
                observable: new BehaviorSubject(null)
                    .pipe(
                        switchMap(() => tmr)
                    )
            };
            //console.log('Timer group created.');
        }
        if (this._timerGroups[timerKey].interval !== timerInterval) {
            throw 'Detected different interval value for timer service group.';
        }
        this._timerGroups[timerKey].refCount++;
        return this._timerGroups[timerKey].observable;
    }

    private stopTimer(timerKey: string) {
        if (this._timerGroups[timerKey]) {
            //console.log('Timer group disposed.');
            this._timerGroups[timerKey].observable.complete();
            delete this._timerGroups[timerKey];
        }
    }
}
