export const CountryCodes = [
    // {
    //     'code': 'af',
    //     'name': 'Afrikaans'
    // }, {
    //     'code': 'sq',
    //     'name': 'Albanian'
    // }, {
    //     'code': 'ar-sa',
    //     'name': 'Arabic (Saudi Arabia)'
    // }, {
    //     'code': 'ar-iq',
    //     'name': 'Arabic (Iraq)'
    // }, {
    //     'code': 'ar-eg',
    //     'name': 'Arabic (Egypt)'
    // }, {
    //     'code': 'ar-ly',
    //     'name': 'Arabic (Libya)'
    // }, {
    //     'code': 'ar-dz',
    //     'name': 'Arabic (Algeria)'
    // }, {
    //     'code': 'ar-ma',
    //     'name': 'Arabic (Morocco)'
    // }, {
    //     'code': 'ar-tn',
    //     'name': 'Arabic (Tunisia)'
    // }, {
    //     'code': 'ar-om',
    //     'name': 'Arabic (Oman)'
    // }, {
    //     'code': 'ar-ye',
    //     'name': 'Arabic (Yemen)'
    // }, {
    //     'code': 'ar-sy',
    //     'name': 'Arabic (Syria)'
    // }, {
    //     'code': 'ar-jo',
    //     'name': 'Arabic (Jordan)'
    // }, {
    //     'code': 'ar-lb',
    //     'name': 'Arabic (Lebanon)'
    // }, {
    //     'code': 'ar-kw',
    //     'name': 'Arabic (Kuwait)'
    // }, {
    //     'code': 'ar-ae',
    //     'name': 'Arabic (U.A.E.)'
    // }, {
    //     'code': 'ar-bh',
    //     'name': 'Arabic (Bahrain)'
    // }, {
    //     'code': 'ar-qa',
    //     'name': 'Arabic (Qatar)'
    // }, {
    //     'code': 'eu',
    //     'name': 'Basque (Basque)'
    // }, {
    //     'code': 'bg',
    //     'name': 'Bulgarian'
    // }, {
    //     'code': 'be',
    //     'name': 'Belarusian'
    // }, {
    //     'code': 'ca',
    //     'name': 'Catalan'
    // }, {
    //     'code': 'zh-tw',
    //     'name': 'Chinese (Taiwan)'
    // }, {
    //     'code': 'zh-cn',
    //     'name': 'Chinese (PRC)'
    // }, {
    //     'code': 'zh-hk',
    //     'name': 'Chinese (Hong Kong SAR)'
    // }, {
    //     'code': 'zh-sg',
    //     'name': 'Chinese (Singapore)'
    // }, {
    //     'code': 'hr',
    //     'name': 'Croatian'
    // },
    {
        'code': 'cs',
        'name': 'Česky'
    },
    // {
    //     'code': 'da',
    //     'name': 'Danish'
    // }, {
    //     'code': 'nl',
    //     'name': 'Dutch (Standard)'
    // }, {
    //     'code': 'nl-be',
    //     'name': 'Dutch (Belgium)'
    // },
    {
        'code': 'en',
        'name': 'English'
    }
    // , {
    //     'code': 'en-us',
    //     'name': 'English (United States)'
    // }, {
    //     'code': 'en-gb',
    //     'name': 'English (United Kingdom)'
    // }, {
    //     'code': 'en-au',
    //     'name': 'English (Australia)'
    // }, {
    //     'code': 'en-ca',
    //     'name': 'English (Canada)'
    // }, {
    //     'code': 'en-nz',
    //     'name': 'English (New Zealand)'
    // }, {
    //     'code': 'en-ie',
    //     'name': 'English (Ireland)'
    // }, {
    //     'code': 'en-za',
    //     'name': 'English (South Africa)'
    // }, {
    //     'code': 'en-jm',
    //     'name': 'English (Jamaica)'
    // }, {
    //     'code': 'en',
    //     'name': 'English (Caribbean)'
    // }, {
    //     'code': 'en-bz',
    //     'name': 'English (Belize)'
    // }, {
    //     'code': 'en-tt',
    //     'name': 'English (Trinidad)'
    // }, {
    //     'code': 'et',
    //     'name': 'Estonian'
    // }, {
    //     'code': 'fo',
    //     'name': 'Faeroese'
    // }, {
    //     'code': 'fa',
    //     'name': 'Farsi'
    // }, {
    //     'code': 'fi',
    //     'name': 'Finnish'
    // }, {
    //     'code': 'fr',
    //     'name': 'French (Standard)'
    // }, {
    //     'code': 'fr-be',
    //     'name': 'French (Belgium)'
    // }, {
    //     'code': 'fr-ca',
    //     'name': 'French (Canada)'
    // }, {
    //     'code': 'fr-ch',
    //     'name': 'French (Switzerland)'
    // }, {
    //     'code': 'fr-lu',
    //     'name': 'French (Luxembourg)'
    // }, {
    //     'code': 'gd',
    //     'name': 'Gaelic (Scotland)'
    // }, {
    //     'code': 'ga',
    //     'name': 'Irish'
    // }, {
    //     'code': 'de',
    //     'name': 'German (Standard)'
    // }, {
    //     'code': 'de-ch',
    //     'name': 'German (Switzerland)'
    // }, {
    //     'code': 'de-at',
    //     'name': 'German (Austria)'
    // }, {
    //     'code': 'de-lu',
    //     'name': 'German (Luxembourg)'
    // }, {
    //     'code': 'de-li',
    //     'name': 'German (Liechtenstein)'
    // }, {
    //     'code': 'el',
    //     'name': 'Greek'
    // }, {
    //     'code': 'he',
    //     'name': 'Hebrew'
    // }, {
    //     'code': 'hi',
    //     'name': 'Hindi'
    // }, {
    //     'code': 'hu',
    //     'name': 'Hungarian'
    // }, {
    //     'code': 'is',
    //     'name': 'Icelandic'
    // }, {
    //     'code': 'id',
    //     'name': 'Indonesian'
    // }, {
    //     'code': 'it',
    //     'name': 'Italian (Standard)'
    // }, {
    //     'code': 'it-ch',
    //     'name': 'Italian (Switzerland)'
    // }, {
    //     'code': 'ja',
    //     'name': 'Japanese'
    // }, {
    //     'code': 'ko',
    //     'name': 'Korean'
    // }, {
    //     'code': 'ko',
    //     'name': 'Korean (Johab)'
    // }, {
    //     'code': 'lv',
    //     'name': 'Latvian'
    // }, {
    //     'code': 'lt',
    //     'name': 'Lithuanian'
    // }, {
    //     'code': 'mk',
    //     'name': 'Macedonian (FYROM)'
    // }, {
    //     'code': 'ms',
    //     'name': 'Malaysian'
    // }, {
    //     'code': 'mt',
    //     'name': 'Maltese'
    // }, {
    //     'code': 'no',
    //     'name': 'Norwegian (Bokmal)'
    // }, {
    //     'code': 'no',
    //     'name': 'Norwegian (Nynorsk)'
    // }, {
    //     'code': 'pl',
    //     'name': 'Polish'
    // }, {
    //     'code': 'pt-br',
    //     'name': 'Portuguese (Brazil)'
    // }, {
    //     'code': 'pt',
    //     'name': 'Portuguese (Portugal)'
    // }, {
    //     'code': 'rm',
    //     'name': 'Rhaeto-Romanic '
    // }, {
    //     'code': 'ro',
    //     'name': 'Romanian'
    // }, {
    //     'code': 'ro-mo',
    //     'name': 'Romanian (Republic of Moldova)'
    // }, {
    //     'code': 'ru',
    //     'name': 'Russian'
    // }, {
    //     'code': 'ru-mo',
    //     'name': 'Russian (Republic of Moldova)'
    // }, {
    //     'code': 'sz',
    //     'name': 'Sami (Lappish)'
    // }, {
    //     'code': 'sr',
    //     'name': 'Serbian (Cyrillic)'
    // }, {
    //     'code': 'sr',
    //     'name': 'Serbian (Latin)'
    // }, {
    //     'code': 'sk',
    //     'name': 'Slovak'
    // }, {
    //     'code': 'sl',
    //     'name': 'Slovenian'
    // }, {
    //     'code': 'sb',
    //     'name': 'Sorbian'
    // }, {
    //     'code': 'es',
    //     'name': 'Spanish (Spain)'
    // }, {
    //     'code': 'es-mx',
    //     'name': 'Spanish (Mexico)'
    // }, {
    //     'code': 'es-gt',
    //     'name': 'Spanish (Guatemala)'
    // }, {
    //     'code': 'es-cr',
    //     'name': 'Spanish (Costa Rica)'
    // }, {
    //     'code': 'es-pa',
    //     'name': 'Spanish (Panama)'
    // }, {
    //     'code': 'es-do',
    //     'name': 'Spanish (Dominican Republic)'
    // }, {
    //     'code': 'es-ve',
    //     'name': 'Spanish (Venezuela)'
    // }, {
    //     'code': 'es-co',
    //     'name': 'Spanish (Colombia)'
    // }, {
    //     'code': 'es-pe',
    //     'name': 'Spanish (Peru)'
    // }, {
    //     'code': 'es-ar',
    //     'name': 'Spanish (Argentina)'
    // }, {
    //     'code': 'es-ec',
    //     'name': 'Spanish (Ecuador)'
    // }, {
    //     'code': 'es-cl',
    //     'name': 'Spanish (Chile)'
    // }, {
    //     'code': 'es-uy',
    //     'name': 'Spanish (Uruguay)'
    // }, {
    //     'code': 'es-py',
    //     'name': 'Spanish (Paraguay)'
    // }, {
    //     'code': 'es-bo',
    //     'name': 'Spanish (Bolivia)'
    // }, {
    //     'code': 'es-sv',
    //     'name': 'Spanish (El Salvador)'
    // }, {
    //     'code': 'es-hn',
    //     'name': 'Spanish (Honduras)'
    // }, {
    //     'code': 'es-ni',
    //     'name': 'Spanish (Nicaragua)'
    // }, {
    //     'code': 'es-pr',
    //     'name': 'Spanish (Puerto Rico)'
    // }, {
    //     'code': 'sx',
    //     'name': 'Sutu'
    // }, {
    //     'code': 'sv',
    //     'name': 'Swedish'
    // }, {
    //     'code': 'sv-fi',
    //     'name': 'Swedish (Finland)'
    // }, {
    //     'code': 'th',
    //     'name': 'Thai'
    // }, {
    //     'code': 'ts',
    //     'name': 'Tsonga'
    // }, {
    //     'code': 'tn',
    //     'name': 'Tswana'
    // }, {
    //     'code': 'tr',
    //     'name': 'Turkish'
    // }, {
    //     'code': 'uk',
    //     'name': 'Ukrainian'
    // }, {
    //     'code': 'ur',
    //     'name': 'Urdu'
    // }, {
    //     'code': 've',
    //     'name': 'Venda'
    // }, {
    //     'code': 'vi',
    //     'name': 'Vietnamese'
    // }, {
    //     'code': 'xh',
    //     'name': 'Xhosa'
    // }, {
    //     'code': 'ji',
    //     'name': 'Yiddish'
    // }, {
    //     'code': 'zu',
    //     'name': 'Zulu'
    // }
];
