export const AUTH_STARTED = 'AUTH_STARTED';
export const AUTH_COMPLETED = 'AUTH_COMPLETED';
export const AUTH_FAILED = 'AUTH_FAILED';

export const AUTH_REFRESH_STARTED = 'AUTH_REFRESH_STARTED';
export const AUTH_REFRESH_COMPLETED = 'AUTH_REFRESH_COMPLETED';
export const AUTH_REFRESH_FAILED = 'AUTH_REFRESH_FAILED';

export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';

export function authStartedAction(email: string) {
    return {
        type: AUTH_STARTED,
        email: email
    };
}

export function authCompletedAction(clientId: string | null, email: string | null, token: string | null, refreshToken: string | null) {
    return {
        type: AUTH_COMPLETED,
        clientId: clientId,
        email: email,
        token: token,
        refreshToken: refreshToken
    };
}

export function authFailedAction() {
    return {
        type: AUTH_FAILED
    };
}

export function authRefreshStartedAction(refreshToken: string) {
    return {
        type: AUTH_REFRESH_STARTED,
        refreshToken: refreshToken
    };
}

export function authRefreshCompletedAction(email: string, token: string, refreshToken: string) {
    return {
        type: AUTH_REFRESH_COMPLETED,
        email: email,
        token: token,
        refreshToken: refreshToken
    };
}

export function authRefreshFailedAction() {
    return {
        type: AUTH_REFRESH_FAILED
    };
}


export function authLogoutAction() {
    return {
        type: AUTH_LOG_OUT
    };
}
