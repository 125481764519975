import { Inject } from '@angular/core';

let resolveParameters = function (target: any, params: any[]) {
    if(params) {
        params.forEach((param, index) => {
            // this code is needed to make @Inject work
            if (param && param.annotation && param.annotation instanceof Inject) {
                param(target, void 0, index);
            }
        });
    }
    return Reflect.getMetadata('parameters', target);
};

let getParamTypesAndParameters = function(target: any, paramTypes: Array<any>, rawParameters: Array<any>) {
    let parameters = [];
    if (paramTypes) {
        parameters = Array(paramTypes.length).fill(null);
        if (rawParameters) {
            rawParameters.slice(0, paramTypes.length).forEach((el, i) => {
                parameters[i] = el;
            });
        }
    }
    return [paramTypes, parameters];
};

export function MixinInjectable(...paramTypes: any[]) {
    return function (childTarget: any) {
        let parentTarget = Object.getPrototypeOf(childTarget.prototype).constructor;
        let [parentParamTypes, parentParameters] = getParamTypesAndParameters(parentTarget,
            Reflect.getMetadata('design:paramtypes', parentTarget),
            Reflect.getMetadata('parameters', parentTarget)
        );
        let [childParamTypes, childParameters] = getParamTypesAndParameters(childTarget,
            paramTypes || [],
            resolveParameters(childTarget, paramTypes)
        );

        Reflect.defineMetadata('design:paramtypes', childParamTypes.concat(parentParamTypes), childTarget);
        Reflect.defineMetadata('parameters', childParameters.concat(parentParameters), childTarget);
        Reflect.defineMetadata('parentParameters', parentParameters, childTarget);
    };
}
