import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumKey'
})
export class EnumKeyPipe implements PipeTransform {
    transform<T>(key: string, enumType: T): T {
        return enumType[key as keyof T] as T;
    }
}
