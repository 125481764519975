import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { getNewComponentId, Logger, parseHTML } from '@myia/ngx-core';
import { map } from 'rxjs/operators';

export class WYSIWYGEditorImage {
    imgElId?: string | null;
    imgElOrigId?: string | null;
    file?: File | Blob;
    imgUrl?: string | null;
    uploadedUrl?: string | null;
    isDeleted = false; // image was deleted (imgUrl was not found in current document)
    isActive = false; // image with imgElId is in document

    constructor(imgElId: string | undefined | null, imgUrl: string | undefined | null, origImgId: string | undefined | null) {
        this.imgElId = imgElId;
        this.imgUrl = imgUrl;
        this.imgElOrigId = origImgId;
    }
}

@Injectable({ providedIn: 'root' })
export class HtmlPageImageService {
    private _images: Array<WYSIWYGEditorImage>;

    private static findElementById(elId: string | null | undefined, elements: Array<HTMLElement>): HTMLElement | undefined {
        return elId ? elements.find( el => el.id === elId) : undefined;
    }

    constructor(private _logger: Logger) {
        this._images = [];
        this._logger.log('Created instance of HtmlPageImageService.');
    }

    clearImgData() {
        this._logger.log('Cleared HtmlPageImageService registry.');
        this._images = [];
    }

    getAllImgData(): Array<WYSIWYGEditorImage> {
        return this._images;
    }

    getImgData(imgElId: string | null | undefined): WYSIWYGEditorImage | undefined {
        if (!imgElId) {
            return undefined;
        }
        return this._images.find((img) => {
            return img.imgElId === imgElId || img.imgElOrigId === imgElId;
        });
    }

    updateImgDataFromElements(imagesElements: Array<HTMLImageElement>) {
        imagesElements.forEach((img: HTMLImageElement) => {
            const origId = img.getAttribute('id');
            const existingData = this.getImgData(origId);
            if (!existingData) {
                let newId;
                if (!origId) {
                    newId = 'img_' + getNewComponentId();
                    img.setAttribute('id', newId);
                }
                this.getOrCreateImgData(newId, img.getAttribute('src'), origId);
            }
        });
        // detect removed images
        this._images.forEach((imgData) => {
            const existingEl = imagesElements.find(img => img.src === imgData.imgUrl);
            imgData.isDeleted = !existingEl;
            imgData.isActive = !!HtmlPageImageService.findElementById(imgData.imgElId || imgData.imgElOrigId, imagesElements);
        });
        this._logger.log('images: ' + this._images.length);

    }

    updateImgWithFile(imgId: string, oldImageUrl: string, origImgId: string, inputFile: File|Blob) {
        const imgData = this.getOrCreateImgData(imgId, oldImageUrl, origImgId);
        if (imgData) {
            imgData.file = inputFile;
        }
    }


    updateImagesUrlInHtmlContent(htmlContent: string, searchByUrl?: boolean): Observable<string> {
        return new Observable((observer: Observer<string>) => {
            if (this._images.length) {
                const elements = parseHTML(htmlContent);
                this._images.forEach((imgData) => {
                    const elId = imgData.imgElId || imgData.imgElOrigId;
                    let imgNode = elId ? elements.getElementById(elId) : undefined;
                    if (imgNode) {
                        if (imgData.uploadedUrl) {
                            if (!imgNode && searchByUrl) {
                                // find image by oldUrl
                                imgNode = elements.querySelector<HTMLElement>('img[src=\'' + imgData.imgUrl + '\']');
                            }
                            imgNode?.setAttribute('src', imgData.uploadedUrl);
                        }
                        // set original id or remove id attribute from element
                        if (imgData.imgElOrigId) {
                            imgNode?.setAttribute('id', imgData.imgElOrigId);
                        } else {
                            imgNode?.removeAttribute('id');
                        }
                    }
                });
                const result = elements.body.innerHTML;
                elements.body.innerHTML = '';
                observer.next(result);
                observer.complete();
            }
            else {
                observer.next(htmlContent);
                observer.complete();
            }
        });
    }

    processFiles(uploadImage: (file: any) => Observable<string>, removeImage: ((url: string) => Observable<void>) | null): Array<Observable<string>> {
        const imageActions: Array<any> = [];
        this._images.forEach((imageData) => {
            if (imageData.isDeleted) {
                if (imageData.imgUrl) {
                    imageActions.push(removeImage?.(imageData.imgUrl));
                }
            }
            if (imageData.file && imageData.isActive) {
                imageActions.push(uploadImage(imageData.file).pipe(
                    map((imageUrl: string) => {
                        imageData.uploadedUrl = imageUrl;
                    })
                ));
                if (imageData.imgUrl && removeImage) {
                    imageActions.push(removeImage(imageData.imgUrl));
                }
            }
        });
        return imageActions;
    }


    cloneImage(image: WYSIWYGEditorImage): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', image.imgUrl!, true);

            // Ask for the result as an ArrayBuffer.
            xhr.responseType = 'arraybuffer';

            xhr.onload = function () {
                // Obtain a blob: URL for the image data.
                const arrayBufferView = new Uint8Array(xhr.response);
                const mimeType = xhr.getResponseHeader('content-type');
                image.file = new Blob([arrayBufferView], {type: mimeType!});
                observer.next();
                observer.complete();
            };
            xhr.send();
        });
    }

    // private methods //
    private getOrCreateImgData(imgElId: string | undefined | null, imgUrl?: string | null | undefined, origImgId?: string | null): WYSIWYGEditorImage | undefined {
        let result = this.getImgData(imgElId || origImgId);
        if (!result && imgElId) {
            result = new WYSIWYGEditorImage(imgElId, imgUrl, origImgId);
            this._images.push(result);
            this._logger.log('Created new image registration in HtmlPageImageService. Images count: ' + this._images.length);
        }
        return result;
    }

}
