import { Directive, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as ClipboardJS from 'clipboard';

@Directive({
    selector: '[clipboard]'
})
export class ClipboardDirective implements OnDestroy {
    clipboard?: ClipboardJS;

    @Output() cbOnSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() cbOnError: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _clipboardTargetEl?: Element;
    private _cbContent?: string;


    get clipboardTargetEl() {
        return this._clipboardTargetEl;
    }
    @Input() set clipboardTargetEl (value: Element | undefined) {
        if (this._clipboardTargetEl !== value) {
            this._clipboardTargetEl = value;
            this.destroy();
            this.init();
        }
    }

    get cbContent() {
        return this._cbContent;
    }
    @Input() set cbContent (value: string | undefined) {
        if (this._cbContent !== value) {
            this._cbContent = value;
            this.destroy();
            if (this._cbContent) {
                this.init();
            }
        }
    }

    constructor(private elmRef: ElementRef) {
    }

    ngOnDestroy() {
        this.destroy();
    }

    private init() {
        let option: ClipboardJS.Options;
        option = this.clipboardTargetEl ? {target: () => this.clipboardTargetEl!} : {text: () => this.cbContent!};
        this.clipboard = new ClipboardJS(this.elmRef.nativeElement, option);
        this.clipboard.on('success', (_) => {
            this.cbOnSuccess.emit(true);
        });
        this.clipboard.on('error', (_) => this.cbOnError.emit(true));
    }

    private destroy() {
        if (this.clipboard) {
            this.clipboard.destroy();
        }
    }
}
