import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { FormatDateService } from '@myia/ngx-localization';

const errorMessages: any = {
    'required': 'Validation.Required',
    'invalidEmailAddress': 'Validation.Invalid_Email_Address',
    'invalidUrl': 'Validation.Invalid_Url',
    'invalidDateTime': 'Validation.Invalid_DateTime',
    'passwordTooShort': 'Validation.Password_Too_Short',
};

/**
 * Validation service
 */
@Injectable({providedIn: 'root'})
export class ValidationService {

    private static Singleton: ValidationService;

    constructor(private _formatDateService: FormatDateService) {
        ValidationService.Singleton = this;
    }

    public urlValidator(control: AbstractControl | { value: string }) {
        try {
            if (control.value) {
                const _ = new URL(control.value);
            }
            return null;
        } catch (_) {
            return {'invalidUrl': true};
        }
    }

    public emailValidator(control: AbstractControl) {
        // RFC 2822 compliant regex
        if (!control.value || control.value.match(/[a-z0-9!#$%&'*+/=?^_`{.}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i)) {
            return null;
        } else {
            return {'invalidEmailAddress': true};
        }
    }

    public dateTimeValidator(control: AbstractControl) {
        if (!control.value || ValidationService.Singleton._formatDateService.isDateValid(control.value)) {
            return null;
        } else {
            return {'invalidDateTime': true};
        }
    }

    public timeValidator(control: AbstractControl) {
        if (!control.value || ValidationService.Singleton._formatDateService.isTimeValid(control.value)) {
            return null;
        } else {
            return {'invalidTime': true};
        }
    }

    public numberValidator(control: AbstractControl) {
        if (!control.value || !isNaN(control.value)) {
            return null;
        } else {
            return {'invalidNumber': true};
        }
    }

    public passwordLengthValidator(control: AbstractControl) {
        if (!control.value || control.value.length >= 6) {
            return null;
        } else {
            return {'passwordTooShort': true};
        }
    }


    public getValidatorErrorMessage(code: string) {
        return errorMessages[code] || code;
    }
}
