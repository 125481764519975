import { Directive, ElementRef, HostListener, Input, NgZone, OnChanges, OnDestroy } from '@angular/core';
import { calculateNodeHeight, getNewComponentId } from '@myia/ngx-core';
import { fromEvent, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

const MAX_LOOKUP_RETRIES = 3;

@Directive({
    selector: '[autoSize]',
    exportAs: 'autoSizeDirective' //the name of the variable to access the directive using ViewChild()
})
export class AutoSizeDirective implements OnDestroy, OnChanges {
    @Input() minRows?: number;
    @Input() maxRows?: number;
    @Input() onlyGrow = false;
    @Input() useImportant = false;

    @Input() autoSize = false;

    private retries = 0;
    private textAreaEl: any;

    private _oldContent?: string;
    private _oldWidth?: number;

    private readonly _uid: string;
    private _destroyed$ = new ReplaySubject(1);

    @HostListener('input')
    onInput(): void {
        this.adjust();
    }

    constructor(
        public element: ElementRef,
        private _zone: NgZone
    ) {
        this._uid = getNewComponentId();
        if (this.element.nativeElement.tagName !== 'TEXTAREA') {
            this._findNestedTextArea();

        } else {
            this.textAreaEl = this.element.nativeElement;
            this.textAreaEl.style.overflow = 'hidden';
            this._onTextAreaFound();
        }
    }

    ngOnDestroy() {
        this._destroyed$.next(true);
        this._destroyed$.complete();
    }

    ngOnChanges() {
        this.adjust(true);
    }

    _findNestedTextArea() {
        this.textAreaEl = this.element.nativeElement.querySelector('TEXTAREA');

        if (!this.textAreaEl && this.element.nativeElement.shadowRoot) {
            this.textAreaEl = this.element.nativeElement.shadowRoot.querySelector('TEXTAREA');
        }

        if (!this.textAreaEl) {
            if (this.retries >= MAX_LOOKUP_RETRIES) {
                console.warn('ngx-autosize: textarea not found');

            } else {
                this.retries++;
                setTimeout(() => {
                    this._findNestedTextArea();
                }, 100);
            }
            return;
        }

        this.textAreaEl.style.overflow = 'hidden';
        this._onTextAreaFound();

    }

    _onTextAreaFound() {
        this._zone.runOutsideAngular(() => {
            fromEvent(window, 'resize')
                .pipe(
                    takeUntil(this._destroyed$),
                    debounceTime(200),
                    distinctUntilChanged()
                )
                .subscribe(() => {
                    this._zone.run(() => {
                        this.adjust();
                    });
                });
        });
        setTimeout(() => {
            this.adjust();
        });
    }

    adjust(inputsChanged = false) {
        if (this.autoSize && this.textAreaEl) {
            const currentText = this.textAreaEl.value;

            if (
                inputsChanged === false &&
                currentText === this._oldContent &&
                this.textAreaEl.offsetWidth === this._oldWidth
            ) {
                return;

            }
            const nodeHeight = calculateNodeHeight(
                this.textAreaEl,
                this._uid,
                false,
                this.minRows,
                this.maxRows,
            );

            if (nodeHeight === null) {
                return;
            }

            let {
                height,
                minHeight,
                maxHeight,
                rowCount,
                valueRowCount,
            } = nodeHeight;

            //console.log(`${rowCount} - ${valueRowCount}- ${height}`);
            const willGrow = height > this.textAreaEl.offsetHeight;

            if (this.onlyGrow === false || willGrow) {
                let styleAttribute = `overflow: ${valueRowCount > rowCount ? 'auto' : 'hidden'};`;
                styleAttribute += `height: ${height}px`;
                styleAttribute += this.useImportant ? '!important;' : ';';
                this.textAreaEl.setAttribute('style', styleAttribute);
            }
        }
    }
}

