import { AfterViewChecked, AfterViewInit, Directive, ElementRef, inject, Input, OnInit } from '@angular/core';
import { EmitterService, LAYOUT_RESIZED, MediaQueryService } from '@myia/ngx-core';
import { ToolTipDirective } from '@myia/ngx-tooltip';
import { fromEvent } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { OnDestroyDirective } from '@myia/ngx-core-ui';

@Directive({
    selector: '[verticalText]',
    hostDirectives: [
        OnDestroyDirective
    ],
})
export class VerticalTextDirective implements OnInit, AfterViewInit, AfterViewChecked {

    @Input() mediaQuery?: string;

    private _destroy$ = inject(OnDestroyDirective).destroy$;
    private onResizeBind: EventListener = this.onResize.bind(this);

    constructor(private _element: ElementRef, private _toolTipDirective: ToolTipDirective) {
    }

    ngOnInit(): void {
        fromEvent(window, 'resize').pipe(
            throttleTime(500),
            takeUntil(this._destroy$)
        ).subscribe(this.onResizeBind);
        EmitterService.getEvent(LAYOUT_RESIZED).pipe(
            takeUntil(this._destroy$)
        ).subscribe(this.onResizeBind);
    }

    ngAfterViewInit() {
        this.onResize();
        setTimeout(this.onResizeBind, 200);
    }


    ngAfterViewChecked() {
        this.onResize();
    }

    onResize(): void {
        if (this._element && this._element.nativeElement) {
            const isVertical = (!this.mediaQuery || MediaQueryService.match(this.mediaQuery));
            this._element.nativeElement.style.maxWidth = isVertical ? `${this._element.nativeElement.parentElement.clientHeight}px` : `${this._element.nativeElement.parentElement.clientWidth}px`;
            if (this._toolTipDirective && this._toolTipDirective.enableWhenOverflow) {
                this._toolTipDirective.checkOverflow();
            }
        }
    }

}
