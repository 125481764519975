import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { IReadFile, readFile, ReadMode } from '@myia/ngx-core';

@Directive({
    selector: '[fileDropZone]'
})
export class FileDropZoneDirective {
    @Input() fileDropZone?: ReadMode;

    @Output() public fileDrop = new EventEmitter<Array<IReadFile>>();

    @HostListener('dragenter', ['$event'])
    public onDragEnter(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }

    @HostListener('dragover', ['$event'])
    public onDragOver(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }

    @HostListener('drop', ['$event'])
    public onDrop(event: any) {
        event.stopPropagation();
        event.preventDefault();

        const dt = event.dataTransfer;
        const files = dt.files;

        const filesData: Array<IReadFile> = [];
        let tasksCount = files.length;
        const completeTask = (fileData?: IReadFile) => {
            if (fileData) {
                filesData.push(fileData);
            }
            tasksCount--;
            if (tasksCount === 0) {
                this.fileDrop.emit(filesData);
            }
        };
        for (let i = 0; i < files.length; i++) {
            readFile(files[i], this.fileDropZone ?? ReadMode.binaryString).subscribe({
                next: file => {
                    completeTask(file);
                }, error: () => {
                    completeTask();
                }
            });
        }

    }
}
