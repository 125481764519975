import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { addListener, EmitterService, getElementOffset, getElementPosition, getFileDataUrl, getNewComponentId, getParentScroll, IFRAME_CLICKED, Logger, parseHTML, removeAllListeners } from '@myia/ngx-core';
import { CultureService } from '@myia/ngx-localization';
import { ToastManager } from '@myia/ngx-toast';
import { LocalizationService } from '@myia/ngx-localization';
import { Subscription, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IEditorExtension } from '../entities/editorExtension.interface';
import { croppingService } from '../services/croppingService';
import { HtmlPageImageService } from '../services/htmlPageImageService';
import { IWYSIWYGEditorComponent } from './wysiwygEditor.interface';

const fixedStyles =
    // fix visible table borders in readonly mode
    'body[contenteditable=false] .mce-item-table, body[contenteditable=false] .mce-item-table td, body[contenteditable=false] .mce-item-table th, body[contenteditable=false] .mce-item-table caption {' +
    'border: none' +
    '}';

const imagePlaceholderId = '_imagePlaceholder_';

@Component({
    selector: 'wysiwyg-editor-v4',
    styleUrls: ['./wysiwygEditor4.component.scss'],
    template: `
        <div class="wysiwyg-editor-block" [ngClass]="{initializing: isInitializing}">
            <div class="hidden">
                <textarea>{{htmlContent}}</textarea>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WYSIWYGEditor4Component implements AfterViewInit, OnInit, OnDestroy, IWYSIWYGEditorComponent {
    @Input() previewEvent?: string; // EmitterService event key to be raised when external component want to show content preview
    @Input() toolbarAlwaysVisible = false;
    @Input() contentStylesFixedForEditor?: string;
    @Input() viewPortTopOffset = 0; // e.g. height of top bar that overflows content
    @Input() trackImages = false;
    @Input() bottomToolbarEl?: HTMLElement;
    @Input() editorVersion?: string;

    @Output() contentChanged: EventEmitter<string>;
    @Output() contentLoaded: EventEmitter<void>;
    @Output() editorClicked: EventEmitter<void>;
    @Output() editorLeft: EventEmitter<void>;


    public isInitializing: boolean;

    public onScroll: EventEmitter<void> = new EventEmitter<void>();

    private _editorExtensions?: Array<IEditorExtension>;
    private _elementRef: ElementRef;
    private readonly _elementID: string;
    private _htmlContent?: string;
    private _htmlContentCss?: string;
    private _htmlEditedContent?: string;
    private _showToolbars = false;
    private _isReadOnly = false;
    private _editorScript?: string;
    private _currentEditor: any;
    private _isToolbarVisible = false;
    private _myiaPluginsAdded = false;
    private _isToolbarFixed = false;
    private _isEditorInBackground = false;
    private _previewSubscription: any;
    private _isSettingHtmlContent = false;
    private _croppingServiceSubscription?: Subscription;
    private _parentScroll: any;
    private _tinyMce: any;

    constructor(@Inject(ElementRef) _elementRef: ElementRef, private _localizationService: LocalizationService, private _zone: NgZone, private _changeDetectorRef: ChangeDetectorRef, private _htmlPageImageService: HtmlPageImageService, private _cultureService: CultureService, private _toastManager: ToastManager, private _logger: Logger) {
        this._elementRef = _elementRef;
        this.isInitializing = true;

        const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
        const editorId = randLetter + Date.now();
        this._elementID = 'tinymce' + editorId;

        // events
        this.contentLoaded = new EventEmitter<void>();
        this.contentChanged = new EventEmitter<string>();
        this.editorClicked = new EventEmitter<void>();
        this.editorLeft = new EventEmitter<void>();

        this._cultureService.onChange.subscribe(() => {
            this.destroyEditor();
            this.initializeEditor();
        });
    }

    initializeEditor() {
        if (!this._tinyMce) {
            console.log('TinyMce was not loaded yet !!!');
            return;
        }
        const importStyles = this._htmlContentCss && this._htmlContentCss.indexOf('http') !== 0;
        let extensionEditorStyles = '';
        if (this._editorExtensions) {
            this._editorExtensions.forEach((ext) => {
                extensionEditorStyles += ext.getEditorStyles(this.localizeExtensionsResources.bind(this));
            });
        }
        this._tinyMce.baseURL = '/tinymce4';
        // Attach tinyMCE to textarea clone
        let creationParams = {
            mode: 'exact',
            theme: 'modern',
            content_css: importStyles ? '' : this._htmlContentCss,
            content_style: (this.contentStylesFixedForEditor || '') + extensionEditorStyles + fixedStyles + (importStyles ? this._htmlContentCss : ''),
            skin: 'myia', // default is lightgray
            language: this._cultureService.currentCulture,
            plugins: [
                'advlist autolink lists link charmap print anchor',
                'searchreplace visualblocks fullscreen',
                'insertdatetime media contextmenu',
                'table paste hr',
                'autoresize',
                'code',
                'noneditable',
                'textcolor colorpicker',
                'myiaImageTools',
                'myiaPreview',
                'myiaImage'
            ],
            menu: {
                // file: {title: 'File', items: 'newdocument'},
                edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | cropImage | selectall | searchreplace'},
                insert: {title: 'Insert', items: 'link myiaImage | hr | charmap anchor insertdatetime'},
                view: {title: 'View', items: 'visualblocks visualaid | code | myiaPreview fullscreen'},
                format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat'},
                table: {title: 'Table', items: 'inserttable tableprops deletetable | cell row column'},
            },
            toolbar: 'undo redo | styleselect | sizeselect fontsizeselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link myiaImage | cropImage',
            contextmenu: 'link myiaImage cropImage inserttable | cell row column deletetable',
            statusbar: false,
            verify_html: false, // do not remove empty tags
            readonly: this._isReadOnly,
            elements: this._elementID,
            forced_root_block: false,
            target_list: false, // disable target selection in 'insert link' dialog
            default_link_target: '_system',
            link_title: false, // disable link title field in 'insert link' dialog
            relative_urls: false,
            convert_urls: false,
            remove_script_host: false,
            anchor_top: false,
            anchor_bottom: false,
            autoresize_overflow_padding: 0,
            autoresize_bottom_margin: 0,
            autoresize_min_height: 200,
            paste_as_text: true,
            setup: this.tinyMCESetup.bind(this)
        };
        const result = this._tinyMce.init(creationParams);
        const that = this;
        result.then(() => {
            this._zone.run(() => {
                that.isInitializing = false;
                that._changeDetectorRef.markForCheck();
                setTimeout(() => {
                    that.contentLoaded.emit();
                });
            });
        });
        return result;
    }

    destroyEditor() {
        if (this._currentEditor) {
            try {
                removeAllListeners(this.getScrollContainer(), ['scroll', 'resize']);
                const editorElements = this._tinyMce.get(this._elementID);
                if (editorElements) {
                    // destroy cloned elements
                    editorElements.remove();
                }
                if (this._croppingServiceSubscription) {
                    this._croppingServiceSubscription.unsubscribe();
                }
                if (this._editorExtensions) {
                    this._editorExtensions.forEach((ext) => {
                        if (ext.destroy) {
                            ext.destroy();
                        }
                    });
                }

            } catch (e) {
                this._logger.error('destroyEditor failed.');
            }
        }
        removeAllListeners(this.getScrollContainer(), ['scroll', 'resize']);
    }

    removeImagePlaceholder() {
        this._currentEditor.dom.remove(imagePlaceholderId);
    }

    addMyiaPlugins() {
        if (!this._myiaPluginsAdded) {
            this._myiaPluginsAdded = true;

            const imgToolbarItems = ['myiaImage cropImage'];

            const that = this;
            let lastSelectedImgEl: any = null;
            // image plugin
            this._tinyMce.PluginManager.add('myiaImage', function (editor: any) {

                const isTargetNode = function (element: any) {
                    // that._logger.log('isTargetNode:' + (element.tagName === 'IMG'));
                    return element && element.tagName === 'IMG';
                };

                const myiaImageExecuted = (fileSelected: Promise<any>) => {
                    let selectedEl = editor.selection.getNode();
                    const isImageSelected = isTargetNode(selectedEl);
                    that.removeImagePlaceholder();
                    if (!isImageSelected) {
                        const imgId = imagePlaceholderId;
                        editor.execCommand('mceInsertContent', false, '<img id="' + imgId + '" style="width:100%"/>');
                        selectedEl = editor.dom.get(imgId);
                    }
                    const oldImageUrl = isImageSelected ? selectedEl.getAttribute('src') : null;
                    editor.selection.collapse();
                    editor.iframeElement.blur();
                    that._isEditorInBackground = true;
                    that.setToolbarsFixedMode(false);
                    that._logger.log('file open dialog opened...');
                    fileSelected.then((inputFile) => {
                        let imgId = editor.dom.getAttrib(selectedEl, 'id');
                        if (!imgId) {
                            imgId = 'img_' + getNewComponentId();
                            editor.dom.setAttrib(selectedEl, 'id', imgId);
                        }
                        that.updateImageSource.call(that, imgId, oldImageUrl, inputFile);
                    }, () => {
                        that._logger.log('File selection canceled.');
                        that.removeImagePlaceholder();
                    });
                };

                const insertFileInputElement = function (itemEl: any, control: any) {
                    let fileSelectedResolve: any, fileSelectedReject: any;
                    const el = itemEl.$el[0] as HTMLElement;
                    // insert input[type=file] to open upload dialog when user clicks on button
                    el.classList.add('myiaImageInputItem');
                    const inputBlock = parseHTML(`<div class="myiaImageInputWrapper">
                                             <input type="file" class="myiaImageInput"/>
                                           </div>`).body.firstElementChild as HTMLElement;
                    inputBlock.addEventListener('click', (e: Event) => {
                        e.stopPropagation();
                        // hide menu
                        if (control.parent) {
                            const prnt = control.parent();
                            if (prnt && prnt.hideAll) {
                                prnt.hideAll();
                                prnt.repaint();
                            }
                        }
                    });
                    inputBlock.style.width = `${el.offsetWidth}px`;
                    inputBlock.style.height = `${el.offsetHeight}px`;
                    const input = inputBlock.querySelector('.myiaImageInput') as HTMLInputElement;
                    input.addEventListener('change', () => {
                        const selectedFiles = input.files;
                        if (selectedFiles?.length) {
                            fileSelectedResolve(selectedFiles[0]);
                        } else {
                            fileSelectedReject();
                        }
                    });
                    el.appendChild(inputBlock);
                    inputBlock.addEventListener('click', () => {
                        const inputFileSelected = new Promise<any>((resolve, reject) => {
                            fileSelectedResolve = resolve;
                            fileSelectedReject = reject;
                        });
                        myiaImageExecuted(inputFileSelected);
                    });
                };

                editor.addButton('myiaImage', {
                    title: that.localizeExtensionsResources('Editor.AddEditImage'),
                    icon: 'image',
                    onPostRender: function (evt: any) {
                        insertFileInputElement(this, evt.control);
                    }
                });
                editor.addContextToolbar(
                    function (element: any) {
                        return isTargetNode(element);
                    },
                    imgToolbarItems.join(' ')
                );

                editor.addMenuItem('myiaImage', {
                    text: that.localizeExtensionsResources('Editor.AddEditImage'),
                    icon: 'image',
                    context: 'insert',
                    onPostRender: function (evt: any) {
                        insertFileInputElement(this, evt.control);
                    }
                });
            });


            // image crop plugin
            this._tinyMce.PluginManager.add('myiaImageTools', function (editor: any) {
                const cropImage = () => {
                    let selectedEl = editor.selection.getNode();
                    const oldImageUrl = selectedEl.getAttribute('src');
                    editor.selection.collapse();
                    editor.iframeElement.blur();
                    that._isEditorInBackground = true;
                    that.setToolbarsFixedMode(false);
                    that._croppingServiceSubscription = croppingService.cropImage(selectedEl, editor.iframeElement, true, null,
                        {
                            minContainerWidth: selectedEl.offsetWidth,
                            minContainerHeight: selectedEl.offsetHeight
                        })
                        .pipe(
                            finalize(() => {
                                selectedEl = that._currentEditor.dom.get(selectedEl.getAttribute('id'));
                                // show original image element hidden by cropper service
                                that._currentEditor.dom.setStyle(selectedEl, 'visibility', 'visible');
                                that.finishCommand(selectedEl);
                            })
                        )
                        .subscribe((croppedImgData) => {
                            if (croppedImgData) {
                                that.updateImageSource.call(that, selectedEl.getAttribute('id'), oldImageUrl, croppedImgData.data);
                            }
                        });
                };

                editor.addCommand('myiaCropImage', cropImage);

                const isTargetNode = function (element?: any) {
                    if (!element) {
                        element = editor.selection ? editor.selection.getNode() : null;
                    }
                    return element && element.tagName === 'IMG';
                };
                let cropButtons: Array<any> = [];
                editor.addButton('cropImage', {
                    title: that.localizeExtensionsResources('Editor.CropImage'),
                    icon: 'crop',
                    cmd: 'myiaCropImage',
                    onPostRender: function () {
                        cropButtons.push(this);
                    }
                });
                editor.addContextToolbar(
                    function (element: any) {
                        return isTargetNode(element);
                    },
                    imgToolbarItems.join(' ')
                );

                let cropMenuItems: Array<any> = [];
                editor.addMenuItem('cropImage', {
                    text: that.localizeExtensionsResources('Editor.CropImage'),
                    icon: 'crop',
                    cmd: 'myiaCropImage',
                    disabled: !isTargetNode(),
                    context: 'edit',
                    onPostRender: function () {
                        cropMenuItems.push(this);
                        this.disabled(!isTargetNode());
                    }
                });

                editor.on('blur', function (e: any) {
                    if (!that._isEditorInBackground) {
                        editor.save();
                        that.editorLeft.emit();
                    }
                });
                const repositionToolbar = () => {
                    if (lastSelectedImgEl && editor && editor.iframeElement) {
                        const element = lastSelectedImgEl;
                        const inlineToolbars = Array.from(document.body.querySelectorAll('.mce-tinymce-inline'));
                        const inlineToolbar = inlineToolbars.find((t: Element) => {
                            return !!t.querySelector('.myiaImageInputItem');
                        }) as HTMLElement;
                        const frameOffset = getElementPosition(editor.iframeElement);
                        const toolbarWidth = inlineToolbar.clientWidth;
                        const toolbarHeight = inlineToolbar.clientHeight;
                        try {
                            const lastSelectedNodePosition = lastSelectedImgEl.getBoundingClientRect();
                            let top = frameOffset.top + lastSelectedNodePosition.top + element.clientHeight / 2 - toolbarHeight / 2;
                            const editorPos = getElementOffset(editor.editorContainer);
                            const scrollContainer = that.getScrollContainer();
                            const scrollContainerBounds = scrollContainer.getBoundingClientRect();
                            const bottomBarHeight = that.bottomToolbarEl ? that.bottomToolbarEl.offsetHeight : 0;
                            if (lastSelectedNodePosition.top + frameOffset.top + editorPos.top + lastSelectedNodePosition.height / 2 + toolbarHeight / 2 > scrollContainerBounds.bottom - bottomBarHeight) {
                                top -= lastSelectedNodePosition.top + frameOffset.top + editorPos.top + lastSelectedNodePosition.height / 2 + toolbarHeight / 2 - (scrollContainerBounds.bottom - bottomBarHeight);
                            }
                            const toolbarsHeight = that.getFixedToolBarsHeight(editor.editorContainer);
                            if (lastSelectedNodePosition.top + frameOffset.top + editorPos.top + lastSelectedNodePosition.height / 2 - toolbarHeight / 2 < scrollContainerBounds.top + toolbarsHeight) {
                                top -= lastSelectedNodePosition.top + frameOffset.top + editorPos.top + lastSelectedNodePosition.height / 2 - toolbarHeight / 2 - (scrollContainerBounds.top + toolbarsHeight);
                            }
                            inlineToolbar.style.left = `${frameOffset.left + lastSelectedNodePosition.left + element.clientWidth / 2 - toolbarWidth / 2}px`;
                            inlineToolbar.style.top = `${top}px`;
                        } catch (e) {
                            that._logger.warn('IMG element already removed from DOM');
                        }
                    }
                };
                editor.on('NodeChange', function (e: any) {
                    const isCommandEnabled = isTargetNode(e.element);
                    cropButtons.forEach((cropButton) => {
                        cropButton.disabled(!isCommandEnabled);
                    });
                    cropMenuItems.forEach((cropMenuItem) => {
                        cropMenuItem.disabled(!isCommandEnabled);
                    });
                    if (isCommandEnabled) {
                        setTimeout(() => {
                            lastSelectedImgEl = e.element;
                            repositionToolbar();
                        }, 100);
                    }

                });

                // that.onScroll.subscribe(() => {
                //     repositionToolbar();
                // });

            });

            this._tinyMce.PluginManager.add('myiaPreview', function (editor: any) {
                const settings = editor.settings, sandbox = !that._tinyMce.Env.ie;

                editor.addCommand('myiaPreview', function (ui: any, v: any) {
                    const closeCallback = v ? v.onClose : null;
                    let win: any;
                    editor.on('CloseWindow', function (e: any) {
                        if (closeCallback && e.win === win) {
                            closeCallback();
                        }
                    });

                    win = editor.windowManager.open({
                        title: v && v.title ? v.title : 'Preview',
                        width: parseInt(editor.getParam('plugin_preview_width', '650'), 10),
                        height: parseInt(editor.getParam('plugin_preview_height', '500'), 10),
                        html: '<iframe src="javascript:\'\'" frameborder="0"' + (sandbox ? ' sandbox="allow-scripts"' : '') + '></iframe>',
                        buttons: {
                            text: 'Close',
                            onclick: function () {
                                win.close();
                            }
                        },
                        onPostRender: function () {
                            let previewHtml, headHtml = '';

                            headHtml += '<base href="' + editor.documentBaseURI.getURI() + '">';

                            that._tinyMce.each(editor.contentCSS, function (url: string) {
                                headHtml += '<link type="text/css" rel="stylesheet" href="' + editor.documentBaseURI.toAbsolute(url) + '">';
                            });

                            // changes start
                            if (typeof editor.settings.content_style !== 'undefined') {
                                headHtml += '<style>' + editor.settings.content_style + '</style>';
                            }

                            // changes end

                            let bodyId = settings.body_id || 'tinymce';
                            if (bodyId.indexOf('=') !== -1) {
                                bodyId = editor.getParam('body_id', '', 'hash');
                                bodyId = bodyId[editor.id] || bodyId;
                            }

                            let bodyClass = settings.body_class || '';
                            if (bodyClass.indexOf('=') !== -1) {
                                bodyClass = editor.getParam('body_class', '', 'hash');
                                bodyClass = bodyClass[editor.id] || '';
                            }

                            let preventClicksOnLinksScript = (
                                '<script>' +
                                'document.addEventListener && document.addEventListener("click", function(e) {' +
                                'for (var elm = e.target; elm; elm = elm.parentNode) {' +
                                'if (elm.nodeName === "A") {' +
                                'e.preventDefault();' +
                                '}' +
                                '}' +
                                '}, false);' +
                                '</script> '
                            );

                            const dirAttr = editor.settings.directionality ? ' dir="' + editor.settings.directionality + '"' : '';

                            previewHtml = (
                                '<!DOCTYPE html>' +
                                '<html>' +
                                '<head>' +
                                headHtml +
                                '</head>' +
                                '<body id="' + bodyId + '" class="mce-content-body ' + bodyClass + '"' + dirAttr + '>' +
                                editor.getContent() +
                                preventClicksOnLinksScript +
                                '</body>' +
                                '</html>'
                            );

                            if (!sandbox) {
                                // IE 6-11 doesn't support data uris on iframes
                                // so I guess they will have to be less secure since we can't sandbox on those
                                // TODO: Use sandbox if future versions of IE supports iframes with data: uris.
                                const doc = this.getEl('body').firstChild.contentWindow.document;
                                doc.open();
                                doc.write(previewHtml);
                                doc.close();
                            } else {
                                this.getEl('body').firstChild.src = 'data:text/html;charset=utf-8,' + encodeURIComponent(previewHtml);
                            }
                        }
                    });
                });

                editor.addButton('myiaPreview', {
                    title: 'Preview',
                    cmd: 'myiaPreview'
                });

                editor.addMenuItem('myiaPreview', {
                    text: 'Preview',
                    cmd: 'myiaPreview',
                    context: 'view'
                });
            });
        }
    }

    initializeLoadedEditor() {
        this.addMyiaPlugins();
        this.initializeEditor();
    }

    addToolbars() {
        if (this._editorExtensions) {
            try {
                this._editorExtensions.forEach((ext) => {
                    ext.register(this._currentEditor, this.localizeExtensionsResources.bind(this));
                });
            } catch (err) {
                if (err) {
                    this._logger.error(err.toString());
                }
                this._toastManager.error('Editor extension registration failed.');
            }
        }
    }

    localizeExtensionsResources(resourceKey: string) {
        return this._localizationService.translate(resourceKey);
    }

    ngAfterViewInit() {
        // Clone base textarea
        const baseTextArea = this._elementRef.nativeElement.querySelector('.wysiwyg-editor-block textarea');
        const clonedTextArea = baseTextArea.cloneNode(true);
        clonedTextArea.id = this._elementID;

        const editorBlock = this._elementRef.nativeElement.querySelector('.wysiwyg-editor-block');
        editorBlock.appendChild(clonedTextArea);

        const that = this;
        (async () => {
            // @ts-ignore
            const {default: tinyMce} = await import('tinymce4lib');
            that._tinyMce = tinyMce;
            (window as any)['tinymce4'] = tinyMce; // used by code injected by webpack wrapper plugin
            that.initializeLoadedEditor();
        })();
    }

    ngOnInit() {
        if (this.previewEvent) {
            this._previewSubscription = EmitterService.getEvent(this.previewEvent).subscribe((options: any) => {
                this.preview(options);
            });
        }
    }

    ngOnDestroy() {
        this.destroyEditor();
        // remove elements
        this._elementRef.nativeElement.querySelector('.wysiwyg-editor-block').remove();
        if (this._previewSubscription) {
            this._previewSubscription.unsubscribe();
            this._previewSubscription = null;
        }
    }

    triggerContentChanged(content?: string) {
        this._htmlEditedContent = content || this._tinyMce.get(this._elementID).getContent();
        this.contentChanged.emit(this._htmlEditedContent);
    }

    tinyMCESetup(ed: any) {
        this._currentEditor = ed;
        // set container for correct position calculation flow controls (menu/dropdowns/...)
        ed.editorManager.Env.container = this._elementRef.nativeElement;
        // set reference to this component for extensions
        ed.component = this;

        ed.on('keyup', this.tinyMCEOnKeyup.bind(this));
        ed.on('click', this.tinyMCEOnClick.bind(this));
        ed.on('init', this.tinyMCEOnInit.bind(this));
        ed.on('SaveContent', this.tinyMCEOnSaveContent.bind(this));

        ed.on('change', this.tinyMCEOnChange.bind(this));

        ed.on('keydown', this.tinyMCEOnKeyDown.bind(this));
        this.addToolbars();
    }

    tinyMCEOnInit() {
        // initially hide toolbar
        this.tinyMCESetToolbarVisibility(this._showToolbars);
        // set initial content
        this.setEditorContent(this._htmlEditedContent);
        if (!this._isReadOnly && this.toolbarAlwaysVisible) {
            addListener(this.getScrollContainer(), ['scroll', 'resize'], this.onEditorScroll.bind(this));
        }
        if (this._editorExtensions) {
            this._editorExtensions.forEach((ext) => {
                ext.initializeContent();
            });
        }

        // native editor 'Click event' is not fired when tinymce is in readonly mode
        const docBody = this._currentEditor.getDoc().body;
        docBody.addEventListener('click', () => {
            if (this._isReadOnly) {
                this.tinyMCEOnClick();
            }
        });
    }

    getScrollContainer() {
        if (!this._parentScroll) {
            this._parentScroll = getParentScroll(this._elementRef.nativeElement);
        }
        return this._parentScroll;
    }

    onEditorScroll(e: any) {
        this.repositionToolbar(e);
        // hide opened context menu on tooltip
        const menus = document.querySelectorAll<HTMLElement>('.mce-contextmenu, .mce-tooltip');
        menus.forEach(menu => {
            menu.style.display = 'none';
        });
        this.onScroll.emit();
    }

    repositionToolbar(e: any) {
        if (this._isEditorInBackground) {
            return;
        }
        try {
            const editorEl = this.getEditorEl();
            const edPos = editorEl.getBoundingClientRect();
            const scrollPos = this.getScrollContainer().getBoundingClientRect();
            const shouldBeFixed = editorEl.getBoundingClientRect().top < this.getScrollContainer().getBoundingClientRect().top + this.viewPortTopOffset;
            if (shouldBeFixed !== this._isToolbarFixed || (shouldBeFixed && e.type === 'resize')) {
                this.setToolbarsFixedMode(shouldBeFixed, editorEl);
            }
        } catch (err) {
            this._logger.warn('repositionToolbar failed: ' + err);
        }
    }

    getEditorEl(): HTMLElement {
        return this._currentEditor.contentAreaContainer.parentElement;
    }

    getFixedToolBarsHeight(editorEl: HTMLElement): number {
        if (this._isToolbarFixed) {
            const toolbarEls = editorEl.querySelectorAll<HTMLElement>('div.mce-toolbar-grp, div.mce-menubar');
            let toolbarsHeight = 0;
            toolbarEls.forEach(toolBarEl => {
                toolbarsHeight += toolBarEl.clientHeight;
            });
            return toolbarsHeight;
        }
        return 0;
    }

    setToolbarsFixedMode(isFixed: boolean, editorEl?: HTMLElement) {
        if (!editorEl) {
            editorEl = this.getEditorEl();
        }
        const toolbarEls = editorEl.querySelectorAll<HTMLElement>('div.mce-toolbar-grp, div.mce-menubar');
        const editAreaEl = editorEl.querySelector<Element>('div.mce-edit-area') as HTMLElement;
        this._isToolbarFixed = isFixed;
        if (isFixed) {
            let toolbarsHeight = 0;
            toolbarEls.forEach(toolBarEl => {
                toolbarsHeight += toolBarEl.clientHeight;
            });
            toolbarEls.forEach(toolBarEl => {
                toolBarEl.classList.add('isFixed');
                toolBarEl.style.width = `${editorEl!.clientWidth}px`;
            });
            editAreaEl.style.paddingTop = `${toolbarsHeight}px`;
        } else {
            editAreaEl.style.paddingTop = `0px`;
            toolbarEls.forEach(toolBarEl => {
                toolBarEl.classList.remove('isFixed');
                toolBarEl.style.width = 'auto';
            });
        }
    }

    tinyMCEOnClick() {
        this._zone.run(() => {
            this.editorClicked.emit();
            // notify all other components about click to editor iframe (e.g. required for popup closing)
            EmitterService.getEvent(IFRAME_CLICKED).emit();
        });
    }

    tinyMCEOnKeyup(e: any) {
        if (this._editorExtensions) {
            this._editorExtensions.forEach((ext) => {
                if (ext.onKeyUp) {
                    ext.onKeyUp(e, this._currentEditor);
                }
            });
        }
        this.triggerContentChanged();
    }

    tinyMCEOnSaveContent(e: any) {
        if (this._editorExtensions) {
            this._editorExtensions.forEach((ext) => {
                if (ext.onSaveContent) {
                    ext.onSaveContent(e);
                }
            });
        }
        this.triggerContentChanged(e.content);
    }

    tinyMCEOnChange() {
        if (!this._isSettingHtmlContent) {
            this.triggerContentChanged();
        }
    }

    tinyMCEOnKeyDown(e: any) {
        if (this._editorExtensions) {
            this._editorExtensions.forEach((ext) => {
                if (ext.onKeyDown) {
                    ext.onKeyDown(e, this._currentEditor);
                }
            });
        }
    }

    tinyMCESetToolbarVisibility(visible: boolean) {
        if (this._currentEditor && this._isToolbarVisible !== visible) {
            const toolBars = this.getEditorEl().querySelectorAll<HTMLElement>('div.mce-menubar, div.mce-toolbar-grp, div.mce-statusbar');
            toolBars.forEach(toolBar => {
                toolBar.style.display = visible ? 'block' : 'none';
            });
        }
    }

    resetContent() {
        this._isSettingHtmlContent = true;
        this._htmlEditedContent = this._htmlContent;
        this._changeDetectorRef.markForCheck();
        this.setEditorContent(this._htmlContent);
        this._isSettingHtmlContent = false;
    }

    get htmlContent() {
        return this._htmlContent;
    }

    @Input() set htmlContent(content: string | undefined) {
        if (this._htmlContent !== content) {
            this._htmlContent = content;
            this.resetContent();
        }
    }

    get contentCss() {
        return this._htmlContentCss;
    }

    @Input() set contentCss(contentCss: string | undefined) {
        if (this._htmlContentCss !== contentCss) {
            this._htmlContentCss = contentCss;
            if (this._currentEditor) {
                // recreate editor
                this.destroyEditor();
                this.initializeEditor();
            }
        }
    }

    get editorScript() {
        return this._editorScript;
    }

    @Input() set editorScript(editorScript: string | undefined) {
        if (this._editorScript !== editorScript) {
            this._editorScript = editorScript;
            (async () => {
                // @ts-ignore
                const {default: jquery} = await import('jquery');
                this._editorExtensions = this.getWysiwygEditorScriptFromString(editorScript, jquery);
                if (this._currentEditor) {
                    // recreate editor
                    this.destroyEditor();
                    this.initializeEditor();
                }
            })();
        }
    }

    @Input() set showToolbars(showToolbars: boolean) {
        this._showToolbars = showToolbars;
        this.tinyMCESetToolbarVisibility(this._showToolbars);
    }

    @Input() set readonly(readonly: boolean) {
        if (this._isReadOnly !== readonly) {
            this._isReadOnly = readonly;
            // revert _htmlEditedContent
            this._htmlEditedContent = this._htmlContent;
            if (this._currentEditor) {
                // recreate editor
                this.destroyEditor();
                this.initializeEditor();
            }
        }
    }

    uploadImages(): Observable<void> {
        // tinymce4 component do not uploads images, its handled by eventWelcomePage component
        return of(void 0);
    }

    private preview(options: any) {
        if (this._currentEditor) {
            this._currentEditor.execCommand('myiaPreview', false, options);
        }
    }

    private setEditorContent(content: string | undefined) {
        if (this._currentEditor) {
            this._currentEditor.setContent(content || '');
            if (!this._isReadOnly && this.trackImages) {
                // create image data to be able to track added/removed/changed images
                const images = parseHTML(this._currentEditor.getBody()).getElementsByTagName('img');
                this._htmlPageImageService.updateImgDataFromElements(Array.from(images));
            }
            try {
                this._currentEditor.execCommand('mceAutoResize');
            } catch (err) {
                this._logger.warn('mceAutoResize failed: ' + err);
            }
        }
    }

    private finishCommand(selectedElId: string) {
        this.setToolbarsFixedMode(this._isToolbarFixed);
        this._currentEditor.iframeElement.focus();
        // select with delay to avoid selection interference with other editor components
        setTimeout(() => {
            const selectedEl = this._currentEditor.dom.get(selectedElId);
            this._currentEditor.selection.select(selectedEl);
        }, 200);
        this._isEditorInBackground = false;
    }


    private updateImageSource(selectedImgId: string, oldImageUrl: string, inputFile: File | Blob) {
        // locally convert do dataUrl to display in page
        getFileDataUrl(inputFile).subscribe((imgUrl) => {
            const selectedEl = this._currentEditor.dom.get(selectedImgId);
            let imgId, origImgId;
            imgId = this._currentEditor.dom.getAttrib(selectedEl, 'id');
            if (imgId === imagePlaceholderId) {
                imgId = null; // create new id for inserted img
            }
            if (!imgId) {
                imgId = 'img_' + getNewComponentId();
                this._currentEditor.dom.setAttrib(selectedEl, 'id', imgId);
            } else {
                origImgId = imgId;
            }
            if (this.trackImages) {
                this._htmlPageImageService.updateImgWithFile(imgId, oldImageUrl, origImgId, inputFile);
            }
            this._currentEditor.dom.setAttrib(selectedEl, 'src', imgUrl);
            this.triggerContentChanged();
            this.finishCommand(imgId);
        });
    }

    private getWysiwygEditorScriptFromString(script: string | undefined, jQuery: any): any {
        if (script) {
            try {
                const parsedFunction = new Function(`return ${script}`)();
                // pass jquery param to the function
                return parsedFunction(jQuery);
            } catch (e) {
                this._logger.warn('Editor script parse error:' + e);
            }
        }
        return [];
    }
}


