import { Pipe, PipeTransform } from '@angular/core';
import { TimeZonesService } from '../services/timeZonesService';
import { LocalizationService } from '@myia/ngx-localization';
import { catchError, startWith } from 'rxjs/operators';
import { of } from 'rxjs';

@Pipe({
    name: 'timeZone',
    pure: false // required to update the value when the promise is resolved
})
export class TimeZonePipe implements PipeTransform {

    constructor(private _timeZonesService: TimeZonesService, private _localizationService: LocalizationService) {
    }

    transform(value: string): any {
        return this._timeZonesService.getTimeZoneTitle(value)
            .pipe(
                startWith(this._localizationService.translate('Loading')),
                catchError(() => {
                    return of('???');
                })
            );
    }

}
