import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { EmitterService, LAYOUT_RESIZED, Logger } from '@myia/ngx-core';

export interface ISideBarItem {
    title: string;
    icon: string;
    routerLink: Array<string>;
}

@Component({
    selector: 'side-bar',
    styleUrls: ['./sideBar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
      <button class="sideBarContainerToggle btnPlain" (click)="toggleSideBarMenu()"><svg-icon name="menu"></svg-icon></button>
      <div class="sidebarContainer" [ngClass]="{loading:isLoading, collapsed: isCollapsed, hidden: isHidden, showMenu: isMenuVisible}" detectBrowser>
          <button class="sideBarToggleBtn" (click)="toggleSideBarState()"><svg-icon name="arrow"></svg-icon></button>
          <div class="sideBarButtons">
             <ng-content></ng-content>
          </div>
      </div>
    `
})
export class SideBarComponent {
    @Input() isCollapsed = false;
    @Input() isHidden = false;
    @Input() isLoading = false;

    @Input() items?: Array<ISideBarItem>;
    @Output() collapseButtonClicked = new EventEmitter<void>();

    isMenuVisible = false;

    constructor(private _changeDetectorRef: ChangeDetectorRef, private _logger: Logger) {
    }

    trackItems(index: number, item: ISideBarItem): any {
        return item.routerLink;
    }

    toggleSideBarState() {
        this.collapseButtonClicked.emit();
        setTimeout(() => {
            EmitterService.getEvent(LAYOUT_RESIZED).emit();
        });
    }

    toggleSideBarMenu() {
        this.isMenuVisible = !this.isMenuVisible;
        this._changeDetectorRef.markForCheck();
    }

    @HostListener('window:resize')
    public windowOnResize() {
        if (this.isMenuVisible) {
            this.isMenuVisible = false;
            this._changeDetectorRef.markForCheck();
        }
    }

    @HostListener('document:click', ['$event'])
    public documentOnClick(event: any) {
        if (this.isMenuVisible) {
            let current = event.target;
            while (current.tagName !== 'BODY' && current.tagName !== 'HTML') {
                if (current.className && current.className.indexOf && (current.className.indexOf('sideBarContainerToggle') !== -1)) {
                    // element with class 'sideBarContainerToggle' clicked
                    return;
                }
                current = current.parentNode;
                if (!current) {
                    // dom is not child of body (probably already removed from DOM)
                    return;
                }
            }
            // clicked outside
            this._logger.log('Document clicked -> deselect all - wall messages');
            this.isMenuVisible = false;
            this._changeDetectorRef.markForCheck();
        }
    }
}

