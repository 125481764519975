import { createState, updateState } from '@myia/ngx-redux';
import { TABLE_DATA_SET_CULTURES, TABLE_DATA_SET_CURRENCIES, TABLE_DATA_SET_TIMEZONES } from './tableDataActions';
import { SET_APP_CULTURE } from './cultureActions';

export const tableDataReducerKey = 'tableData';

export interface ITableDataState {
    currencies: Array<any> | null;
    timeZones: Array<any> | null;
    countries: Array<any> | null;
    cultures: Array<any> | null;
}

const initialState = createState<ITableDataState>(
    {
        currencies: null,
        timeZones: null,
        countries: null,
        cultures: null,
    });

function tableDataReducer(state = initialState, action: any) {
    let newState;
    switch (action.type) {
        case SET_APP_CULTURE:
            // clear table data specific to culture -> load it again from server
            return initialState;
        case TABLE_DATA_SET_CURRENCIES:
            return updateState(state, (newState: ITableDataState) => {
                newState.currencies = action.currencies;
            });
        case TABLE_DATA_SET_TIMEZONES:
            return updateState(state, (newState: ITableDataState) => {
                newState.timeZones = action.timeZones;
            });
        case TABLE_DATA_SET_CULTURES:
            return updateState(state, (newState: ITableDataState) => {
                newState.cultures = action.cultures;
            });
        default:
            return state;
    }
}

export default {
    [tableDataReducerKey]: tableDataReducer
};
