import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formattedText'
})
export class FormattedTextPipe implements PipeTransform {

    transform(text: string | undefined | null): string | undefined | null {
        if (text && text.indexOf('|') !== -1) {
            const listParts = text.split('|');
            const partsHtml = listParts.map(p => `<li>${p}</li>`).join('');
            return `<ul>${partsHtml}</ul>`;
        }
        return text;
    }
}
