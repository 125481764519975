import { createPersistedState, IPersistedState, rehydrateReducerState, updateState } from '@myia/ngx-redux';
import { SET_EVENT_LIST_DISPLAY, SET_PAGE_TITLE } from './generalActions';
import { REHYDRATE } from 'redux-persist';

export const generalReducerKey = 'general';

export interface IGeneralState extends IPersistedState {
    _pageTitle: string | null;
    displayEventsAsList: boolean;
}

const initialState = createPersistedState<IGeneralState>(
    {
        _pageTitle: null,
        displayEventsAsList: false,
    });

function generalReducer(state = initialState, action: any) {
    switch (action.type) {
        case REHYDRATE:
            return rehydrateReducerState(generalReducerKey, state, action);
        case SET_EVENT_LIST_DISPLAY:
            return updateState(state, (newState: IGeneralState) => {
                newState.displayEventsAsList = action.asList;
            });
        case SET_PAGE_TITLE:
            return updateState(state, (newState: IGeneralState) => {
                newState._pageTitle = action.title;
            });
        default:
            return state;
    }
}

export default {
    [generalReducerKey]: generalReducer
};
