import { Injectable } from '@angular/core';
import { CultureService } from '@myia/ngx-localization';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICompleterItem } from '../entities/completerItem.interface';
import { GoogleMapsAPIService } from './googleMapsAPIService';
import { GoogleMapsCompleterServiceBase } from './googleMapsCompleterServiceBase';

@Injectable({providedIn: 'root'})
export class GoogleMapsAddressCompleterService extends GoogleMapsCompleterServiceBase {

    constructor(private _googleMapsAPIService: GoogleMapsAPIService, private _cultureService: CultureService) {
        super();
    }

    protected override searchData(term: string): Observable<Array<any>> {
        return this._googleMapsAPIService.autoCompleteAddress(term, this._cultureService.currentCulture)
            .pipe(
                catchError(() => {
                    // return empty array result
                    return of([]);
                })
            );
    }

    protected override transformSearchResultData(data: any): ICompleterItem[] {
        return data.map((address: any) => {
            return {
                title: address.title,
                originalObject: address.originalObject
            };
        });
    }
}
