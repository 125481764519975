import { Injectable } from '@angular/core';
import { LittleLoader } from '@myia/ngx-little-loader';
import { Observable, Observer } from 'rxjs';
import { Logger } from '@myia/ngx-core';

@Injectable({ providedIn: 'root' })
export class ScriptLoaderService {
    constructor(private _littleLoader: LittleLoader, private _logger: Logger) {}

    load(scriptUrl: string): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            this._littleLoader.load(scriptUrl, (err: any) => {
                if (err) {
                    this._logger.error('Script loading failed: ' + err);
                    observer.error(err);
                }
                observer.next(!err);
                observer.complete();
            });
        });
    }

}
