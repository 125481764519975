import { createState, updateState } from '@myia/ngx-redux';
import { USE_DIRECT_DEEP_LINKS } from './debugActions';

export const debugReducerKey = 'debug';

export interface IDebugState {
    _useDirectDeepLink: boolean;
}

const initialState = createState<IDebugState>(
    {
        _useDirectDeepLink: false,
    });

function debugReducer(state = initialState, action: any) {
    switch (action.type) {
        case USE_DIRECT_DEEP_LINKS:
            return updateState(state, (newState: IDebugState) => {
                newState._useDirectDeepLink = action.useDeepLinks;
            });
        default:
            return state;
    }
}

export default {
    [debugReducerKey]: debugReducer
};
