import {
    AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, inject, Input,
    NgZone, OnChanges, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    EmitterService, getElementScrollbarWidth, getNewComponentId, getParentScroll, IFRAME_CLICKED
} from '@myia/ngx-core';
import { takeUntil } from 'rxjs/operators';
import { OnDestroyDirective } from '@myia/ngx-core-ui';

export interface IDropDownValue<T = string> {
    label: string;
    value: T;
}

export function getDropDownValuesFromEnum<TEnum>(enumType: any, labelMapping?: any): Array<IDropDownValue<TEnum>> {
    return Object.keys(enumType).map(l => {
        return {label: labelMapping ? labelMapping(enumType[l]) : enumType[l], value: l} as IDropDownValue<TEnum>;
    });
}

@Component({
    selector: 'input-dropdown',
    styleUrls: ['./inputDropDown.component.scss'],
    hostDirectives: [
        OnDestroyDirective
    ],
    template: `
        <div dropdown #dropDown (click)="$event.preventDefault()" [(isOpen)]="isOpen"  *ngLet="items | itemByValue: value : valuePath as item"
             [ngClass]="{modified: isModified, readOnly: readonly, invalid: control?.invalid, dirty: control?.dirty, disabled: disabledPath && value !== null && item[disabledPath]}">
            <a href [id]="id" dropdownToggle [disabled]="disabled" [ngClass]="{empty: null === value && !nullValueTitle}">
                <svg-icon *ngIf="iconPath && item" name="{{item[iconPath]}}"></svg-icon>
                <span [innerHTML]="(item?.[itemTitlePath] ?? (nullValueTitle || '&nbsp;'))|trans|sanitizeHtml"></span>
            </a>
            <input #inputEl type="text" autocomplete="off" tabindex="-1"
                (keydown)="inputEvent($event)"
                (keyup)="inputEvent($event, true)"
                [disabled]="disabled"
                class="searchBox"
                *ngIf="editable"
                placeholder="{{label || ''}}">
            <label *ngIf="label">{{label}}</label>
            <ul *ngIf="!readonly" dropdownMenu class="dropdown-menu" [attr.aria-labelledby]="id" #itemsContainer>
                <li *ngIf="nullValueTitle" (click)="itemClicked($event, null)">
                    <a class="dropdown-item" href="#" [ngClass]="{selected: null === value, active: null === activeItem}"><span>{{nullValueTitle}}</span></a>
                </li>
                <li *ngFor="let choice of items; trackBy: trackItem" class="dropdown-item-wrapper" (click)="itemClicked($event, choice)" [ngClass]="{disabled: disabledPath && choice[disabledPath]}">
                    <a class="dropdown-item" href="#" [ngClass]="{selected: choice === item, active: choice === activeItem}">
                        <svg-icon *ngIf="iconPath" name="{{choice[iconPath]}}"></svg-icon>
                        <span [innerHTML]="choice[itemTitlePath]|toString|trans|sanitizeHtml"></span></a>
                </li>
            </ul>
        </div>
    `
})
export class InputDropDownComponent<TItem extends Object, TValue> implements OnInit, OnDestroy, OnChanges, AfterViewInit, AfterViewChecked {
    @Input() label?: string;
    @Input() items?: ReadonlyArray<TItem> | null;
    @Output() valueChange = new EventEmitter<TValue>();
    @Input() disabled = false;
    @Input() editable = false;
    @Input() validator: any;
    @Input() readonly = false;
    @Input() isRequired = false;
    @Input() isModified = false;
    @Input() itemTitlePath = 'label' as keyof TItem;
    @Input() valuePath?: keyof TItem;
    @Input() disabledPath?: keyof TItem;
    @Input() nullValueTitle?: string;
    @Input() iconPath?: keyof TItem;
    @Input() formGroupRef?: FormGroup;
    @Input() fieldName?: string | number;

    @HostBinding('class') hostClasses?: string;

    @ViewChild('inputEl', {static: false}) _inputEl?: ElementRef;
    @ViewChild('itemsContainer', {static: false}) _itemsContainer?: ElementRef;
    @ViewChild('dropDown', {static: false}) _dropDown?: ElementRef;

    id: string;
    control?: FormControl;
    activeItem?: TItem;

    private _value: any;
    private _destroy$ = inject(OnDestroyDirective).destroy$;
    private _classNames?: string;
    private _isOpen = false;
    private _clearanceTimeout: any;
    private _parentScroll?: HTMLElement;
    private _controlName?: string;

    private _lastScrollPos?: number;
    private onParentScrollBind: EventListener = this.onParentScroll.bind(this);

    @Input() set value(val: TValue) {
        this._value = val;
        if (this.control) {
            this.control.setValue(this._value);
        }
    }

    get value(): TValue {
        return this._value;
    }

    @Input() set classNames(value: string) {
        this._classNames = value;
        this.updateHostClasses();
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    set isOpen(isOpen: boolean) {
        if (this._isOpen !== isOpen) {
            this._isOpen = isOpen;
            if (this._isOpen) {
                if (this._parentScroll) {
                    this._lastScrollPos = this._parentScroll.scrollTop; // remember scroll pos when drop-down opened to detect parent scroll caused by user
                }
                this.activeItem = this.itemByValue(this.value);
                if (this._inputEl) {
                    this._inputEl.nativeElement.focus();
                }
                if (this._itemsContainer) {
                    this._itemsContainer.nativeElement.style.opacity = 0.0;
                    this._itemsContainer.nativeElement.style.maxHeight = 'inherit';
                    requestAnimationFrame(() => {
                        this.updateDropdownListPosition();
                        this.ensureHighlightVisible();
                        if (this._itemsContainer) {
                            this._itemsContainer.nativeElement.style.opacity = 1.0;
                        }
                    });
                }
            }
        }
    }

    constructor(private _element: ElementRef, private _changeDetectorRef: ChangeDetectorRef, private _zone: NgZone) {
        this.id = getNewComponentId();
        EmitterService.getEvent(IFRAME_CLICKED).pipe(
            takeUntil(this._destroy$)
        ).subscribe(() => {
            if (this.isOpen) {
                this.closeDropDown();
            }
        });
    }

    ngOnInit() {
        this.updateHostClasses();
        const validators = this.validator ? [this.validator] : [];
        if (this.isRequired) {
            validators.push(Validators.required);
        }
        this.control = new FormControl({value: this._value, disabled: this.disabled}, Validators.compose(validators));
        if (this.formGroupRef) {
            this._controlName = this.fieldName?.toString() || getNewComponentId();
            this.formGroupRef.addControl(this._controlName, this.control);
        }

    }

    ngAfterViewInit() {
        this.checkParentScroll();
    }

    ngAfterViewChecked() {
        if (!this.readonly && !this._parentScroll) {
            this.checkParentScroll();
        }
    }

    ngOnDestroy() {
        if (this._parentScroll) {
            this._parentScroll.removeEventListener('scroll', this.onParentScrollBind);
        }

        if (this.formGroupRef && this._controlName) {
            this.formGroupRef.removeControl(this._controlName);
        }
    }

    ngOnChanges() {
        this.value = this._value;
    }

    onParentScroll(): void {
        // hide opened drop down when page is scrolled
        if (this.isOpen && this._parentScroll && this._lastScrollPos !== this._parentScroll.scrollTop) {
            this.closeDropDown();
        }
    }


    itemClicked(event: any, newValue: any) {
        event.preventDefault();
        event.stopPropagation();
        this.setValue(newValue);
    }

    public setValue(newValue: any) {
        if (newValue && this.valuePath) {
            newValue = newValue[this.valuePath];
        }
        if (this._value !== newValue) {
            this._value = newValue;
            if (this.control) {
                this.control.setValue(newValue);
            }
            this.valueChange.emit(newValue);
        }
    }

    public inputEvent(evt: any, isUpMode: boolean = false): void {
        // tab
        if (evt.keyCode === 9) {
            return;
        }
        if (isUpMode && (evt.keyCode === 37 || evt.keyCode === 39 || evt.keyCode === 38 ||
            evt.keyCode === 40 || evt.keyCode === 13)) {
            evt.preventDefault();
            return;
        }
        // backspace
        if (!isUpMode && evt.keyCode === 8) {
            if (this._value) {
                this.setValue(null);
            }
            evt.preventDefault();
        }
        // esc
        if (!isUpMode && evt.keyCode === 27) {
            this.closeDropDown();
            this._element.nativeElement.children[0].focus();
            evt.preventDefault();
            return;
        }
        // del
        if (!isUpMode && evt.keyCode === 46) {
            if (this._value) {
                this.setValue(null);
            }
            evt.preventDefault();
        }
        // left
        if (!isUpMode && evt.keyCode === 37 && this.items && this.items.length > 0) {
            this.first();
            evt.preventDefault();
            return;
        }
        // right
        if (!isUpMode && evt.keyCode === 39 && this.items && this.items.length > 0) {
            this.last();
            evt.preventDefault();
            return;
        }
        // up
        if (!isUpMode && evt.keyCode === 38) {
            this.prev();
            evt.preventDefault();
            return;
        }
        // down
        if (!isUpMode && evt.keyCode === 40) {
            this.next();
            evt.preventDefault();
            return;
        }
        // enter
        if (!isUpMode && evt.keyCode === 13) {
            this.setValue(this.activeItem);
            this.closeDropDown();

            evt.preventDefault();
            return;
        }
        let target = evt.target || evt.srcElement;
        if (target && target.value) {
            const inputValue = target.value;
            this.selectItem(inputValue);
            this.clearInputValueWithTimeout();
        }
    }

    trackItem(index: number, item: TItem): string {
        return item[this.valuePath ?? 'value' as keyof TItem] as string;
    }

    private itemByValue(val: TValue): TItem | undefined {
        return this.items ? this.items.find(i => (this.valuePath ? i[this.valuePath as keyof TItem] : i) === val) : undefined;
    }

    private selectItem(typedValue: string) {
        const txtToSearch = typedValue.toLowerCase();
        const filteredItems = this.items?.filter((item: any) => {
            return item.title.toLowerCase().indexOf(txtToSearch) === 0;
        });

        if (filteredItems?.length) {
            this.activeItem = filteredItems[0];
            this.ensureHighlightVisible();
        }
    }

    private first(): void {
        this.activeItem = this.items?.[0];
        this.ensureHighlightVisible();
    }

    private last(): void {
        this.activeItem = this.items?.[this.items.length - 1];
        this.ensureHighlightVisible();
    }

    private prev(): void {
        let index = this.activeItem && this.items?.indexOf(this.activeItem);
        this.activeItem = this.items && index !== undefined ? this.items[index - 1 < 0 ? this.items.length - 1 : index - 1] : undefined;
        this.ensureHighlightVisible();
    }

    private next(): void {
        let index = this.activeItem && this.items?.indexOf(this.activeItem);
        this.activeItem = this.items && index !== undefined ? this.items[index + 1 > this.items.length - 1 ? 0 : index + 1] : undefined;
        this.ensureHighlightVisible();
    }

    private updateDropdownListPosition() {
        if (this._itemsContainer && this._dropDown) {
            const dropDownContainer = this._itemsContainer.nativeElement;
            const dropDownBounds = this._dropDown.nativeElement.getBoundingClientRect();
            const dropDownListBounds = dropDownContainer.getBoundingClientRect();
            const viewBoundsEl = this._parentScroll || document.body;
            const parentBounds = viewBoundsEl.getBoundingClientRect();
            const maxRight = parentBounds.right - (this._parentScroll ? getElementScrollbarWidth(this._parentScroll) : 0);
            const margin = 5; // margin
            //console.log(`p: ${JSON.stringify(parentBounds)},\r\nd: ${JSON.stringify(dropDownListBounds)}`);
            dropDownContainer.style.maxWidth = `${maxRight - margin - dropDownListBounds.left}px`;
            // check vertical overflow
            if (dropDownBounds.bottom + dropDownListBounds.height > parentBounds.bottom && (dropDownBounds.top - parentBounds.top) > parentBounds.height / 2) {
                const maxHeight = dropDownBounds.top - parentBounds.top - 10;
                dropDownContainer.style.maxHeight = `${maxHeight}px`;
                const listHeight = Math.min(dropDownListBounds.height, maxHeight);
                // expand dropdown list to the top
                dropDownContainer.style.marginTop = `-${listHeight + dropDownBounds.height}px`;
            } else {
                dropDownContainer.style.marginTop = 'auto';
                dropDownContainer.style.maxHeight = `${parentBounds.bottom - 10 - dropDownBounds.bottom}px`;
            }
        }
    }

    private ensureHighlightVisible() {
        if (!this.activeItem) {
            return;
        }
        if (this._itemsContainer) {
            const container = this._itemsContainer.nativeElement;
            const choices = container.querySelectorAll('li');
            if (choices.length < 1) {
                return;
            }
            const activeIndex = this.items?.indexOf(this.activeItem);
            const highlighted: any = activeIndex !== undefined ? choices[activeIndex] : null;
            if (!highlighted) {
                return;
            }
            let posY: number = highlighted.offsetTop + highlighted.clientHeight - container.scrollTop;
            let height: number = container.offsetHeight;
            if (posY > height) {
                container.scrollTop += posY - height;
            } else if (posY < highlighted.clientHeight) {
                container.scrollTop -= highlighted.clientHeight - posY;
            }
        }
    }

    private updateHostClasses() {
        this.hostClasses = 'dropdown ' + (this._classNames || '');
    }

    private closeDropDown() {
        // close opened popup
        this.isOpen = false;
        this._changeDetectorRef.markForCheck();
    }

    private clearInputValueWithTimeout() {
        if (this._clearanceTimeout) {
            clearTimeout(this._clearanceTimeout);
        }
        this._clearanceTimeout = setTimeout(() => {
            if (this._inputEl) {
                this._inputEl.nativeElement.value = '';
            }
        }, 1000);
    }

    private checkParentScroll() {
        if (this._itemsContainer) {
            this._parentScroll = getParentScroll(this._itemsContainer.nativeElement.parentNode);
            if (this._parentScroll) {
                this._zone.runOutsideAngular(() => {
                    this._parentScroll?.addEventListener('scroll', this.onParentScrollBind);
                });
            }
        }
    }
}

