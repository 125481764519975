import { NEVER, Observable, Subject } from 'rxjs';
import { mergeMap, shareReplay, takeUntil } from 'rxjs/operators';


export abstract class CachedDataServiceBase<TResult> {

    private _cachedItems: any;
    private _destroy$!: Subject<void>;

    protected constructor() {
        this.initializeCache();
    }

    protected getCachedItem<TResult>(cacheKey: string, getNewItem: (...params: any[]) => Observable<TResult>): Observable<TResult> {
        if (!this._cachedItems[cacheKey]) {
            this._cachedItems[cacheKey] = getNewItem(cacheKey)
                .pipe(
                    takeUntil(this._destroy$),
                    // cache -> return same result for all subscriptions
                    shareReplay({refCount: true, bufferSize: 1}),
                );
            // create one subscription that will not complete to avoid cancel from Pipe component
            this._cachedItems[cacheKey]
                .pipe(
                    mergeMap(() => NEVER),
                ).subscribe();
        }
        return this._cachedItems[cacheKey];
    }

    protected initializeCache(cachedItems: { [key: string]: Observable<TResult> } = {}) {
        this.unSubscribeAll();
        this._cachedItems = cachedItems;
    }

    protected unSubscribeAll() {
        this._destroy$?.next();
        this._destroy$?.complete();
        this._destroy$ = new Subject<void>();
    }
}
