import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Logger } from '@myia/ngx-core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FuseService {
    constructor(private _logger: Logger) {}

    search(inputText: string, list: Array<any>): Observable<Array<string> | null> {
        return new Observable((observer: Observer<Array<string> | null>) => {
            (async () => {
                // @ts-ignore
                const {default: Fuse} = await import('fuse.js');
                const options = {
                    shouldSort: true,
                    threshold: 0.8,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1
                };
                const fuse = new Fuse(list, options); // "list" is the item array
                let result = fuse.search(inputText);
                if (result) {
                    observer.next(result.map(fuseResult => list[fuseResult.refIndex]));
                } else {
                    observer.next(null);
                }
                //that._logger.log(`Fuse results: ${inputText} -> ${JSON.stringify(result)}`);
                observer.complete();
            })();
        });
    }

    searchBestMatch(inputText: string, list: Array<any>): Observable<string | null> {
        return this.search(inputText, list).pipe(
            map(result => result && result.length ? result[0] : null)
        );
    }
}
