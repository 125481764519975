import {
    AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Injector, Renderer2
} from '@angular/core';
import { setElementClass } from '@myia/ngx-core';
import { ReduxStore, ReduxView } from '@myia/ngx-redux';
import { IStatusBarMessage } from '../entities/statusBarMessage.interface';
import { IStatusBarState, statusBarReducerKey } from '../redux/statusBarReducers';

@Component({
    selector: 'status-bar',
    styleUrls: ['./statusBar.component.scss'],
    template: `
                <div class="busyLine">
                    <progress-indicator-bar *ngIf="showBusyLine" indicatorClass="blockLoader fromLeft"></progress-indicator-bar>
                </div>
                <div class="messages">
                    <div class="msgCount" *ngIf="statusBarMessages && statusBarMessages.length > 1" (click)="toggle()">{{statusBarMessages.length}}</div>
                    <div class="msg" *ngFor="let message of statusBarMessages; trackBy:trackByMessage">{{message.messageText}}</div>
                </div>
              `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusBarComponent extends ReduxView implements AfterViewInit {
    @HostBinding('class') hostClasses = 'statusBar';

    public statusBarMessages?: ReadonlyArray<IStatusBarMessage>;
    public showBusyLine = false;

    private _isExpanded = false;
    private _isInitialized = false;
    private _waitingMsgs: any = {};

    constructor(injector: Injector, private _store: ReduxStore, private _renderer: Renderer2, private _elementRef: ElementRef) {
        super(injector);
        this.attachToStore(_store, [statusBarReducerKey]);
    }

    ngAfterViewInit() {
        // activate status bar animation to avoid animating of initial 'hidden' state
        if (this.showStatusBar()) {
            this._renderer.addClass(this._elementRef.nativeElement, 'ready');
        }
        this._isInitialized = true;
        this.updateView();
    }

    override mapStateToProps(state: any) {
        super.mapStateToProps(state);
        const statusBarState = state[statusBarReducerKey] as IStatusBarState;
        this.checkMessages(statusBarState);
        this.showBusyLine = this.getPropertyFromState(this.showBusyLine, statusBarState.showBusyLine);
        return this.propertiesUpdated;
    }

    checkMessages(statusBarState: IStatusBarState) {
        const now = new Date();
        const msgsToShow = statusBarState.messages.filter(m => !m.timeToShow || (m.timeToShow <= now));
        const newWaitingMsgs = statusBarState.messages.filter(m => m.timeToShow && (m.timeToShow > now) && !this._waitingMsgs[m.id]);
        newWaitingMsgs.forEach(m => {
            const timeToShow = m.timeToShow!.getTime() - now.getTime();
            this._waitingMsgs[m.id] = setTimeout(() => {
                this.checkMessages(this._store.getState(statusBarReducerKey));
                delete this._waitingMsgs[m.id];
                this.updateView();
            }, timeToShow);
        });
        this.statusBarMessages = this.getPropertyFromState(this.statusBarMessages, msgsToShow);
    }

    override updateView() {
        super.updateView();
        setElementClass(this._renderer, this._elementRef.nativeElement, 'ready', this._isInitialized);
        setElementClass(this._renderer, this._elementRef.nativeElement, 'show', this.showStatusBar());
        setElementClass(this._renderer, this._elementRef.nativeElement, 'expanded', this._isExpanded);
        setElementClass(this._renderer, this._elementRef.nativeElement, 'withMessages', Boolean(this.statusBarMessages && !!this.statusBarMessages.length));
        setElementClass(this._renderer, this._elementRef.nativeElement, 'withBusyLine', this.showBusyLine);
        super.updateView();
    }

    trackByMessage(index: number, message: IStatusBarMessage): any {
        return message.messageKey;
    }

    toggle() {
        this._isExpanded = !this._isExpanded;
        this.updateView();
    }

    private showStatusBar(): boolean {
        return Boolean(this.showBusyLine || (this.statusBarMessages && !!this.statusBarMessages.length));
    }
}
