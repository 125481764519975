import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreUIModule } from '@myia/ngx-core-ui';
import { HttpModule } from '@myia/ngx-http';
import { LocalizationModule } from '@myia/ngx-localization';
import { ReduxFeatureModule, ReduxModule, ReduxStore } from '@myia/ngx-redux';
import { ToastModule } from '@myia/ngx-toast';
import { TooltipModule } from '@myia/ngx-tooltip';
import { AdminToolsComponent } from './components/adminTools';
import { ButtonMenuComponent } from './components/buttonMenu';
import { CalendarIconComponent } from './components/calendarIcon';
import { ClipboardDirective } from './components/clipboard';
import { ContextMenuDirective } from './components/context-menu/contextMenuDirective';
import { ContextMenuHolderComponent } from './components/context-menu/contextMenuHolder';
import { ContextMenuItemsFilterPipe } from './components/context-menu/contextMenuItemsFilterPipe';
import { ControlMessagesComponent } from './components/controlMessages';
import { DevToolsComponent } from './components/devTools';
import { EmptyComponent } from './components/emptyComponent';
import { ExpandCollapseComponent } from './components/expandCollapse';
import { FilterPipe } from './components/filterPipe';
import { FnPipe } from './components/fnPipe';
import { FormattedTextPipe } from './components/formattedTextPipe';
import { HelpIconComponent } from './components/helpIcon';
import { HelpPositionPipe } from './components/helpPositionPipe';
import { InputCheckboxComponent } from './components/inputCheckbox';
import { InputDateFieldComponent } from './components/inputDateField';
import { InputDropDownComponent } from './components/inputDropDown';
import { InputFileComponent } from './components/inputFile';
import { InputLanguageFieldComponent } from './components/inputLanguageField';
import { InputMultiSelectionComponent } from './components/inputMultiSelection';
import { InputMultiSelectionItemPipe } from './components/inputMultiSelectionItemPipe';
import { InputRadioComponent } from './components/inputRadio';
import { InputTextFieldComponent } from './components/inputTextField';
import { InputTextFieldHasValuePipe } from './components/inputTextFieldHasValuePipe';
import { DROPDOWN_DIRECTIVES } from './components/ng2-bootstrap/dropdown';
import { SafeUrlPipe } from './components/safeUrlPipe';
import { SideBarComponent } from './components/sideBar';
import { SideBarItemComponent } from './components/sideBarItem';
import { StatusBarComponent } from './components/statusBar';
import { TimeZonePipe } from './components/timeZonePipe';
import { TopBarComponent } from './components/topBar';
import { ValidationErrorsPipe } from './components/validationErrorsPipe';
import { WYSIWYGEditorComponent } from './components/wysiwygEditor';
import { WYSIWYGEditor4Component } from './components/wysiwygEditor4';
import { WYSIWYGEditor5Component } from './components/wysiwygEditor5';
import { AutoScrollContentDirective } from './directives/autoScrollContentDirective';
import { AutoSizeDirective } from './directives/autoSizeDirective';
import { DropEffectDirective } from './directives/dropEffectDirective';
import { FileDropZoneDirective } from './directives/inputFileDropZone';
import { MenuToggleDirective } from './directives/menuToggleDirective';
import { MutationObserverDirective } from './directives/mutationObserverDirective';
import { ScrollControlDirective } from './directives/scrollControlDirective';
import { StickyDirective } from './directives/stickyDirective';
import { VerticalTextDirective } from './directives/verticalTextDirective';
import adminReducers from './redux/adminReducers';
import { authReducerKey, authReducers } from './redux/authReducers';
import countryReducers from './redux/countryReducers';
import debugReducers from './redux/debugReducers';
import generalReducers, { generalReducerKey } from './redux/generalReducers';
import statusBarReducers from './redux/statusBarReducers';
import tableDataReducers from './redux/tableDataReducers';
import { CodeEditorDirective } from './components/codeEditor';
import { cultureReducerKey, cultureReducers } from './redux/cultureReducers';
import { ItemByValuePipe } from './components/itemByValuePipe';
import { ToStringPipe } from './components/toStringPipe';
import { EnumKeyPipe } from './components/enumKeyPipe';
import { AutoTextSizeDirective } from './directives/autoTextSizeDirective';
import { WYSIWYGEditor6Component } from './components/wysiwygEditor6';


/* Redux configuration - must be provided as factory to be AOT compatible */
export function provideReducers() {
    return [adminReducers, authReducers, countryReducers, cultureReducers, debugReducers, generalReducers, statusBarReducers, tableDataReducers];
}

export function providePersistedReducersNames() {
    return [authReducerKey, generalReducerKey, cultureReducerKey];
}

let done = false;

@NgModule({
    imports: [
        CommonModule,
        CoreUIModule,
        FormsModule,
        HttpModule,
        LocalizationModule,
        ReactiveFormsModule,
        ReduxModule.forChild(provideReducers, providePersistedReducersNames),
        RouterModule,
        ToastModule,
        TooltipModule,
    ],
    declarations: [
        AdminToolsComponent,
        AutoScrollContentDirective,
        AutoSizeDirective,
        AutoTextSizeDirective,
        ButtonMenuComponent,
        CalendarIconComponent,
        ClipboardDirective,
        CodeEditorDirective,
        ContextMenuItemsFilterPipe,
        ContextMenuDirective,
        ContextMenuHolderComponent,
        ControlMessagesComponent,
        DevToolsComponent,
        DropEffectDirective,
        ExpandCollapseComponent,
        EmptyComponent,
        EnumKeyPipe,
        FileDropZoneDirective,
        FilterPipe,
        FnPipe,
        FormattedTextPipe,
        HelpIconComponent,
        HelpPositionPipe,
        ItemByValuePipe,
        InputCheckboxComponent,
        InputDropDownComponent,
        InputDateFieldComponent,
        InputFileComponent,
        InputLanguageFieldComponent,
        InputMultiSelectionComponent,
        InputMultiSelectionItemPipe,
        InputRadioComponent,
        InputTextFieldComponent,
        InputTextFieldHasValuePipe,
        MenuToggleDirective,
        MutationObserverDirective,
        SafeUrlPipe,
        ScrollControlDirective,
        SideBarComponent,
        SideBarItemComponent,
        StatusBarComponent,
        StickyDirective,
        TimeZonePipe,
        TopBarComponent,
        ToStringPipe,
        ValidationErrorsPipe,
        VerticalTextDirective,
        WYSIWYGEditorComponent,
        WYSIWYGEditor4Component,
        WYSIWYGEditor5Component,
        WYSIWYGEditor6Component,
        DROPDOWN_DIRECTIVES,
    ],
    exports: [
        AutoScrollContentDirective,
        AutoSizeDirective,
        AutoTextSizeDirective,
        AdminToolsComponent,
        ButtonMenuComponent,
        CalendarIconComponent,
        ClipboardDirective,
        CodeEditorDirective,
        ContextMenuDirective,
        ContextMenuHolderComponent,
        ControlMessagesComponent,
        DevToolsComponent,
        DropEffectDirective,
        EmptyComponent,
        ExpandCollapseComponent,
        EnumKeyPipe,
        FileDropZoneDirective,
        FilterPipe,
        FnPipe,
        FormattedTextPipe,
        HelpIconComponent,
        HelpPositionPipe,
        InputCheckboxComponent,
        InputDropDownComponent,
        InputDateFieldComponent,
        InputFileComponent,
        InputLanguageFieldComponent,
        InputMultiSelectionComponent,
        InputRadioComponent,
        InputTextFieldComponent,
        InputTextFieldHasValuePipe,
        ItemByValuePipe,
        MenuToggleDirective,
        SafeUrlPipe,
        ScrollControlDirective,
        SideBarComponent,
        SideBarItemComponent,
        StatusBarComponent,
        StickyDirective,
        TimeZonePipe,
        TopBarComponent,
        ToStringPipe,
        ValidationErrorsPipe,
        VerticalTextDirective,
        WYSIWYGEditorComponent,
        WYSIWYGEditorComponent,
        WYSIWYGEditor5Component,
        WYSIWYGEditor6Component,
        DROPDOWN_DIRECTIVES,
        MutationObserverDirective,
    ],
    providers: [
        // all services are singletons with @Injectable({ providedIn: 'root' })
    ]
})
export class ViewSharedComponentsModule extends ReduxFeatureModule {
    constructor(reduxStore: ReduxStore, featureInjector: Injector) {
        super(done ? null : reduxStore, featureInjector);
        done = true;
    }
}

