import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BackendService } from '@myia/ngx-http';
import { Logger } from '@myia/ngx-core';

export interface IAutoCompletedPlace {
    formattedAddress: string;
    street: string;
    city: string;
    postalCode: string;
    countryCode: string;
    inputValue: string; // this property could be set by parent component to override value in input
    timeZone?: string;
}

export interface ICity {
    title: string;
    placeId: string;
    originalObject: IAutoCompletedPlace;
}

@Injectable({ providedIn: 'root' })
export class GoogleMapsAPIService {

    private static isAddressComponentType(addressComponent: any, itemType: string): boolean {
        return addressComponent.types.indexOf(itemType) !== -1;
    }

    constructor(private _backendService: BackendService, private _logger: Logger) {
    }

    autoCompleteCity(searchTxt: string, language: string): Observable<Array<ICity>> {
        // get auto complete suggestions for cities
        const apiUrl = `/api/services/google/autocomplete?input=${encodeURIComponent(searchTxt)}&language=${language}&types=${encodeURIComponent('(cities)')}`;
        return this._backendService.get<any>(apiUrl)
            .pipe(
                map(data => {
                    return data.predictions.map((p: any) => {
                        return {
                            title: p.description,
                            placeId: p.place_id,
                            originalObject: p
                        };
                    });
                }),
                catchError(err => {
                    this._logger.warn('Could not load places to auto-complete(Google API) : ' + err);
                    return throwError(err);
                })
            );
    }

    autoCompleteAddress(searchTxt: string, language: string): Observable<Array<ICity>> {
        // get auto complete suggestions for street
        const apiUrl = `/api/services/google/autocomplete?input=${encodeURIComponent(searchTxt)}&language=${language}&types=${encodeURIComponent('address')}`;
        return this._backendService.get<any>(apiUrl)
            .pipe(
                map(data => {
                    return data.predictions.map((p: any) => {
                        return {
                            title: p.description,
                            placeId: p.place_id,
                            originalObject: p
                        };
                    });
                }),
                catchError(err => {
                    this._logger.warn('Could not load street to auto-complete(Google API) : ' + err);
                    return throwError(err);
                })
            );
    }

    timeZoneByPlace(placeId: string, placeStr: string, language: string, addressComponents?: Array<string>): Observable<any> {
        // get time zone for location from Google
        let apiUrl = `/api/services/google/timezone/place?place=${encodeURIComponent(placeStr)}&placeId=${encodeURIComponent(placeId)}&language=${language}`;
        if (addressComponents) {
            apiUrl += `&addressComponents=${addressComponents.join('|')}`;
        }
        return this._backendService.get<any>(apiUrl)
            .pipe(
                map(data => {
                    return {
                        address: data.address,
                        zone: data.zone
                    };
                }),
                catchError(err => {
                    this._logger.error('Could not load time zone for place (Google API) : ' + err);
                    return throwError(err);
                })
            );
    }

    addressByPlace(placeId: string, language: string) {
        // get time zone for location from Google
        let apiUrl = `/api/services/google/address?placeId=${encodeURIComponent(placeId)}&language=${language}`;
        return this._backendService.get(apiUrl)
            .pipe(
                map(data => this.parseAddress(data)),
                catchError(err => {
                    this._logger.warn('Could not load address by placeId(Google API) : ' + err);
                    return throwError(err);
                })
            );
    }

    private parseAddress(originalObject: any) {
        const addressComponents = <Array<any>>originalObject['address_components'];
        if (!addressComponents) {
            return originalObject;
        }
        let street = '';
        let city = '';
        let subCity = '';
        let neighborhood = '';
        let countryCode = '';
        let postalCode='';
        let streetNumber='';

        // iterate through address_component array
        addressComponents.forEach(addressComponent => {
            if (GoogleMapsAPIService.isAddressComponentType(addressComponent, 'route')) {
                street = addressComponent.long_name;
            }

            if (GoogleMapsAPIService.isAddressComponentType(addressComponent, 'locality')) {
                city = addressComponent.long_name;
            }

            if (GoogleMapsAPIService.isAddressComponentType(addressComponent, 'neighborhood')) {
                neighborhood = addressComponent.long_name;
            }

            if (GoogleMapsAPIService.isAddressComponentType(addressComponent, 'sublocality')) {
                subCity = addressComponent.long_name;
            }

            if (GoogleMapsAPIService.isAddressComponentType(addressComponent, 'country')) {
                countryCode = addressComponent.short_name;
            }

            if (GoogleMapsAPIService.isAddressComponentType(addressComponent, 'postal_code')) {
                postalCode = addressComponent.long_name;
            }

            if (GoogleMapsAPIService.isAddressComponentType(addressComponent, 'street_number')) {
                streetNumber = addressComponent.long_name;
            }
        });
        return {
            formattedAddress: originalObject['formatted_address'] as string,
            street: street + (streetNumber ? ' ' + streetNumber : '') + (neighborhood ? ', ' + neighborhood : '') + (subCity ? ', ' + subCity : ''),
            city: city,
            postalCode: postalCode,
            countryCode: countryCode,
            timeZone: null,
            inputValue: null // this property could be set by parent component to override value in input
        };
    }


}

