import { Directive, ElementRef, EventEmitter, Input, Output, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { NgResizeObserver, ngResizeObserverProvidersWithPonyfill } from 'ng-resize-observer';
import type { Ace } from 'ace-builds';


@Directive({
    selector: '[codeEditor]',
    providers: [...ngResizeObserverProvidersWithPonyfill]
})
export class CodeEditorDirective implements OnInit, OnDestroy {
    @Output() textChange = new EventEmitter<string | null | undefined>();
    @Output() editorBlur = new EventEmitter<void>();
    @Input() statusBar?: string;
    @Input() beautifyButton?: string;

    editor?: Ace.Editor;
    private _currentVal?: string;
    private _readOnly = false;
    private _mode?: string;
    private _origVal?: string | null;
    private _options: any;
    private _isSettingValue = false;
    private _jsBeautify: any;
    private _beautifyEl?: HTMLElement | null;
    private readonly _beautifyCodeFn: any;

    @Input()
    set options(value: any) {
        this._options = value;
        if (this.editor) {
            this.editor.setOptions(value || {});
        }
    }

    @HostBinding('class.readonly')
    @Input()
    get readOnly(): boolean {
        return this._readOnly;
    }

    set readOnly(value: boolean) {
        this._readOnly = value;
        if (this.editor) {
            this.editor.setReadOnly(value);
        }
    }

    @Input()
    set mode(value: string | undefined) {
        this._mode = value;
        if (this.editor && value) {
            this.editor.getSession().setMode(`ace/mode/${value}`);
        }
    }

    @Input()
    set text(value: string | null | undefined) {
        const newValue = value || '';
        if (this._origVal !== newValue) {
            this._origVal = newValue;
            if (this.editor) {
                this._isSettingValue = true;
                this.editor.setValue(this._origVal);
                this._isSettingValue = false;
                this.editor.clearSelection();
                this.editor.focus();
            }
        }
    }

    constructor(private _elementRef: ElementRef, private resize$: NgResizeObserver) {
        this._beautifyCodeFn = this.beautifyCode.bind(this);
        const el = this._elementRef.nativeElement;
        el.classList.add('editor');
        this.resize$.subscribe(
            () => {
                if (this.editor) {
                    this.editor.resize();
                    this.editor.renderer.updateFull();
                }
            });

    }

    ngOnInit() {
        (async () => {
            const [ace, statusBar, langTools, jsBeautify] = await Promise.all([import('ace-builds'), import('ace-builds/src-noconflict/ext-statusbar'), import('ace-builds/src-noconflict/ext-language_tools'), import('js-beautify')]);
            this._jsBeautify = (jsBeautify as any).default;
            ace.config.set('basePath', '/ace-builds');
            ace.require('ace/ext/language_tools');
            const el = this._elementRef.nativeElement;
            this.editor = ace.edit(el);
            this.editor.setTheme('ace/theme/clouds');

            // enable line wrapping
            this.editor.getSession().setUseWrapMode(true);

            // enable autocompletion and snippets
            this.editor.setOptions({
                enableBasicAutocompletion: true,
                enableSnippets: false,
                enableLiveAutocompletion: true
            });

            this.editor.on('change', () => {
                const newVal = this.editor?.getValue();
                if (newVal === this._currentVal) {
                    return;
                }
                if (!this._isSettingValue) {
                    if (typeof this._currentVal !== 'undefined') {
                        this._origVal = newVal;
                        this.textChange.emit(newVal);
                    }
                }
                this._currentVal = newVal;
            });
            this.editor.on('blur', () => {
                this.editorBlur.emit();
            });

            if (this.statusBar && this.editor) {
                const StatusBar = ace.require('ace/ext/statusbar').StatusBar;
                const statusBarEl = document.querySelector(this.statusBar);
                const _ = new StatusBar(this.editor, statusBarEl);
            }
            if (this.beautifyButton) {
                this._beautifyEl = document.querySelector(this.beautifyButton);
                if (this._beautifyEl) {
                    this._beautifyEl.addEventListener('click', this._beautifyCodeFn);
                }
            }

            // add command to lazy-load keybinding_menu extension
            this.editor.commands.addCommand({
                name: 'beautifyCode',
                bindKey: {win: 'Alt-F8', mac: 'Alt-F8'},
                exec: _ => {
                    this.beautifyCode();
                }
            });

            setTimeout(() => {
                // set all editor properties
                const txt = this._origVal;
                this._origVal = null;
                this.text = txt;
                this.options = this._options;
                this.readOnly = this._readOnly;
                this.mode = this._mode;
            }, 0);
        })();
    }

    ngOnDestroy() {
        if (this._beautifyEl) {
            this._beautifyEl.removeEventListener('click', this._beautifyCodeFn);
            this._beautifyEl = null;
        }
        if (this.editor) {
            this.editor.destroy();
            this.editor = undefined;
        }
    }

    beautifyCode() {
        this.text = this.beautify(this._origVal);
        this.textChange.emit(this._origVal);
    }

    private beautify(code: string | null | undefined): string {
        return code ? this._jsBeautify.html(code, {
            'indent_size': 4,
            'html': {
                'end_with_newline': true,
                'js': {
                    'indent_size': 2
                },
                'css': {
                    'indent_size': 2
                }
            },
            'css': {
                'indent_size': 1
            },
            'js': {
                'preserve-newlines': true
            }
        }) : code;
    }
}
