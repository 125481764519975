import { Directive, HostListener, Input } from '@angular/core';
import { ContextMenuService } from './contextMenuService';
import { IContextMenuItem } from './contextMenuHolder';

@Directive({
    selector: '[contextMenu]'
})
export class ContextMenuDirective {
    @Input() menuItems?: Array<IContextMenuItem>;
    @Input() contextMenuDisabled = false;

    private _buttons?: Set<string>;
    private _buttonsDef?: string;

    get buttons() {
        return this._buttonsDef;
    }

    @Input() set buttons(buttonsDef: string | undefined) { // set of buttons separated by '|'
        this._buttonsDef = buttonsDef;
        this._buttons = buttonsDef ? new Set<string>(<Array<string>>buttonsDef.split('|')) : undefined;
    }

    constructor(private _contextMenuService: ContextMenuService) {
    }

    @HostListener('click', ['$event'])
    clicked(event: MouseEvent) {
        if (this.contextMenuDisabled) {
            return;
        }
        if (this._buttons && this._buttons.has('left')) {
            // open in next phase to avoid immediate closing by same click to the document
            setTimeout(() => {
                this._contextMenuService.show.next({event: event, obj: this.menuItems!});
            }, 0);

        }
    }

    @HostListener('contextmenu', ['$event'])
    rightClicked(event: MouseEvent) {
        if (this.contextMenuDisabled) {
            return;
        }
        if (!this._buttons || this._buttons.has('right')) {
            // open in next phase to avoid immediate closing by same click to the document
            setTimeout(() => {
                this._contextMenuService.show.next({event: event, obj: this.menuItems!});
            }, 0);
            event.preventDefault(); // hide default context menu
        }
    }
}
