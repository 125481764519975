import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { NgChanges } from '@myia/ngx-core';

@Directive({
    selector: '[mutation]'
})
export class MutationObserverDirective
    implements AfterViewInit, OnChanges, OnDestroy {
    @Input() mutationConfig?: MutationObserverInit;
    @Input() mutationSelector?: String;
    @Output() mutation = new EventEmitter<MutationRecord[]>();
    private observing = false;
    private observer?: MutationObserver;

    constructor(private readonly elementRef: ElementRef) {
    }

    ngAfterViewInit() {
        this.observe();
    }

    ngOnChanges(changes: NgChanges<MutationObserverDirective>) {
        if (this.observing && (changes.mutationConfig || changes.mutationSelector)) {
            this.unobserve();
            this.observe();
        }
    }

    ngOnDestroy() {
        this.unobserve();
    }

    private observe() {
        if (!this.observing) {
            let config = this.mutationConfig;

            if (!config || typeof config !== 'object') {
                config = {
                    attributes: true,
                    characterData: true,
                    childList: true
                };
            }

            this.observer = new MutationObserver(mutations => {
                this.mutation.emit(mutations);
            });
            const elToObserve = this.mutationSelector ? this.elementRef.nativeElement.querySelector(this.mutationSelector) : this.elementRef.nativeElement;
            if (elToObserve) {
                this.observer.observe(elToObserve, config);
                this.observing = true;
            }
        }
    }

    private unobserve() {
        if (this.observing) {
            this.observer?.disconnect();
            this.observing = false;
        }
    }
}
