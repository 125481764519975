export const AccessDeniedRoutePath = 'accessDenied';
export const AccountRoutePath = 'account';
export const ActivateRoutePath = 'activate';
export const DashboardRoutePath = 'dashboard';
export const GameRoutePath = 'game';
export const EventRoutePath = 'event';
export const LoginRoutePath = 'login';
export const LogoutRoutePath = 'logout';
export const ResetPasswordRoutePath = 'resetPassword';
export const SignUpRoutePath = 'signup';
