import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Logger } from '@myia/ngx-core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/authService';

export interface RouteRolesData {
    roles: Array<string>;
}

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private _authService: AuthService, private _logger: Logger) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const result = this._authService.isAuth();
        result.subscribe((isAuth: boolean) => {
            if (isAuth) {
                const routeRolesData = <RouteRolesData>next.data;
                // check authorization
                if (routeRolesData && routeRolesData.roles) {
                    const hasRoles = routeRolesData.roles.some(role => {
                        return this._authService.currentTokenHasRole(role);
                    });
                    if (!hasRoles) {
                        this._authService.accessDenied();
                    }
                }
            }
            else {
                this._logger.info('User is not authenticated -> redirect to login...');
                this._authService.login();
            }
        });
        return result;
    }
}
