import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { getFormControlErrors } from '@myia/ngx-core';
import { LocalizationService } from '@myia/ngx-localization';
import { ValidationService } from '../services/validationService';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

export interface IValidationErrorsOptions {
    html: boolean; // return string containing formatted html
}

@Pipe({
    name: 'validationErrors',
    pure: true
})
export class ValidationErrorsPipe implements PipeTransform {

    constructor(private _validationService: ValidationService, private _localizationService: LocalizationService) {
    }

    transform(control: AbstractControl | undefined | null, options?: IValidationErrorsOptions): Observable<Array<string> | string | null> {
        if (control) {
            const touchedChanges$ = new Subject<void>();
            (control as any)['_markAsTouched'] = control.markAsTouched;
            control.markAsTouched = () => {
                (control as any)['_markAsTouched']();
                touchedChanges$.next();
            };
            return combineLatest([touchedChanges$, control.statusChanges]).pipe(
                map(([_, status]) => {
                    return status === 'INVALID' ? this.getControlMessages(control, options) : null;
                }),
                finalize(() => {
                    touchedChanges$.complete();
                })
            );
        }
        return of(null);
    }

    private getControlMessages(control: AbstractControl, options?: IValidationErrorsOptions) {
        const messages = getFormControlErrors(control).map(err => this._validationService.getValidatorErrorMessage(err));
        if (options && options.html) {
            return messages.map(m => `<div class="msg">${this._localizationService.translate(m)}</div>`).join('');
        }
        return messages;
    }
}
