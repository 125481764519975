import { Injectable } from '@angular/core';
import { BackendService } from '@myia/ngx-http';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { ReduxStore } from '@myia/ngx-redux';
import { CultureService } from '@myia/ngx-localization';
import { Logger } from '@myia/ngx-core';
import { setCountriesAction } from '../redux/countryActions';
import { ITableDataState, tableDataReducerKey } from '../redux/tableDataReducers';

export interface ICountry {
    title: string;
    code: string;
}

@Injectable({ providedIn: 'root' })
export class CountryService {

    private _observer?: Observer<Array<ICountry>>;
    private readonly _observable: Observable<Array<ICountry>>;
    private _isLoadingData = false;
    private _cachedCountries: any;

    constructor(private _store: ReduxStore, private _cultureService: CultureService, private _backendService: BackendService, private _logger: Logger) {
        this._observable = new Observable((observer: Observer<Array<ICountry>>) => {
            this._observer = observer;
        }).pipe(share());
        this._observable.subscribe();
    }

    public getCountries(): Observable<Array<ICountry>> {
        const countries = this.getCurrentCountries();
        if (!countries) {
            if (!this._isLoadingData) {
                this._isLoadingData = true;
                this.getCountriesFromServer(this._cultureService.currentCulture)
                    .pipe(
                        catchError(err => {
                            this._logger.warn('Could not load list of countries.');
                            this._isLoadingData = false;
                            return throwError(err);
                        })
                    )
                    .subscribe(countries => {
                        this._cachedCountries = countries;
                        this._store.dispatch(setCountriesAction(countries));
                        this._observer?.next(countries);
                        this._isLoadingData = false;
                    });
            }
        }
        return this._observable;
    }

    public getCountryByCode(code: string): string | null {
        const countries = this.getCurrentCountries(true);
        if (!countries) {
            this.getCountries();
            return null;
        }
        else {
            const curr = countries.find(c => c.code === code);
            return curr ? curr.title : null;
        }
    }

    public getDefaultCountryCode(): string | null {
        return this._cultureService.currentCulture === 'cs' ? 'CZ' : null;
    }

    private getCurrentCountries(useCache: boolean = false) : Array<ICountry> {
        const tableDataState = this._store.getState(tableDataReducerKey) as ITableDataState;
        return tableDataState.countries || (useCache ? this._cachedCountries : null);
    }

    private getCountriesFromServer(culture: string): Observable<Array<ICountry>> {
        return this._backendService.get<Array<any>>(`/api/list/country?language=${culture}`)
            .pipe(
                map(data => {
                    return data.map(i => {
                        return {title: i.value, code: i.key};
                    });
                }),
                catchError(err => {
                        this._logger.error('Could not load list of countries.');
                        return throwError(err);
                    }
                )
            );
    }

}
