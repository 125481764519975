import { Pipe, PipeTransform } from '@angular/core';
import { IContextMenuItem } from '@shared';

@Pipe({
    name: 'contextMenuItemsFilter'
})
export class ContextMenuItemsFilterPipe implements PipeTransform {
    transform(items: ReadonlyArray<IContextMenuItem>) {
        return items ? items.filter(i => !i.hidden).filter((i, index) => !i.isDelimiter || index === 0 || !items[index -1].isDelimiter) : items;
    }
}
