import { Injectable } from '@angular/core';
import { getNewComponentId, Logger } from '@myia/ngx-core';
import { BackendService, ITokenData, ITokenService } from '@myia/ngx-http';
import { ReduxStore, setReduxStorage } from '@myia/ngx-redux';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, share, tap } from 'rxjs/operators';

import {
    authCompletedAction, authFailedAction, authRefreshCompletedAction, authRefreshFailedAction,
    authRefreshStartedAction, authStartedAction
} from '../redux/authActions';
import { authReducerKey } from '../redux/authReducers';
import { AppConfig } from '../appConfig';
import { IAuthState } from '../redux/authState.interface';

@Injectable({providedIn: 'root'})
export class TokenService implements ITokenService {
    private _currentRefreshTokenRequest?: Observable<ITokenData>;

    constructor(private _backendService: BackendService, private _store: ReduxStore, private _logger: Logger) {
    }

    /**
     * Make API call to token service
     * @param {string} email - user email
     * @param {string} password
     * @param {string} rememberMe flag
     * @return {Observable} - http request
     */
    load(email: string, password: string, rememberMe: boolean) {
        return setReduxStorage(rememberMe ? 'local' : 'session', AppConfig.reduxStorageKey)
            .pipe(
                mergeMap(() => {
                    const clientId = this.generateClientId();
                    const data = {
                        username: email,
                        password: password,
                        grant_type: 'password',
                        applicationId: AppConfig.AUTH_APP_ID,
                        client_id: clientId
                    };

                    this._store.dispatch(authStartedAction(email));
                    const request: Observable<any> = this._backendService.postJSON(`${AppConfig.BASE_AUTH_API_URL}/token`, JSON.stringify(data), {noAuth: true})
                        .pipe(
                            catchError(errResponse => {
                                if (errResponse && errResponse.error && typeof errResponse.error === 'string') {
                                    errResponse.error = JSON.parse(errResponse.error);
                                }
                                return throwError(errResponse);
                            }),
                            share()
                        );
                    request
                        .subscribe({
                            next: (tokensData: any) => {
                                this._store.dispatch(authCompletedAction(clientId, email, tokensData.access_token, tokensData.refresh_token));
                            },
                            error: error => {
                                this._store.dispatch(authFailedAction());
                                try {
                                    this._logger.error(`Could not load token: ${JSON.stringify(error)}`);
                                } catch (e) {
                                    // error during error object serialization
                                    this._logger.error(`Could not load token: Unknown error`);
                                }
                            }
                        });
                    return request;
                })
            );
    }

    refreshToken(): Observable<ITokenData> {
        // check if 'refresh token' request already running
        if (this._currentRefreshTokenRequest) {
            this._logger.log('Reused existing request for \'refresh token\'.');
            return this._currentRefreshTokenRequest;
        }
        const authState = this._store.getState(authReducerKey) as IAuthState;
        if (authState) {
            const email = authState.email;
            const clientId = authState.clientId;
            const refreshToken = authState.refreshToken;
            if (email && refreshToken) {
                const data = {
                        refresh_token: refreshToken,
                        grant_type: 'refresh_token',
                        applicationId: AppConfig.AUTH_APP_ID,
                        client_id: clientId
                };
                this._store.dispatch(authRefreshStartedAction(refreshToken));
                this._currentRefreshTokenRequest = this._backendService.postJSON<ITokenData>(`${AppConfig.BASE_AUTH_API_URL}/token`, JSON.stringify(data), {noAuth: true})
                    .pipe(
                        tap((data: ITokenData) => {
                            this._currentRefreshTokenRequest = undefined;
                            this._store.dispatch(authRefreshCompletedAction(email, data.access_token, data.refresh_token || refreshToken));
                        }),
                        catchError(err => {
                            this._currentRefreshTokenRequest = undefined;
                            this._store.dispatch(authRefreshFailedAction());
                            this._logger.warn('Could not refresh token.');
                            return throwError(err);
                        }),
                        share()
                    );
                return this._currentRefreshTokenRequest;
            }
        }
        this._logger.warn('Refresh token not available.');
        this._store.dispatch(authRefreshFailedAction());
        return throwError(() => new Error('Refresh token not available.'));
    }

    _test_invalidate_token(invalidateToken: boolean, invalidateRefreshToken: boolean) {
        const authState = this._store.getState(authReducerKey) as IAuthState;
        if (authState) {
            const clientId = authState.clientId;
            const email = authState.email;
            const token = authState.token + (invalidateToken ? 'X' : '');
            const refreshToken = authState.refreshToken + (invalidateRefreshToken ? 'X' : '');
            this._store.dispatch(authCompletedAction(clientId, email, token, refreshToken));
        }
    }

    private generateClientId(): string {
        return getNewComponentId();
    }

}
