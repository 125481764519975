import { Component, Input, HostBinding } from '@angular/core';
import { animate, style, transition, trigger, state, AUTO_STYLE } from '@angular/animations';

@Component({
    selector: 'expand-collapse',
    styles: [`
    .outerContainer {
      box-sizing: border-box;
      overflow: hidden;
    }
  `],
    animations: [
        trigger('openClose', [
            state('collapsed, void',
                style({height: '0px'})),
            state('expanded',
                style({height: AUTO_STYLE})),
            transition('collapsed <=> expanded', [
                animate(300)
            ])
        ])
    ],
    template: `
    <div class="outerContainer" [@openClose]="state">
      <ng-content></ng-content>
    </div>
  `
})
export class ExpandCollapseComponent {
    @Input() rootClass?: string;
    state?: string;

    @HostBinding('class') get hostClasses() {
        return (this.rootClass ? this.rootClass + ' ' : '') + (this.collapsed ? 'collapsed' : 'expanded');
    }

    get collapsed(): boolean {
        return this.state === 'collapsed';
    }

    @Input() set collapsed(val: boolean) {
        this.state = val ? 'collapsed' : 'expanded';
    }

    constructor() {
        this.collapse();
    }

    expand() {
        this.state = 'expanded';
    }

    collapse() {
        this.state = 'collapsed';
    }
}
