import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';

@Directive({
    selector: '[autoTextSize]',
    exportAs: 'autoTextSizeDirective' //the name of the variable to access the directive using ViewChild()
})
export class AutoTextSizeDirective implements AfterViewInit, OnDestroy, OnChanges {
    @Input() mode: 'oneline' | 'multiline' | 'box' = 'oneline';
    @Input() minFontSizePx = 8;
    @Input() maxFontSizePx = 160;
    @Input() fontSizePrecisionPx = 1;
    @Input() scaleKind: 'downscale' | 'upscale' | 'both' = 'downscale';

    @Input() autoTextSize = false;

    private _autoSizeRef?: { disconnect: () => void; };
    private _enabled = false;
    private _maxFontSizePx!: number;
    private _minFontSizePx!: number;

    constructor(private _element: ElementRef) {
    }

    ngAfterViewInit() {
        this._element.nativeElement.classList.add('autoFontSize');
        this._element.nativeElement.parentElement.classList.add('autoFontSize');
        this._enabled = true;
        this._maxFontSizePx = this.scaleKind === 'downscale' ?
            parseFloat(window.getComputedStyle(this._element.nativeElement, null).getPropertyValue('font-size'))
            : this.maxFontSizePx;
        this._minFontSizePx = this.scaleKind === 'upscale' ?
            parseFloat(window.getComputedStyle(this._element.nativeElement, null).getPropertyValue('font-size'))
            : this.minFontSizePx;
        this.adjust();
    }

    ngOnChanges() {
        this.adjust();
    }

    ngOnDestroy() {
        this._autoSizeRef?.disconnect();
    }

    private adjust() {
        this._autoSizeRef?.disconnect();
        if (autoTextSize && this._enabled && this._element.nativeElement?.parentElement) {
            const options = {
                innerEl: this._element.nativeElement,
                containerEl: this._element.nativeElement.parentElement,
                mode: this.mode,
                minFontSizePx: this._minFontSizePx,
                maxFontSizePx: this._maxFontSizePx,
                fontSizePrecisionPx: this.fontSizePrecisionPx,
            };
            this._autoSizeRef = autoTextSize(options);
        }
    }
}

/// Following code is updated version of
/// https://github.com/sanalabs/auto-text-size/blob/main/src/auto-text-size-standalone.ts

/**
 * Ensures that `func` is not called more than once per animation frame.
 *
 * Using requestAnimationFrame in this way ensures that we render as often as
 * possible without excessively blocking the UI.
 */
function throttleAnimationFrame(func: () => void): () => void {
    let wait = false;

    return () => {
        if (!wait) {
            wait = true;
            requestAnimationFrame(() => {
                func();
                wait = false;
            });
        }
    };
}

type AlgoOpts = {
    innerEl: HTMLElement;
    containerEl: HTMLElement;
    fontSizePx: number;
    minFontSizePx: number;
    maxFontSizePx: number;
    fontSizePrecisionPx: number;
    updateFontSizePx: (px: number) => number;
};

/**
 * Ensure no overflow. Underflow is preferred since it doesn't look visually
 * broken like overflow does.
 *
 * Some browsers (eg. Safari) are not good with sub-pixel font sizing, making it so
 * that visual overflow can occur unless we adjust for it.
 */
const antiOverflowAlgo = ({
                              fontSizePx,
                              minFontSizePx,
                              fontSizePrecisionPx,
                              updateFontSizePx,
                              breakPredicate: breakPred,
                          }: Pick<
    AlgoOpts,
    "fontSizePx" | "minFontSizePx" | "fontSizePrecisionPx" | "updateFontSizePx"
    > & { breakPredicate: () => boolean }): void => {
    const maxIterCount = Math.ceil(1 / fontSizePrecisionPx); // 1 px should always be enough.
    let iterCount = 0;

    while (fontSizePx > minFontSizePx && iterCount < maxIterCount) {
        if (breakPred()) break;
        fontSizePx = updateFontSizePx(fontSizePx - fontSizePrecisionPx);
        iterCount++;
    }
};

const getContentWidth = (element: HTMLElement): number => {
    const computedStyle = getComputedStyle(element);
    return (
        element.clientWidth -
        parseFloat(computedStyle.paddingLeft) -
        parseFloat(computedStyle.paddingRight)
    );
};

const getContentHeight = (element: HTMLElement): number => {
    const computedStyle = getComputedStyle(element);
    return (
        element.clientHeight -
        parseFloat(computedStyle.paddingTop) -
        parseFloat(computedStyle.paddingBottom)
    );
};

const multilineAlgo = (opts: AlgoOpts): void => {
    opts.innerEl.style.whiteSpace = "nowrap";

    onelineAlgo(opts);

    if (opts.innerEl.scrollWidth > getContentWidth(opts.containerEl)) {
        opts.innerEl.style.whiteSpace = "normal";
    }
};

const onelineAlgo = ({
                         innerEl,
                         containerEl,
                         fontSizePx,
                         minFontSizePx,
                         maxFontSizePx,
                         fontSizePrecisionPx,
                         updateFontSizePx,
                     }: AlgoOpts): void => {
    const maxIterCount = 10; // Safety fallback to avoid infinite loop
    let iterCount = 0;
    let prevOverflowFactor = 1;

    while (iterCount < maxIterCount) {
        const w0 = innerEl.scrollWidth;
        const w1 = getContentWidth(containerEl);

        const canGrow = fontSizePx < maxFontSizePx && w0 < w1;
        const canShrink = fontSizePx > minFontSizePx && w0 > w1;

        const overflowFactor = w0 / w1;

        // The browser cannot render a difference based on the previous font size update
        if (prevOverflowFactor === overflowFactor) {
            break;
        }

        if (!(canGrow || canShrink)) {
            break;
        }

        const updatePx = fontSizePx / overflowFactor - fontSizePx;
        const prevFontSizePx = fontSizePx;
        fontSizePx = updateFontSizePx(fontSizePx + updatePx);

        // Stop iterating when converging
        if (Math.abs(fontSizePx - prevFontSizePx) <= fontSizePrecisionPx) {
            break;
        }

        prevOverflowFactor = overflowFactor;
        iterCount++;
    }

    antiOverflowAlgo({
        fontSizePx,
        minFontSizePx,
        updateFontSizePx,
        fontSizePrecisionPx,
        breakPredicate: () => innerEl.scrollWidth <= getContentWidth(containerEl),
    });
};

/**
 * Binary search for the best font size in the range [minFontSizePx, maxFontSizePx].
 */
const boxAlgo = ({
                     innerEl,
                     containerEl,
                     fontSizePx,
                     minFontSizePx,
                     maxFontSizePx,
                     fontSizePrecisionPx,
                     updateFontSizePx,
                 }: AlgoOpts) => {
    const maxIterCount = 100; // Safety fallback to avoid infinite loop

    // Start the binary search in the middle.
    fontSizePx = updateFontSizePx((maxFontSizePx - minFontSizePx) * 0.5);

    // Each subsequent update will halve the search space.
    let updatePx = (maxFontSizePx - minFontSizePx) * 0.25;
    let iterCount = 0;

    while (updatePx > fontSizePrecisionPx && iterCount < maxIterCount) {
        const w0 = innerEl.scrollWidth;
        const w1 = getContentWidth(containerEl);

        const h0 = innerEl.scrollHeight;
        const h1 = getContentHeight(containerEl);

        if (w0 === w1 && h0 === h1) break;

        /**
         * Use `<=` rather than `<` since equality is possible even though there is
         * room for resizing in the other dimension.
         */
        if (fontSizePx < maxFontSizePx && w0 <= w1 && h0 <= h1) {
            fontSizePx = updateFontSizePx(fontSizePx + updatePx);
        } else if (fontSizePx > minFontSizePx && (w0 > w1 || h0 > h1)) {
            fontSizePx = updateFontSizePx(fontSizePx - updatePx);
        }

        updatePx *= 0.5; // Binary search. Don't change this number.
        iterCount++;
    }

    antiOverflowAlgo({
        fontSizePx,
        minFontSizePx,
        updateFontSizePx,
        fontSizePrecisionPx,
        breakPredicate: () =>
            innerEl.scrollWidth <= getContentWidth(containerEl) &&
            innerEl.scrollHeight <= getContentHeight(containerEl),
    });
};

export type Options = {
    mode?: "oneline" | "multiline" | "box" | undefined;
    minFontSizePx?: number | undefined;
    maxFontSizePx?: number | undefined;
    fontSizePrecisionPx?: number | undefined;
};

/**
 * Make text fit container, prevent overflow and underflow.
 *
 * Adjusts the font size of `innerEl` so that it precisely fills `containerEl`.
 */
export function updateTextSize({
                                   innerEl,
                                   containerEl,
                                   mode = "multiline",
                                   minFontSizePx = 8,
                                   maxFontSizePx = 160,
                                   fontSizePrecisionPx = 0.1,
                               }: Options & {
    innerEl: HTMLElement;
    containerEl: HTMLElement;
}): void {
    const t0 = performance.now();

    if (!isFinite(minFontSizePx)) {
        throw new Error(`Invalid minFontSizePx (${minFontSizePx})`);
    }

    if (!isFinite(minFontSizePx)) {
        throw new Error(`Invalid maxFontSizePx (${maxFontSizePx})`);
    }

    if (!isFinite(fontSizePrecisionPx) || fontSizePrecisionPx === 0) {
        throw new Error(`Invalid fontSizePrecisionPx (${fontSizePrecisionPx})`);
    }

    if (containerEl.children.length > 1) {
        console.warn(
            `AutoTextSize has ${
                containerEl.children.length - 1
            } siblings. This may interfere with the algorithm.`
        );
    }

    const containerStyles: Partial<CSSStyleDeclaration> = {
        // Necessary to correctly compute the dimensions `innerEl`.
        display: "flex",
        alignItems: "start",
    };

    const innerStyles: Partial<CSSStyleDeclaration> = {
        display: "block", // Necessary to compute dimensions.
    };

    if (mode === "oneline") {
        innerStyles.whiteSpace = "nowrap";
    } else if (mode === "multiline") {
        innerStyles.wordBreak = "break-word";
        // white-space is controlled dynamically in multiline mode
    } else if (mode === "box") {
        innerStyles.whiteSpace = "pre-wrap";
        innerStyles.wordBreak = "break-word";
    }

    Object.assign(containerEl.style, containerStyles);
    Object.assign(innerEl.style, innerStyles);

    const fontSizeStr = window
        .getComputedStyle(innerEl, null)
        .getPropertyValue("font-size");
    let fontSizePx = parseFloat(fontSizeStr);
    let iterations = 0;

    const updateFontSizePx = (px: number): number => {
        px = Math.min(Math.max(px, minFontSizePx), maxFontSizePx);
        // console.debug(
        //   `setFontSizePx ${px > fontSizePx ? "up" : "down"} (abs: ${
        //     px / fontSizePx
        //   }, rel: ${(px - fontSizePx) / fontSizePx}) ${px}`
        // );
        fontSizePx = px;
        innerEl.style.fontSize = `${fontSizePx}px`;
        iterations++;
        return fontSizePx;
    };

    if (fontSizePx > maxFontSizePx || fontSizePx < minFontSizePx) {
        updateFontSizePx(fontSizePx);
    }

    const algoOpts = {
        innerEl,
        containerEl,
        fontSizePx,
        minFontSizePx,
        maxFontSizePx,
        fontSizePrecisionPx,
        updateFontSizePx,
    };

    if (mode === "oneline") {
        onelineAlgo(algoOpts);
    } else if (mode === "multiline") {
        multilineAlgo(algoOpts);
    } else if (mode === "box") {
        boxAlgo(algoOpts);
    }

    const t1 = performance.now();
    const duration = Math.round(t1 - t0);

    if (minFontSizePx === fontSizePx) {
        innerEl.classList.add('min');
    } else {
        innerEl.classList.remove('min');
    }

    console.debug(
        `AutoTextSize ${mode} ran ${iterations} iterations in ${duration}ms`
    );
}

type DisconnectableFunction = {
    (): void;
    disconnect: () => void;
};

/**
 * Make text fit container, prevent overflow and underflow.
 *
 * Adjusts the font size of `innerEl` so that it precisely fills `containerEl`.
 *
 * Throttles all invocations to next animation frame (through
 * `requestAnimationFrame`).
 *
 * Sets up a `ResizeObserver` to automatically run `autoTextSize` when
 * `containerEl` resizes. Call `disconnect()` when done to disconnect the resize
 * observer to prevent memory leaks.
 */
export function autoTextSize({
                                 innerEl,
                                 containerEl,
                                 mode,
                                 minFontSizePx,
                                 maxFontSizePx,
                                 fontSizePrecisionPx,
                             }: Options & {
    innerEl: HTMLElement;
    containerEl: HTMLElement;
}): DisconnectableFunction {
    // Initialize as `undefined` to always run directly when instantiating.
    let containerDimensions: [number, number] | undefined = undefined;

    // Use type `any` so that we can add the `.disconnect` property later on.
    const throttledUpdateTextSize: any = throttleAnimationFrame(() => {
        updateTextSize({
            innerEl,
            containerEl,
            mode,
            maxFontSizePx,
            minFontSizePx,
            fontSizePrecisionPx,
        });

        containerDimensions = [
            getContentWidth(containerEl),
            getContentHeight(containerEl),
        ];
    });

    const resizeObserver = new ResizeObserver(() => {
        const prevContainerDimensions = containerDimensions;
        containerDimensions = [
            getContentWidth(containerEl),
            getContentHeight(containerEl),
        ];

        if (
            prevContainerDimensions?.[0] !== containerDimensions[0] ||
            prevContainerDimensions?.[1] !== containerDimensions[1]
        ) {
            throttledUpdateTextSize();
        }
    });

    // It calls the callback directly.
    resizeObserver.observe(containerEl);

    // The native code `resizeObserver.disconnect` needs the correct context.
    // Retain the context by wrapping in arrow function. Read more about this:
    // https://stackoverflow.com/a/9678166/19306180
    throttledUpdateTextSize.disconnect = () => resizeObserver.disconnect();

    return throttledUpdateTextSize;
}
