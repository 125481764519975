import { readOnlyEntity } from '@myia/ngx-core';
import { EventState } from './eventState';
import { IPlaceEvent } from './placeEvent.interface';

export function PlaceEvent(props: Partial<IPlaceEvent>): IPlaceEvent {
    return readOnlyEntity({
        id: '',
        name: '',
        from: '',
        to: '',
        paidAttendees: 0,
        noOfAttendees: 0,
        paidDays: 0,
        paidUnits: 0,
        tariffId: null,
        timeZone: '',
        googlePlaceId: '',
        isPublished: false,
        isListed: false,
        createdBy: null,
        ...props,
        status: typeof props.status === 'string' ? EventState[props.status as keyof typeof EventState] : null,
        messageGroups: props.messageGroups && Array.isArray(props.messageGroups) ? readOnlyEntity(props.messageGroups) as any: null,
    } as IPlaceEvent);
}
