import { readOnlyEntity } from '@myia/ngx-core';
import { IOrganization } from './organization.interface';

export function Organization(props: Partial<IOrganization>): IOrganization {
    return readOnlyEntity({
        id: '',
        name: '',
        ...props
    });
}
