import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICompleterItem } from '../entities/completerItem.interface';
import { GoogleMapsCompleterServiceBase } from './googleMapsCompleterServiceBase';
import { GoogleMapsAPIService } from './googleMapsAPIService';
import { CultureService } from '@myia/ngx-localization';

@Injectable({ providedIn: 'root' })
export class GoogleMapsCitiesCompleterService extends GoogleMapsCompleterServiceBase  {

    constructor(private _googleMapsAPIService: GoogleMapsAPIService, private _cultureService: CultureService) {
        super();
    }

    protected override searchData(term: string): Observable<Array<any>> {
        return this._googleMapsAPIService.autoCompleteCity(term, this._cultureService.currentCulture)
            .pipe(
                catchError(() => {
                    // return empty array result
                    return of([]);
                })
            );
    }

    protected override transformSearchResultData(data: any): ICompleterItem[] {
        return data.map((city: any) => {
            return {
                title: city.title,
                originalObject: city.originalObject
            };
        });
    }

}
