import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Output } from '@angular/core';
import { Level, Logger } from '@myia/ngx-core';
import { configureReduxLogger, ReduxStore, ReduxView } from '@myia/ngx-redux';
import { IToastButton, ToastManager } from '@myia/ngx-toast';
import { useDirectDeepLinksAction } from '../redux/debugActions';
import { debugReducerKey, IDebugState } from '../redux/debugReducers';
import { TokenService } from '../services/tokenService';
import { getDropDownValuesFromEnum, IDropDownValue } from './inputDropDown';
import { AppConfig } from '../appConfig';

function isLogLevel(value: string): value is Level {
    return Object.values(Level).includes(value as Level);
}

@Component({
    selector: 'dev-tools',
    styleUrls: ['./devTools.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="_devToolsMenu_">
            <div class="panelTitle">Development tools</div>
            <div class="closeBtnWrapper">
                <button class="btn btn-link btn-topbar-button" type="button" (click)="closeDevTools()">
                    <svg-icon name="cancel"></svg-icon>
                </button>
            </div>
            <div class="section">
                <input-dropdown [value]="loggerLevel" label="Log level" [items]="loggerLevels" itemTitlePath="value"
                                (valueChange)="loggerLevelChanged($event)"></input-dropdown>
            </div>
            <div class="section">
                <div class="title">Preview link:</div>
                <div>
                    <input-checkbox label="Use direct deeplinks" [value]="useDirectDeepLink" [textStates]="false"
                                    (valueChange)="onUseDirectDeepLinkChanged($event)"></input-checkbox>
                </div>
            </div>
            <div class="section">
                <div class="title">Token management:</div>
                <button type="button" (click)="invalidateToken(true, false)">Invalidate token</button>
                <button type="button" (click)="invalidateToken(false, true)">Invalidate refresh token</button>
            </div>
            <div class="section">
                <div class="title">Toasts:</div>
                <div>
                    <button type="button" (click)="showToast('info')">Info</button>
                    <button type="button" (click)="showToast('warning')">Warning</button>
                    <button type="button" (click)="showToast('error')">Error</button>
                    <button type="button" (click)="showToast('success')">Success</button>
                </div>
                <div class="subTitle">With toast key:</div>
                <div>
                    <button type="button" (click)="showToast('info', '_toastKey_')">Info</button>
                    <button type="button" (click)="showToast('warning', '_toastKey_')">Warning</button>
                    <button type="button" (click)="showToast('error', '_toastKey_')">Error</button>
                    <button type="button" (click)="showToast('success', '_toastKey_')">Success</button>
                </div>
                <div class="subTitle">Custom icon:</div>
                <button type="button" (click)="showCustomToast()">Custom info</button>
            </div>
        </div>
    `
})
export class DevToolsComponent extends ReduxView {
    @Output() closed = new EventEmitter<void>();
    useDirectDeepLink = false;

    loggerLevel?: IDropDownValue<Level>;
    loggerLevels: Array<IDropDownValue<Level>>;

    constructor(injector: Injector, private _tokenService: TokenService, private _toastManager: ToastManager, private _store: ReduxStore, private _logger: Logger) {
        super(injector);
        this.attachToStore(_store, [debugReducerKey]);
        this.loggerLevels = getDropDownValuesFromEnum<Level>(Level);
        const configLevel = AppConfig.logLevel;
        this.loggerLevel = this.loggerLevels.find(l => l.value === configLevel);
    }

    loggerLevelChanged(level: IDropDownValue | undefined) {
        if (level) {
            AppConfig.logLevel = level.value;
            this._logger.level = Level[level.value as keyof typeof Level];
            configureReduxLogger(this._logger.level >= Level.INFO);
        }
    }

    invalidateToken(invalidateToken: boolean, invalidateRefreshToken: boolean) {
        this._tokenService._test_invalidate_token(invalidateToken, invalidateRefreshToken);
        if (invalidateToken) {
            this._toastManager.info('Token invalidated.');
        }
        if (invalidateRefreshToken) {
            this._toastManager.info('Refresh token invalidated.');
        }
    }

    override mapStateToProps(state: any) {
        super.mapStateToProps(state);
        const debugState = state[debugReducerKey] as IDebugState;
        this.useDirectDeepLink = this.getPropertyFromState(this.useDirectDeepLink, debugState._useDirectDeepLink);
        return this.propertiesUpdated;
    }

    closeDevTools() {
        this.closed.emit();
    }

    onUseDirectDeepLinkChanged(useDeepLinks: boolean) {
        this._store.dispatch(useDirectDeepLinksAction(useDeepLinks));
    }

    showToast(toastType: keyof ToastManager, toastKey?: string) {
        const options: any = {};
        if (toastKey) {
            options.toastKey = toastKey;
        }
        this._toastManager[toastType]('Test toast: ' + toastType, options);
    }

    showCustomToast() {
        const buttons: Array<IToastButton> = [
            {
                title: 'Update app', clickHandler: (toast: any) => {
                    this._toastManager.clearToast(toast);
                    this._logger.log('Update button clicked.');
                }
            }
        ];
        this._toastManager.info('There is update available.', {
            toastKey: '_toastKey_custom_',
            timeOut: 0,
            icon: 'update',
            iconPrefix: 'svgIcon',
            buttons
        });

    }
}

