import { Directive, ElementRef, Renderer2, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[dropEffect]',
})
export class DropEffectDirective {
    private _isActive = false;
    private _enterTarget: any;

    constructor(private _element: ElementRef, private _renderer: Renderer2) {
    }

    @Input() set dropEffect(value: string) {
        this._isActive = value === 'true';
    }

    @HostListener('dragover', ['$event'])
    onDragOver($event: any) {
        if (this._isActive) {
            $event.stopPropagation();
            $event.preventDefault();
            $event.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
        }
    }

    @HostListener('dragenter', ['$event'])
    onDragEnter($event: any) {
        if (this._isActive) {
            this._enterTarget = $event.target;
            this._renderer.addClass(this._element.nativeElement, 'dragOver');
        }
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event: any) {
        if (this._isActive && this._enterTarget === $event.target) {
            this._renderer.removeClass(this._element.nativeElement, 'dragOver');
            this._enterTarget = null;
        }
    }

    @HostListener('drop', ['$event'])
    onDrop(_: any) {
        this._renderer.removeClass(this._element.nativeElement, 'dragOver');
        this._enterTarget = null;
    }
}
