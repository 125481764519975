import { createState, updateState } from '@myia/ngx-redux';
import { IStatusBarMessage } from '../entities/statusBarMessage.interface';
import { ADD_STATUSBAR_MESSAGE, CLEAR_ALL_STATUSBAR_MESSAGES, REMOVE_STATUSBAR_MESSAGE, SHOW_STATUSBAR_BUSY_LINE, UPDATE_STATUSBAR_MESSAGE } from './statusBarActions';

export const statusBarReducerKey = 'statusBar';

export interface IStatusBarState {
    showBusyLine: boolean;
    messages: Array<IStatusBarMessage>;
    busyLineKeys: any;
}

const initialState = createState<IStatusBarState>(
    {
        showBusyLine: false,
        messages: [],
        busyLineKeys: {}
    });

function statusBarReducer(state = initialState, action: any) {
    switch (action.type) {
        case ADD_STATUSBAR_MESSAGE:
            return updateState(state, (newState: IStatusBarState) => {
                newState.messages.push(action.msg);
            });
        case UPDATE_STATUSBAR_MESSAGE:
            return updateState(state, (newState: IStatusBarState) => {
                const msgToUpdate = newState.messages.find((item: IStatusBarMessage) => item.messageKey === action.msg.messageKey);
                if (msgToUpdate) {
                    msgToUpdate.messageText = action.msg.messageText;
                }
            });
        case REMOVE_STATUSBAR_MESSAGE:
            return updateState(state, (newState: IStatusBarState) => {
                const msgIndexToRemove = newState.messages.findIndex((item: IStatusBarMessage) => item.messageKey === action.key);
                if (msgIndexToRemove !== -1) {
                    newState.messages.splice(msgIndexToRemove, 1);
                }
            });
        case CLEAR_ALL_STATUSBAR_MESSAGES:
            return updateState(state, (newState: IStatusBarState) => {
                newState.messages = [];
            });
        case SHOW_STATUSBAR_BUSY_LINE:
            return updateState(state, (newState: IStatusBarState) => {
                let busyLineKeys: any = newState.busyLineKeys;
                if (action.show) {
                    busyLineKeys[action.key] = true;
                }
                else {
                    delete busyLineKeys[action.key];
                }
                newState.showBusyLine = Object.keys(busyLineKeys).length > 0;
            });
        default:
            return state;
    }
}


export default {
    [statusBarReducerKey]: statusBarReducer
};
