import { ICurrency } from '@eventShared';

export const TABLE_DATA_SET_CURRENCIES = 'TABLE_DATA_SET_CURRENCIES';
export const TABLE_DATA_SET_TIMEZONES = 'TABLE_DATA_SET_TIMEZONES';
export const TABLE_DATA_SET_CULTURES = 'TABLE_DATA_SET_CULTURES';

export function setCurrenciesAction(currencies: Array<ICurrency>) {
    return {
        type: TABLE_DATA_SET_CURRENCIES,
        currencies
    };
}

export function setTimeZonesAction(timeZones: Array<any>) {
    return {
        type: TABLE_DATA_SET_TIMEZONES,
        timeZones
    };
}

export function setCulturesAction(cultures: Array<any>) {
    return {
        type: TABLE_DATA_SET_CULTURES,
        cultures
    };
}

