import { readOnlyEntity } from '@myia/ngx-core';
import { IAccountInfo } from './accountInfo.interface';

export function AccountInfo(props: Partial<IAccountInfo>): IAccountInfo {
    return readOnlyEntity({
        id: '',
        email: '',
        organizationId: '',
        organizationName: '',
        ...props
    });
}
